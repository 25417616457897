import React from "react";
import { LockOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import MetaComponent from "../components/shared/MetaComponent";
import { Grid, Typography, Box, Container } from "@mui/material";
const Unauthorized = () => {
  return (
    <>
      <MetaComponent
        titulo="No Autorizado"
        description="No tienes permisos para ver esta pagina"
      />
      <Box my={10} mt={10 }>
        <section className="unauthorize">
          <Container>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h2" align="center">
                  <LockOutlined color="primary" style={{ fontSize: 150 }} />
                </Typography>
                <Typography variant="h3" align="center">
                  No tienes permisos
                </Typography>
                <Typography variant="body2" align="center">
                  Lo sentimos, no tiene permisos para ver esta pagina, <br />{" "}
                  intente ingresar nuevamente.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mt={2}
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <Link to="/auth/login">
                    {" "}
                    <Typography variant="h2" align="center">
                      <img
                        alt="Industrias Pegaduro"
                        src="https://www.pegaduro.com.mx/img/LogoPegaduro.png"
                      />
                    </Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </section>
      </Box>
    </>
  );
};

export default Unauthorized;
