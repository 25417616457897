import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import currencyFormatter from "currency-formatter";
import format from "format-number";
import { useHistory } from "react-router-dom";
import useEnvio from "../../hooks/useEnvio";
import { editCarritoPedidoAction } from "../../actions/pedido";
import ReactPixel from "react-facebook-pixel";
import TagManager from 'react-gtm-module'
import { Paper, Button, Box, Typography, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { green } from "@mui/material/colors";
import { updateCarritoOlvidado } from "../../repository/carritoRepository";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firebase } from "../../firebase/config";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  kg: {
    fontWeight: "bold",
  },
  price: {
    color: green[400],
    fontWeight: "bold",
  },
}));

const CardInfo = () => {
  const classes = useStyles();
  const { carrito, auth, unregistredUser } = useSelector((state) => state);
  const [correo, setcorreo] = useState("");
  const { envio } = useEnvio(carrito);
  const dispatch = useDispatch();
  const history = useHistory();
  const subTotal = carrito
    .map((item) => (item.iva * item.precio + item.precio) * item.cantidad)
    .reduce((a, b) => a + b, 0);

  const handleClick = async () => {
    ReactPixel.track(
      "InitiateCheckout",
      carrito.map((p) => ({ producto: p.nombre, cantidad: p.cantidad }))
    );
    TagManager.dataLayer({
      dataLayer:{
        ecommerce: {
          products:carrito.map(p=>({ name:p.nombre, quantity:p.cantidad, procuctId:p.itemCode, unityPrice:p.precio, iva:p.iva, currency:p.moneda}))
        },
        event:"begin_checkout"}})
    dispatch(editCarritoPedidoAction(carrito));
    await updateCarritoOlvidado(carrito, correo);
    history.push("/check-out");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (auth?.token) {
      const authFirebase = getAuth(firebase);
      const cancell = onAuthStateChanged(authFirebase, (user) => {
        if (user) {
          if (user) {
            setcorreo(user.email);
          }
        }
      });
      return () => {
        cancell();
      };
    } else {
      setcorreo(unregistredUser.email);
    }
  }, [auth, unregistredUser]);
  return (
    <>
      <Paper elevation={3}>
        <Box p={5}>
          <Box>
            <Typography fontWeight="bold" variant="h6">
              Total de la compra
            </Typography>
          </Box>
          {/* Precio  */}
          <Box
            display="flex"
            justifyItems="center"
            justifyContent="space-between"
            alignItems="center"
            my={4}
          >
            <Typography variant="subtitle2" fontWeight="bold">
              Peso del pedido
            </Typography>
            <Typography
              variant="body2"
              fontWeight="bold"
              className={classes.kg}
            >
              {format({ suffix: "kg" })(
                carrito
                  .map((item) => item.peso * item.cantidad)
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2)
              )}
            </Typography>
          </Box>
          {/* Costo de envio  */}
          <Box
            display="flex"
            justifyItems="center"
            justifyContent="space-between"
            alignItems="center"
            my={4}
          >
            <Typography variant="subtitle2" fontWeight="bold">
              Costo de envío
            </Typography>
            <Typography
              variant="body2"
              fontWeight="bold"
              className={classes.price}
            >
              {currencyFormatter.format(envio, {
                code: "USD",
              })}
              {carrito[0].moneda}
            </Typography>
          </Box>
          {/* SubTotal */}
          <Box
            display="flex"
            justifyItems="center"
            justifyContent="space-between"
            alignItems="center"
            my={4}
          >
            <Typography variant="subtitle2" fontWeight="bold">
              Subtotal
            </Typography>
            <Typography
              variant="body2"
              fontWeight="bold"
              className={classes.price}
            >
              {currencyFormatter.format(subTotal, {
                code: "USD",
              })}
              {carrito[0].moneda}
            </Typography>
          </Box>
          <Divider />
          {/* Total */}
          <Box
            display="flex"
            justifyItems="center"
            justifyContent="space-between"
            alignItems="center"
            my={4}
          >
            <Typography variant="body1" fontWeight="bold">
              Total
            </Typography>
            <Typography
              variant="body2"
              fontWeight="bold"
              className={classes.price}
            >
              {currencyFormatter.format(subTotal + envio, {
                code: "USD",
              })}
              {carrito[0].moneda}
            </Typography>
          </Box>

          <Button
            fullWidth
            component="span"
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Continuar<i className="fas fa-arrow-right ml-2"></i>
          </Button>
        </Box>
      </Paper>
    </>
  );
};

export default CardInfo;
