import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import currencyFormatter from "currency-formatter";
import { Link } from "react-router-dom";
import { eventTypes } from "../../facebookpixel/config";
import ReactPixel from "react-facebook-pixel";
import { makeStyles } from "@mui/styles";
import { green } from "@mui/material/colors";

const useStyle = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  verProducto: {
    marginLeft: 10,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  price: {
    color: green[500],
  },
}));
const ListProductos = ({ productos }) => {
  const classes = useStyle();
  const [expanded, setexpanded] = useState(false);
  const handleAccordion = () => {
    setexpanded(!expanded);
  };
  return (
    <Box>
      <Accordion
        expanded={expanded}
        onChange={handleAccordion}
        elevation={0}
        placeholder="Productos"
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreOutlined />}
        >
          <Typography variant="h5" fontWeight="bold" color="primary">
            Ver Productos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {productos.map((producto, i) => {
              return (
                <Box key={producto.itemCode}>
                  {" "}
                  <Link
                    className={classes.link}
                    to={`./info-producto-${producto.itemCodeHDR}?itemCode=${producto.itemCode}`}
                    onClick={() => {
                      ReactPixel.track(eventTypes.verProducto, {
                        producto: producto.nombre,
                      });
                    }}
                  >
                    <Typography variant="subtitle2" fontWeight="bold">
                      {i + 1} - {producto.nombre}
                      <Typography
                        className={classes.verProducto}
                        variant="caption"
                      >
                        volver a comprar
                      </Typography>
                    </Typography>
                  </Link>
                  <Box display="flex" mt={1} justifyContent="space-between">
                    <Typography variant="caption" fontWeight="bold">
                      cantidad: x{producto.cantidad}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight="bold"
                      className={classes.price}
                    >
                      {currencyFormatter.format(producto.total, {
                        code: "USD",
                      })}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ListProductos;
