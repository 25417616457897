import { getCarritoLocalStorage } from "../helper/carritoLocalStorage";
import { compare } from "../helper/validaciones";
import { carritoTypes } from "../types/types";

export const carritoReducer = (
  state = getCarritoLocalStorage().sort(compare),
  action
) => {
  switch (action.type) {
    case carritoTypes.get:
      return action.payload;
    case carritoTypes.add:
      return action.payload;
    case carritoTypes.edit_carrito:
      return action.payload;
    case carritoTypes.delete_carrito:
      return action.payload;
    case carritoTypes.delete_producto:
      return action.payload;
    case carritoTypes.edit_producto:
      return action.payload;
    case carritoTypes.edit_producto_cantidad:
      return action.payload;

    default:
      return state;
  }
};
