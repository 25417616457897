
const max_dots = 10;
export const settings = (item, arrows = false, dots = true) => ({
  infinite: item?.length > 5 ? true : false,
  slidesToShow: 5,
  slidesToScroll: 5,
  arrows,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        infinite: item?.length > 4 ? true : false,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows,
        dots: false,
      },
    },
    {
      breakpoint: 900,
      settings: {
        infinite: item?.length > 3 ? true : false,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        dots: true,
      },
    },

    {
      breakpoint: 600,
      settings: {
        infinite: item?.length > 2 ? true : false,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        dots:item?.length <max_dots,
      },
    },
    {
      breakpoint: 480,
      settings: {
        infinite: item?.length > 1 ? true : false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:  item?.length >=max_dots,
        dots: item?.length <max_dots,
      },
    },
  ],
});

export const settingsPresentaciones = (item) => ({
  infinite: item?.length > 6 ? true : false,
  slidesToShow: 6,
  slidesToScroll: 6,
  dots: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        infinite: item?.length > 5 ? true : false,
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        infinite: item?.length > 5 ? true : false,
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
        arrows: false,
      },
    },
  ],
});



export const settingsIconos = (item)=>({
  infinite: item?.length > 5 ? true : false,
  slidesToShow: 10,
  slidesToScroll: 10,

})

