import { useEffect, useState, useRef } from "react";
import { validateNotDecimalNumber } from "../../helper/validaciones";
import { urlServices } from "../../helper/urlsBase";
import {
  TextField,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
} from "@mui/material";
import {
  ApartmentOutlined,
  Email,
  GroupOutlined,
  HomeOutlined,
  LocalPhoneOutlined,
  LocationCityOutlined,
  MarkunreadMailboxOutlined,
  PersonOutlined,
} from "@mui/icons-material";
const FormDireccion = ({ handleChange, form, setForm }) => {
  const {
    apellidos,
    nombres,
    municipio,
    calle,
    numeroInterior,
    numeroExterior,
    numeroTelefono,
    estado,
    correoElectronico,
    correoElectronicoConfirmar,
    codigoPostal,
  } = form;
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  const [validCp, setValidCp] = useState(true);
  const [validConfirm, setValidConfirm] = useState(true);
  const [colonias, setColonias] = useState([]);
  /*Revisar los codigos postales*/
  useEffect(() => {
    const regex = /^[0-9]*$/;
    setColonias([]);
    setForm((f) => ({ ...f, colonia: "" }));
    if (codigoPostal.length === 5 && regex.test(codigoPostal)) {
      fetch( `${urlServices.URL_SERVICE_TIENDA}api/v1/Pedidos/CP/${codigoPostal}`)
        .then((resp) => resp.json())
        .then((data) => {
          if (isMounted.current) {
            if (data.mensaje === "Valido") {
              setColonias(data.cp.response.asentamiento);
              setValidCp(true);
              setForm((f) => ({...f,municipio: data.cp.response.municipio,estado: data.cp.response.estado}));
            } else {
              setColonias([]);
              setValidCp(false);
              setForm((f) => ({...f,municipio: "",estado: ""}));
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [codigoPostal, setForm]);

  useEffect(() => {
    if (
      correoElectronico?.toUpperCase() === correoElectronicoConfirmar?.toUpperCase()
    ) {
      setValidConfirm(true);
    } else {
      setValidConfirm(false);
    }
  }, [correoElectronico, correoElectronicoConfirmar]);
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          type="text"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutlined />
              </InputAdornment>
            ),
          }}
          variant="standard"
          label="Nombre (s)"
          name="nombres"
          value={nombres}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          type="text"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <GroupOutlined />
              </InputAdornment>
            ),
          }}
          variant="standard"
          label="Apellidos"
          name="apellidos"
          onChange={handleChange}
          value={apellidos}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          type="email"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            ),
          }}
          variant="standard"
          label="Correo Electrónico"
          name="correoElectronico"
          onChange={handleChange}
          value={correoElectronico}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          type="email"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            ),
          }}
          variant="standard"
          label="Confirmar Correo Electrónico"
          name="correoElectronicoConfirmar"
          onChange={handleChange}
          value={correoElectronicoConfirmar}
        />
        {!validConfirm && (
          <Typography
            variant="caption"
            fontWeight="bold"
            style={{ color: "red" }}
          >
            El correo electrónico no coincide
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <TextField
          type="tel"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocalPhoneOutlined />
              </InputAdornment>
            ),
          }}
          variant="standard"
          label="Número Telefónico "
          name="numeroTelefono"
          value={numeroTelefono}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          type="text"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HomeOutlined />
              </InputAdornment>
            ),
          }}
          variant="standard"
          label="Calle"
          name="calle"
          value={calle}
          onChange={handleChange}
          placeholder="Nombre de la calle"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          type="text"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <i className="fas fa-hashtag"></i>
              </InputAdornment>
            ),
          }}
          variant="standard"
          label="Número exterior"
          name="numeroExterior"
          value={numeroExterior}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          type="text"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <i className="fas fa-hashtag"></i>
              </InputAdornment>
            ),
          }}
          variant="standard"
          label="Número Interior"
          name="numeroInterior"
          value={numeroInterior}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          type="text"
          onKeyPress={validateNotDecimalNumber}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MarkunreadMailboxOutlined />
              </InputAdornment>
            ),
          }}
          variant="standard"
          label="Código Postal"
          name="codigoPostal"
          onChange={handleChange}
          value={codigoPostal}
        />

        {!validCp && (
          <Typography
            variant="caption"
            fontWeight="bold"
            style={{ color: "red" }}
          >
            Código postal no disponible
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="test-select-label">Colonia</InputLabel>
          <Select
            labelId="test-select-label"
            label="Colonia"
            name="colonia"
            variant="standard"
            inputProps={{
              style: {
                fontSize: ".9rem",
              },
            }}
            defaultValue=""
            MenuProps={{
              autoFocus: false,
              disableScrollLock: false,
            }}
            onChange={handleChange}
          >
            {colonias.map((colonia) => (
              <MenuItem value={colonia} key={colonia}>
                {colonia}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          type="text"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationCityOutlined />
              </InputAdornment>
            ),
          }}
          variant="standard"
          label="Municipio"
          name="municipio"
          onChange={handleChange}
          value={municipio}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          type="text"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ApartmentOutlined />
              </InputAdornment>
            ),
          }}
          variant="standard"
          label="Estado"
          name="Estado"
          onChange={handleChange}
          value={estado}
        />
      </Grid>
    </Grid>
  );
};

export default FormDireccion;
