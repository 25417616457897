import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { urlServices } from "../helper/urlsBase";
import { firebase } from "../firebase/config";
import { useFetchAuth } from "../hooks/useFetchAuth";
import ListPedidos from "../components/mispedidos/ListPedidos";
import Loader from "react-loader-spinner";
import {
  WarningOutlined,
  RefreshOutlined,
  SentimentDissatisfiedOutlined,
} from "@mui/icons-material";
import { Box, Button, Container, Typography } from "@mui/material";
const MisPedidos = () => {
  const { auth } = useSelector((state) => state);

  const [token, settoken] = useState(null);

  const history = useHistory();
  useEffect(() => {
    const auth = getAuth(firebase);

    const onAuthCancell = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken(true).then((token) => settoken(token));
      }
    });

    return onAuthCancell();
  }, []);
  const {
    data: pedidos,
    loading,
    error,
  } = useFetchAuth(`${urlServices.URL_SERVICE_TIENDA}api/v1/Pedidos`, token);
  if (!auth.token) {
    return <Redirect to="/unauthorized" />;
  }

  return (
    <Container>
      <Box my={5}>
        <Typography variant="h4" align="center">
          Mis pedidos
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" mt={20}>
            <Loader type="ThreeDots" color="#fe5000" height={150} width={150} />
          </Box>
        ) : error ? (
          <Box mt={20}>
            <Typography variant="h2" align="center">
              <WarningOutlined color="primary" style={{ fontSize: 150 }} />
            </Typography>
            <Typography variant="h4" align="center">
              {" "}
              Error al obtener mis pedidos
            </Typography>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                component="span"
                color="primary"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Recargar <RefreshOutlined color="primary" />
              </Button>
            </Box>
          </Box>
        ) : (
          <Box mt={10}>
            {pedidos.length <= 0 ? (
              <Container>
                <Typography variant="h4" align="center">
                  <SentimentDissatisfiedOutlined
                    color="primary"
                    style={{ fontSize: 150 }}
                  />
                </Typography>
                <Typography variant="h2" align="center">
                  No has hecho ningún pedido
                </Typography>
                <Box mt={5} display="flex" justifyContent="center">
                  <Button
                    component="span"
                    color="primary"
                    onClick={() => {
                      history.push("/productos");
                    }}
                  >
                    Ver productos
                  </Button>
                </Box>
              </Container>
            ) : (
              <ListPedidos pedidos={pedidos} />
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default MisPedidos;
