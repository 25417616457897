import { getLocalUnregistredUser } from "../helper/UnregistredLocalStorage";
import { unregistredTypes } from "../types/types";


export const unregistredReducer = (
    state = getLocalUnregistredUser(),
    action
  ) => {
    switch (action.type) {
      case unregistredTypes.get_unregistred:
        return action.payload;
      case unregistredTypes.set_unregistred:
        return action.payload;
      default:
        return state;
    }
  };
  