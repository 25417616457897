
import { PayPalButtons } from "@paypal/react-paypal-js";
import React from "react";
import { useSelector } from "react-redux";

const PayPalPayment = ({carrito ,onPayPalClick,onPayPalCreateOrder,onPayPalError,onPayPalCancel,onPayPalApprove,forceReRender}) => {
  const { costoEnvio: envioPedido } = useSelector((state) => state.pedido);
  const subTotal = carrito
    .map((item) => (item.iva * item.precio + item.precio) * item.cantidad)
    .reduce((a, b) => a + b, 0);
  return (

        <PayPalButtons
          onClick={(data, actions) => {
            onPayPalClick(data, actions);
          }}
          createOrder={(data, actions) =>
            onPayPalCreateOrder(
              { data, total: subTotal + envioPedido },
              actions
            )
          }
          forceReRender={forceReRender}
          onApprove={onPayPalApprove}
          onError={onPayPalError}
          onCancel={onPayPalCancel}
          label="comprar"
          style={{ color: "blue", layout: "vertical" }}
        />

  );
};

export default PayPalPayment;
