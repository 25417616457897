import { postConfig } from "../helper/postConfig";
import { urlServices } from "../helper/urlsBase";

export const updateCarritoOlvidado = async (carrito, correo) => {
  try {
    if (carrito) {
      const detalle = carrito.map((p) => {
        return {
          idProducto: p.id,
          cantidad: p.cantidad,
        };
      });
      const result = await fetch(
        `${urlServices.URL_SERVICE_TIENDA}api/v1/Carrito/Olvidado`,
        postConfig({
          correo,
          detalle,
        })
      );
      if (result.ok) {
        return true;
      }
    } else {
    }
  } catch (e) {
    console.log(e);
  }
  return false;
};

