import React from "react";

import PropTypes from "prop-types";
import { Breadcrumbs, Typography, Box, Container } from "@mui/material";
import { ArrowForwardIosOutlined } from "@mui/icons-material";

const Stepper = ({ stepp }) => {
  return (
    <Container>
      <Box display="flex" justifyContent="center" my={5}>
        <div role="presentation">
          <Breadcrumbs
            separator={<ArrowForwardIosOutlined style={{ fontSize: 10 }} />}
            aria-label="breadcrumb"
          >
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              variant="subtitle1"
              fontWeight="bold"
              color={stepp === "carrito" ? "primary" : "inherit"}
            >
              Carrito
            </Typography>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              variant="subtitle1"
              fontWeight="bold"
              color={stepp === "formEnvio" ? "primary" : "inherit"}
            >
              Datos de envío
            </Typography>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              variant="subtitle1"
              fontWeight="bold"
              color={stepp === "confirmarPago" ? "primary" : "inherit"}
            >
              Pago
            </Typography>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              variant="subtitle1"
              fontWeight="bold"
              color={stepp === "ordencompletada" ? "#fe5000" : "inherit"}
            >
              Orden Completada
            </Typography>
          </Breadcrumbs>
        </div>
      </Box>
    </Container>
  );
};
Stepper.propTypes = {
  stepp: PropTypes.string.isRequired,
};

export default Stepper;
