export const carritoTypes = {
  get: "[CARRITO] Get",
  add: "[CARRITO] Add",
  delete_producto: "[CARRITO] Delete",
  edit_producto_cantidad: "[CARRITO] EditProductoCantidad",
  edit_producto: "[CARRITO] EditProducto",
  delete_carrito: "[CARRITO] DeleteCarrito",
  edit_carrito: "[CARRITO] EditCarrito",
};

export const uiTypes = {
  show_error: "[UI]  ShowError",
  dismiss_error: "[UI] DismissError",
};

export const disponibilidadTypes = {
  edit_disponibilidad: "[DISPONIBILIDAD] EditDisponibilidad",
};

export const pedidoTypes = {
  editar_detalle: "[PEDIDO] ModificarPedido",
  editar_info_pedido: "[PEDIDO] EditInfoPedido",
  editar_validacion_pedido: "[PEDIDO] EditarValidacion",
  edit_costo_envio: "[PEDIDO] EditCostoEnvio",
};

export const authTypes = {
  login: "[AUTH] Login",
  loading: "[AUTH] Loading",
  logout: "[AUTH] Logout",
  registro: "[AUTH] Registro",
  logedUser: "[AUTH] LogedUser",
  error: "[AUTH] Error",
};

export const direccionesTypes = {
  get_direcciones: "[DIRECCIONES] Get",
  update_direcciones: "[DIRECCIONES] Update",
  delete_direcciones: "[DIRECCIONES] Delete",
  add_direcciones: "[DIRECCIONES] Add",
  load_direcciones:"[DIRECCIONES] Loading",
  select_direccion:"[DIRECCIONES] Select"
};


export const unregistredTypes = {

  set_unregistred:" [UNREGISTRED] set",
  get_unregistred:"[UNREGISTRED] get"
}