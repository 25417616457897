import Slider from "react-slick";
import ProductoRelacionadoItem from "../infoproducto/ProductoRelacionadoItem";
import { Box, Typography } from "@mui/material";
import Loader from "react-loader-spinner";
import { settings } from "../../helper/configSliders";
import { green } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { urlServices } from "../../helper/urlsBase";
import { useFetch } from "../../hooks/useFetch";
import { useEffect } from "react";

const useStyle = makeStyles((theme) => ({
  titleDestacados: {
    backgroundColor: green[500],
    color: "white",
    padding: ".5rem 1.8rem",
  },
}));
const Destacados = () => {
  const classes = useStyle();

  const { data: destacados, loading } = useFetch(
    `${urlServices.URL_SERVICE_TIENDA}api/v2/Productos/Destacados`
  );

  
  useEffect(() => {
    
    console.log(destacados);
  }, [destacados])
  
  

  return (
    <Box>
      {loading ? (
        <Box mt={10} display="flex" justifyContent="center">
          <Loader type="ThreeDots" color="#fe5000" height={80} width={80} />
        </Box>
      ) : destacados?.productos?.length > 0 ? (
        <>
          <Box justifyContent="center" display="flex" my={3}>
            <Typography
              variant="h6"
              align="center"
              className={classes.titleDestacados}
            >
              DESTACADOS
            </Typography>
          </Box>
          <Box justifyContent="center">
            <Slider {...settings(destacados, true)}>
              {destacados?.productos?.map((producto) => {
                return (
                  <Box key={producto.itemCode} height="100%">
                    <ProductoRelacionadoItem producto={producto} />
                  </Box>
                );
              })}
            </Slider>
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default Destacados;
