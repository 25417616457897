import moment from "moment";
import "moment/locale/es-mx";
import logo from "../../assets/img/LogoPegaduro.png";
import { Grid, Box } from "@mui/material";
import { Typography } from "@mui/material";
const InforOrden = ({ pedido, cargo }) => {
  moment.locale("es-mx");
  return (
    <>
      <Grid container >
        <Grid item xs={12} md={5}>
          <Box display="flex" justifyContent="center">
            <img
              style={{ maxWidth: "100%" }}
              alt="industrias pegaduro"
              src={logo}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            <Typography align="center" variant="subtitle2" fontWeight="bold">
              Id:
              <Typography align="center" variant="body2">
                {pedido.id}
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography align="center" variant="subtitle2" fontWeight="bold">
              Fecha de su orden:
              <Typography align="center" variant="body2">
                {moment(pedido?.fecha).format("LLL")}
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography align="center" variant="subtitle2" fontWeight="bold">
              Correo electrónico:
              <Typography align="center" variant="body2">
                {pedido?.correo ? pedido?.correo : "Coreo no disponible"}
              </Typography>
            </Typography>
          </Box>

          <Box>
            <Typography align="center" variant="subtitle2" fontWeight="bold">
              Dirección de envió:
              <Typography align="center" variant="body2">
                {` ${pedido?.direccion?.calle} #${pedido?.direccion?.numero} ${pedido?.direccion?.colonia} ${pedido?.direccion?.municipio} , ${pedido?.direccion?.estado}`}
              </Typography>
            </Typography>
          </Box>

          {!pedido.pagado && (
            <Box>
              <Typography align="center" variant="subtitle2" fontWeight="bold">
                Estado del pagó:
                <Typography align="center" variant="body2">
                  {cargo?.estatusPago}
                </Typography>
              </Typography>
            </Box>
          )}
          {pedido?.comentarios && (
            <Box>
              <Typography align="center" variant="subtitle2" fontWeight="bold">
                Comentarios del pedido:
                <Typography align="center" variant="body2">
                  {pedido?.comentarios}
                </Typography>
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default InforOrden;
