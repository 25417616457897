import { useState ,useEffect} from "react";
import {Button,Dialog,DialogContent,DialogTitle,TextField,Box,Typography,
  Alert,FormControl,InputLabel,MenuItem,Select,Grid
} from "@mui/material";
import { DraftsOutlined } from "@mui/icons-material";
import useForm from "../../hooks/useForm";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUnregistedUserAction } from "../../actions/unregistred";
import validator from "validator";
import { suscribir } from "../../data/notificaciones";
import {getRemoteGirosEmpresa} from '../../data/notificaciones'

export const PopUpCorreo = ({ open, handleClose,isCarrito =true }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { form, handleChange } = useForm({email: "",giro:""});
  const { email,giro } = form;
  const [error, setError] = useState("");
  const [giros,setGiros] = useState([])
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validator.isEmpty(email)&&!validator.isEmpty(giro)) {
      if (validator.isEmail(email)) {
        dispatch(setUnregistedUserAction({ email ,giro}));
        await suscribir({correo:email,giro}, 5);
        if(isCarrito)
        {
          history.push("/carrito");
        }
        handleClose();
      } else {
        setError("El correo que intenta ingresar no es valido");
      }
    } else {
      setError("Ingrese un correo electronico y giro, por favor");
    }
  };

  const getGirosEmpresa = async ()=> {
    setGiros(await getRemoteGirosEmpresa());
  }
  
  useEffect(() => {
    getGirosEmpresa()
  }, [])
  return (
    <>
      <Dialog open={open} fullWidth={true} onClose={handleClose}>
        <DialogTitle>
          <Box display="flex" justifyContent="center" px={10}>
            <DraftsOutlined sx={{ fontSize: 120 }} color="primary" />
          </Box>
          <Typography variant="subtitle2" align="center" fontWeight="bold">
            {isCarrito?"Para continuar necesitamos de un correo electrónico":"Registrate a nuestro Newsletter!"} 
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Typography variant="caption" textAlign={"center"} align="center">
              Usamos Sendinblue como plataforma de marketing. Al hacer clic a
              continuación para enviar este formulario, consiente que la
              información proporcionada sea transferida a Sendinblue para su
              procesamiento.
            </Typography>
            <Box mt={3}>
              <Grid container spacing={1}>
                  <Grid item sm={6}>
                    <FormControl fullWidth  size="small">
                     <InputLabel  id="demo-select-small-label">Giro</InputLabel>
                     <Select fullWidth name="giro" size="small" labelId="demo-select-small-label" id="demo-select-small" value={giro} label="Giro" onChange={handleChange}>
                       {giros.map(({id,nombre})=>  <MenuItem key={id} value={nombre}>{nombre}</MenuItem>)}
                     </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField name="email" label="Correo Electrónico" fullWidth size="small" onChange={handleChange} autoComplete="off" />
                  </Grid>
              </Grid>
            </Box>
            <Box my={2}>
              {error.trim() !== "" && (
                <Alert variant="outlined" severity="error">
                  {error}
                </Alert>
              )}
            </Box>
            <Box mt={4}>
              <Button type="submit" size="small" variant="contained" fullWidth>
                {isCarrito?"Continuar":"Registrarme"}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};


export default PopUpCorreo;