import { direccionesTypes } from "../types/types";

export const direccionesReducer = (state = [], action) => {
  switch (action.type) {
    case direccionesTypes.get_direcciones:
      return action.payload;
    case direccionesTypes.add_direcciones:
      return action.payload;
    case direccionesTypes.update_direcciones:
      return action.payload;
    case direccionesTypes.delete_direcciones:
      return action.payload;
    case direccionesTypes.load_direcciones:
      return action.payload;
    case direccionesTypes.select_direccion:
      return action.payload
    default:
      return state;
  }
};
