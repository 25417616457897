import { clearAuthLs, setAuthLS } from "../helper/authLocalStorage";
import { postConfigAuth } from "../helper/postConfig";
import { urlServices } from "../helper/urlsBase";
import { authTypes } from "../types/types";
import { getAuth, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail} from "firebase/auth";
import { firebase } from "../firebase/config";
import { getUtmsLocalStorage } from "../helper/utmLocalStorage";

export const registroAction = (registro) => {
  return async (dispatch) => {
    dispatch({
      type: authTypes.loading,
      payload: { loading: true },
    });
    const auth = getAuth(firebase);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth,registro.correo,registro.password);

      const httpRequest = postConfigAuth({...registro,utms:getUtmsLocalStorage()},userCredential.user.accessToken);
      const response = await fetch(`${urlServices.URL_SERVICE_TIENDA}api/v1/Access/Registro`,httpRequest);
      if (response.ok) {
        sendEmailVerification(userCredential?.user, {url: "https://tienda.pegaduro.com.mx/auth/login"});
        dispatch({type: authTypes.registro,payload: {  type: authTypes.registro,  email: registro.correo,  loading: false,  error: null}});
      } else {
        const error = response.text();
        dispatch({type: authTypes.error,payload: {  loading: false,  error: error,}});
      }
    } catch (e) {
      if (e.code === "auth/email-already-in-use") {
          dispatch({
            type: authTypes.error,
            payload: {
              loading: false,
              token: false,
              error: "El correo que estas intentando ingresar ya está en uso",
            },
          });
      } else {
        dispatch({
          type: authTypes.error,
          payload: {
            loading: false,
            token: false,
            error: "Error al crear la cuenta",
          },
        });
      }
    }
  };
};

export const enviarEmailVerificacion = async () => {
  try {
    const auth = getAuth(firebase);
    console.log(auth?.currentUser);
    await sendEmailVerification(auth?.currentUser, {
      url: "https://tienda.pegaduro.com.mx/auth/login",
    });
    return {
      enviado: true,
      mensaje: "El correo se envió de forma correcta",
    };
  } catch (e) {
    if (e.code === "auth/too-many-requests") {
      return {
        enviado: false,
        mensaje:
          "Ya se ha reenviado un correo con anterioridad, favor de intentar en unos minutos.",
      };
    }
    return {
      enviado: false,
      mensaje: "Error al enviar el correo de confirmación",
    };
  }
};

export const singInGoogleAction = () => {
  return (dispatch) => {
    dispatch({
      type: authTypes.loading,
      payload: { loading: true },
    });
    const auth = getAuth(firebase);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((userCredential) => {
        dispatch(loginGoogleAction(userCredential));
      })
      .catch((e) => {
        if (e.code !== "auth/cancelled-popup-request") {
          dispatch({
            type: authTypes.error,
            payload: {
              loading: false,
              token: false,
              error: "Error al ingresar con google",
            },
          });
        }
      });
  };
};

export const loginUserPasswordAction = (email, password) => {
  return (dispatch) => {
    dispatch({
      type: authTypes.loading,
      payload: { loading: true },
    });
    const auth = getAuth(firebase);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
        dispatch(loginAction(userCredential));
      })
      .catch((e) => {
        if (e.code === "auth/invalid-email") {
          dispatch({type: authTypes.error,payload: {  loading: false,  token: false,  error: "El correo no es válido",} });
        } else if (e.code === "auth/user-not-found" ||e.code === "auth/wrong-password") {
          dispatch({
            type: authTypes.error,
            payload: { loading: false, token: false, error: "Usuario y/o Contraseña incorrecta"}});
        } else if (e.code === "auth/too-many-requests") {
          dispatch({
            type: authTypes.error,
            payload: {loading: false,token: false,error: "Has realizado demasiados intentos en poco tiempo, por favor intenta en unos segundos"},
          });
        } else {
          dispatch({
            type: authTypes.error,
            payload: {
              loading: false,
              token: false,
              error: "Hubo un error al intentar autenticarse",
            },
          });
        }
      });
  };
};

export const logOutAction = () => {
  return (dispatch) => {
    const auth = getAuth(firebase);
    auth
      .signOut()
      .then(() => dispatch({ type: authTypes.logout, payload: clearAuthLs() }));
  };
};

export const logedUserAction = (email, token) => {
  return async (dispatch) => {
    if (token) {
      const httpRequest = postConfigAuth({ correo: email ,}, token);

      const response = await fetch(`${urlServices.URL_SERVICE_TIENDA}api/v1/Access/Login`,httpRequest);

      if (response.ok) {
        const json = await response.json();
        const auth = setAuthLS(json);
        dispatch({
          type: authTypes.login,
          payload: {
            ...auth,
            token: true,
            loading: false,
          },
        });
      } else {
        const error = await response.text();
        dispatch(logOutAction());
        dispatch({
          type: authTypes.error,
          payload: {
            loading: false,
            error: error,
          },
        });
      }
    }
  };
};

const loginAction = (userCredential) => {
  return async (dispatch) => {
    const { email, accessToken: token } = userCredential.user;

    try {
      if (token) {
        const httpRequest = postConfigAuth(
          {
            correo: email,
          },
          token
        );

        const response = await fetch(
          `${urlServices.URL_SERVICE_TIENDA}api/v1/Access/Login`,
          httpRequest
        );
        if (response.ok) {
          const json = await response.json();
          const auth = {
            ...json,
            token: true,
            error: null,
            loading: false,
          };
          dispatch({
            type: authTypes.login,
            payload: setAuthLS(auth),
          });
        } else {
          const error = await response.text();
          getAuth(firebase)
            .signOut()
            .then(() =>
              dispatch({
                type: authTypes.error,
                payload: {
                  loading: false,
                  token: false,
                  error: error,
                },
              })
            );
        }
      }
    } catch (e) {
      dispatch({
        type: authTypes.error,
        payload: {
          loading: false,
          token: false,
          error: "Error al ingresar intentelo mas tarde",
        },
      });
    }
  };
};

const loginGoogleAction = (userCredential) => {
  return async (dispatch) => {
    const { user } = userCredential;
    const { email, accessToken: token } = user;
    try {
      if (token) {
        const httpRequest = postConfigAuth({nombres: user.displayName,correo: email,utms:getUtmsLocalStorage()},token );

        const response = await fetch(`${urlServices.URL_SERVICE_TIENDA}api/v1/Access/LoginGoogle`,httpRequest);
        if (response.ok) {
          const json = await response.json();
          const auth = setAuthLS({...json,token: true});
          dispatch({
            type: authTypes.login,
            payload: {...auth,token: true,error: null,loading: false},
          });
        } else {
          const error = await response.text();
          dispatch(logOutAction());
          dispatch({
            type: authTypes.error,
            payload: {
              loading: false,
              error: error,
            },
          });
        }
      }
    } catch (e) {
      dispatch({
        type: authTypes.error,
        payload: {
          loading: false,
          token: false,
          error: "Error al ingresar intentelo mas tarde",
        },
      });
    }
  };
};

export const enviarEmailRestPassword = async (email) => {
  try {
    const auth = getAuth(firebase);

    await sendPasswordResetEmail(auth, email);
    return {
      enviado: true,
      mensaje: "El correo se envió de forma correcta",
    };
  } catch (e) {
    if (e.code === "auth/too-many-requests") {
      return {
        enviado: false,
        mensaje:
          "Ya se ha reenviado un correo con anterioridad, favor de intentar en unos minutos.",
      };
    }
    if (e.code === "auth/missing-email") {
      return {
        enviado: false,
        mensaje: "Ingrese un correo por favor.",
      };
    }
    if (e.code === "auth/invalid-email") {
      return {
        enviado: false,
        mensaje: "Correo no válido",
      };
    }
    if (e.code === "auth/user-not-found") {
      return {
        enviado: false,
        mensaje: "Este correo no está registrado",
      };
    }
    console.log(e.code);
    return {
      enviado: false,
      mensaje: "Error al enviar el correo de confirmación",
    };
  }
};
