import { useState, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  Paper,
  Box,
  TextField,
  Grid,
  Typography,
  Button,
  Alert,
} from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import Loader from "react-loader-spinner";
import Copyright from "../components/shared/Copyright";
import useForm from "../hooks/useForm";
import { enviarEmailRestPassword } from "../actions/auth";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      padding: 100,
    },
  },
  iconAvatar: {
    fontSize: 70,
    marginBottom: 15,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    outline: "none",
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
}));
const ResetPassword = () => {
  const classes = useStyles();
  const { token } = useSelector((state) => state.auth);
  const history = useHistory();
  const [loading] = useState(false);
  const [mensaje, setMensaje] = useState({
    enviado: null,
    mensaje: "",
  });

  const { handleChange, form, clearForm } = useForm({
    email: "",
    confirmEmail: "",
  });
  const handleClickSendEmail = async () => {
    const mensaje = await enviarEmailRestPassword(form.email);
    clearForm();
    setMensaje(mensaje);
  };
  useEffect(() => {
    if (token) {
      history.push("/");
    }
  }, [token, history]);
  return (
    <Grid item xs={12} sm={8} md={6} component={Paper} elevation={2} square>
      <div className={classes.paper}>
        <LockOutlined className={classes.iconAvatar} color="primary" />
        <Typography component="h1" variant="h4">
          Tienda Pegaduro
        </Typography>

        <form
          className={classes.form}
          onSubmit={handleClickSendEmail}
          noValidate
        >
          {mensaje.mensaje && (
            <Box mt={2} mb={2}>
              <Alert
                variant="filled"
                severity={mensaje.enviado ? "success" : "error"}
              >
                {mensaje.mensaje}
              </Alert>
            </Box>
          )}

          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            onChange={handleChange}
            name="email"
            value={form.email}
            label="Correo electrónico"
            type="email"
            id="email"
          />

          {loading ? (
            <Box display="flex" justifyContent="center" mt={5} mb={5}>
              <Loader type="TailSpin" color="#fe5000" height={50} width={50} />
            </Box>
          ) : (
            <Box>
              <Box mt={4} mb={2} display="flex" justifyContent="flex-end">
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={handleClickSendEmail}
                >
                  Enviar corrreo
                </Button>
              </Box>
            </Box>
          )}
        </form>
        <Box display="flex" justifyContent="flex-end"></Box>
        <Grid container>
          <Grid item xs>
            <RouterLink
              to="/auth/login"
              variant="body2"
              className={classes.link}
            >
              {"Ya tienes cuenta? Ingresa"}
            </RouterLink>
          </Grid>
          <Grid item>
            <RouterLink to="/auth/registro" className={classes.link}>
              {"No tienes cuenta? Registrate"}
            </RouterLink>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </div>
    </Grid>
  );
};

export default ResetPassword;
