import { Box, Button, Rating, TextField } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import { firebase } from "../../../firebase/config";
import { validateLenght } from "../../../helper/validaciones";
import useForm from "../../../hooks/useForm";
import { postComentario } from "../../../repository/comentariosRepository";
const FormComentario = ({ idProductoHdr, getNewComents }) => {
  const [token, settoken] = useState(null);
  const { form, handleChange } = useForm({ comentario: "" });
  const { comentario } = form;
  const [ratingValue, setRatingValue] = useState(5);

  useEffect(() => {
    const auth = getAuth(firebase);
    const cancell = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user) {
          user.getIdToken(true).then((token) => {
            settoken(token);
          });
        }
      }
    });
    return () => {
      cancell();
    };
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await postComentario(
      {
        comentario,
        calificacion: ratingValue,
        idProducto: idProductoHdr,
      },
      token
    );
    if (response) {
      getNewComents();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Rating
        precision={0.5}
        value={ratingValue}
        onChange={(e, newValue) => setRatingValue(newValue)}
      />
      <Box mt={1}>
        <TextField
          id="outlined-multiline-static"
          label="Comentario"
          multiline
          fullWidth
          variant="standard"
          rows={5}
          onChange={handleChange}
          name="comentario"
          value={comentario}
          onKeyPress={validateLenght}
          placeholder="¿Que opinas sobre este producto?"
        />
      </Box>
      <Box mt={2}>
        <Button type="submit">Comentar</Button>
      </Box>
    </form>
  );
};

export default FormComentario;
