import React from "react";
import {
  Box,
  Paper,
  TableContainer,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { EngineeringOutlined } from "@mui/icons-material";
const Espesificaciones = ({ espesificaciones }) => {
  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center",marginBottom:5 }}>
        <EngineeringOutlined sx={{ fontSize: 30, marginRight: 1 }} color="primary" />
        <Typography variant="h5" fontWeight="bold"> Especificaciones del producto</Typography>
      </Box>
      <TableContainer component={Paper} elevation={0}>

        <Table sx={{ maxWidth: 800 }}>

          <TableBody>
            {espesificaciones.map((row) => (
              <TableRow
                key={row.nombre}
              >
                <TableCell align="center" >{row.nombre}</TableCell>
                <TableCell align="center" >{row.valor}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Espesificaciones;
