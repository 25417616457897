import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { applyActionCode, getAuth } from "@firebase/auth";
import {
  EmailOutlined,
  ArrowRightAltOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";
import Loader from "react-loader-spinner";
import { firebase } from "../../firebase/config";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  link: {
    color: "inherit",
    textDecoration: "inherit",
  },
}));

const VerificarCorreo = ({ actionCode }) => {
  const classes = useStyle();
  const [verificado, setverificado] = useState({
    verificado: true,
    mensaje: "",
    loading: false,
  });
  useEffect(() => {
    const auth = getAuth(firebase);
    setverificado({
      verificado: true,
      mensaje: "Se ha verificado el correo de forma exitosa!",
      loading: true,
    });
    applyActionCode(auth, actionCode)
      .then((resp) => {
        console.log(resp);
        setverificado({
          verificado: true,
          mensaje: "Se ha verificado el correo de forma exitosa!",
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        if (auth.currentUser?.emailVerified) {
          setverificado({
            verificado: false,
            mensaje: "El usuario ya ha sido verificado",
            loading: false,
          });
        } else {
          setverificado({
            verificado: false,
            mensaje: "El enlacé ya se ha usado o ha caducado",
            loading: false,
          });
        }
      });
  }, [actionCode]);

  return (
    <Container>
      {verificado.loading ? (
        <Box pt={20} display="flex" justifyContent="center">
          <Loader type="ThreeDots" color="#fe5000" height={150} width={150} />
        </Box>
      ) : verificado.verificado ? (
        <Box display="flex" justifyContent="center" mt={20}>
          <Box>
            <Box display="flex" justifyContent="center">
              <EmailOutlined style={{ fontSize: 200, color: "#4a934a" }} />
            </Box>
            <Typography variant="h4" align="center">
              El correo se ha verificado de forma exitosa!
            </Typography>
            <Box mt={4}>
              <Typography variant="h6" align="center">
                {verificado.mensaje}
              </Typography>
            </Box>
            <Box mt={2}>
              <Link to="auth/login" className={classes.link}>
                <Typography
                  variant="h6"
                  color="primary"
                  align="center"
                  justifyContent="center"
                >
                  Ingresa
                  <ArrowRightAltOutlined />
                </Typography>{" "}
              </Link>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center">
          <Box pt={20}>
            <Typography variant="h6" align="center" fontWeight="bold">
              <WarningOutlined color="primary" style={{ fontSize: 200 }} />
            </Typography>
            <Typography variant="h6" align="center">
              Error al verificar el correo
            </Typography>
            <Box mt={2}>
              <Typography variant="h4" align="center">
                {verificado.mensaje}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default VerificarCorreo;
