import Slider from "react-slick";
import ProductoRelacionadoItem from "../infoproducto/ProductoRelacionadoItem";
import { Box, Container, Typography } from "@mui/material";
import Loader from "react-loader-spinner";
import { settings } from "../../helper/configSliders";
import { makeStyles } from "@mui/styles";
import { urlServices } from "../../helper/urlsBase";
import { useFetch } from "../../hooks/useFetch";

const useStyle = makeStyles((theme) => ({
  titleDestacados: {
    color: "white",
  },
}));
const SinCostoEnvio = () => {
  const classes = useStyle();
  const url = `${urlServices.URL_SERVICE_TIENDA}api/v2/Productos/EnvioGratis`;

  const { data: sinCostoEnvio, loading } = useFetch(url);

  return (
    <Box className="gradiant-bg-primary-dark" px={1.5} pb={8}>
      {loading ? (
        <Box mt={10} display="flex" justifyContent="center">
          <Loader type="ThreeDots" color="#fe5000" height={80} width={80} />
        </Box>
      ) : sinCostoEnvio?.productos?.length > 0 ? (
        <>
          <Box justifyContent="center" display="flex" p={3}>
            <Typography variant="h6" align="center" className={classes.titleDestacados}>
              SIN COSTO DE ENVÍO
            </Typography>
          </Box>
          <Container>
            <Slider {...settings(sinCostoEnvio, true)}>
              {sinCostoEnvio?.productos?.map((producto) => {
                return (
                  <Box key={producto.itemCode}>
                    <ProductoRelacionadoItem
                      producto={producto}
                      elevation={2}
                    />
                  </Box>
                );
              })}
            </Slider>
          </Container>
        </>
      ) : null}
    </Box>
  );
};

export default SinCostoEnvio;
