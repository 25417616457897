import { SentimentDissatisfiedOutlined } from "@mui/icons-material";
import { Container,Typography,Box, Divider } from "@mui/material";
import React from "react";
const OrdenError = ({ cargo }) => {
  return (
    <>
      <Container>
        <Box display="flex" justifyContent="center">
          <SentimentDissatisfiedOutlined
            style={{ fontSize: 150, color: "red", opacity: ".7" }}
          />
        </Box>
        <Typography align="center" variant="h6">
          <p style={{ color: "red", fontWeight: "bold", opacity: ".7" }}>
            {cargo?.error_message}
          </p>
        </Typography>
      </Container>
      <Divider />
    </>
  );
};

export default OrdenError;
