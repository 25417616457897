import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { urlServices } from "../../helper/urlsBase";
import { useFetch } from "../../hooks/useFetch";
import { Avatar, Tab } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { HomeOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  tabsContainer: {
    width: "14%",
  },
});

const TabsCategorias = () => {
  const classes = useStyles();
  const [categorias, setcategorias] = useState([]);
  const url = `${urlServices.URL_SERVICE_TIENDA}api/v2/Productos/Catalogos`;
  const { data: catalogos } = useFetch(url);
  const pathName = window.location.pathname;
  const [value, setValue] = useState(false);

  useEffect(() => {
    if (categorias?.length > 0) {
      const categoria = categorias.find(
        (e) =>
          `/productos-${e.nombre.replaceAll(" ", "-")}/${e.id}` === pathName
      );
      if (categoria) {
        return setValue(
          `/productos-${categoria.nombre.replaceAll(" ", "-")}/${categoria.id}`
        );
      } else if (pathName === "/productos") {
        return setValue("/productos");
      }
      return setValue(false);
    }
  }, [pathName, categorias]);
  useEffect(() => {
    if (catalogos) {
      const sortedCategorias = catalogos.sort((a, b) => {
        if (a.orden < b.orden) {
          return -1;
        }
        if (a.orden > b.orden) {
          return 1;
        }
        return 0;
      });
      sortedCategorias.unshift({
        id: 0,
        nombre: "Todos",
        descripcion: null,
        orden: 0,
        iconoUrl: "",
        navegacion: true,
      });
      setcategorias(sortedCategorias);
    }
  }, [catalogos]);

  return (
    <div>
      {categorias?.length > 0 && (
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          {categorias?.map((item) => {
            if (item.navegacion) {
              if (item.id === 0) {
                return (
                  <Tab
                    wrapped
                    icon={<HomeOutlined style={{ fontSize: 40 }} />}
                    key={item.id}
                    value={`/productos`}
                    to={`/productos`}
                    component={Link}
                    label={item.nombre}
                    classes={{ root: classes.tabsContainer }}
                  />
                );
              }
              return (
                <Tab
                  wrapped
                  icon={<Avatar src={item.iconoUrl} alt={item.nombre} />}
                  key={item.id}
                  value={`/productos-${item.nombre.replaceAll(" ", "-")}/${
                    item.id
                  }`}
                  to={`/productos-${item.nombre.replaceAll(" ", "-")}/${
                    item.id
                  }`}
                  component={Link}
                  label={item.nombre}
                  classes={{ root: classes.tabsContainer }}
                />
              );
            } else {
              return null;
            }
          })}
        </Tabs>
      )}
    </div>
  );
};

export default TabsCategorias;
