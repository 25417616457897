import { useEffect } from "react";
import currencyFormatter from "currency-formatter";
import InforOrden from "./InforOrden";
import OrdenError from "./OrdenError";
import OrdenSucces from "./OrdenSucces";
import { Box, Divider, Paper, Typography } from "@mui/material";
import ProductoOrden from "./ProductoOrden";
import { makeStyles } from "@mui/styles";
import { green } from "@mui/material/colors";
import ReactPixel from "react-facebook-pixel";
const useStyle = makeStyles((theme) => ({
  price: {
    color: green[500],
    fontWeight: "bold",
  },
}));

const CardProductoOrden = ({ orden }) => {
  const classes = useStyle();
  const { pedido, cargo } = orden;

  useEffect(() => {
    if (pedido?.pagado) {
      ReactPixel.track("PURCHASE", {
        value: pedido.total + pedido?.costoEnvio,
        currency: "MXN ",
      });
    }
  }, [pedido]);

  useEffect(() => {
    if (pedido?.pagado) {
      ReactPixel.trackCustom("Comprar", {
        value: pedido.total + pedido?.costoEnvio,
        currency: "MXN ",
      });
    }
  }, [pedido]);

  return (
    <Paper elevation={4}>
      <Box p={5}>
        <div className="card-body">
          {cargo.error_message ? (
            <OrdenError cargo={cargo} />
          ) : (
            <OrdenSucces orden={orden} />
          )}
          <Box mt={5} mb={5}>
            <Typography variant="h6" fontWeight="bold" align="center">
              {`Detalle de envío`.toUpperCase()}
            </Typography>
          </Box>
          <InforOrden pedido={pedido} cargo={cargo} />
          {/* Productos de la orden  */}

          <Box mt={5}>
            <Typography variant="h6" fontWeight="bold" align="center">
              Tus productos
            </Typography>
          </Box>
          <Box my={2}>
            {pedido?.productos.map((producto) => (
              <ProductoOrden
                key={producto.itemCode}
                large
                producto={producto}
              />
            ))}
          </Box>
          <Divider />

          <Box
            display="flex"
            justifyItems="center"
            justifyContent="space-between"
            alignItems="center"
            my={4}
          >
            <Typography variant="subtitle2" align="center" fontWeight="bold">
              Costo de envío
            </Typography>
            <Typography
              variant="caption"
              fontWeight="bold"
              className={classes.price}
            >
              {currencyFormatter.format(pedido?.costoEnvio, {
                code: "MXN",
              })}
              MXN
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyItems="center"
            justifyContent="space-between"
            alignItems="center"
            my={4}
          >
            <Typography variant="subtitle2" align="center" fontWeight="bold">
              Subtotal
            </Typography>
            <Typography
              variant="caption"
              fontWeight="bold"
              className={classes.price}
            >
              {currencyFormatter.format(pedido?.total, {
                code: "MXN",
              })}
              MXN
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyItems="center"
            justifyContent="space-between"
            alignItems="center"
            my={4}
          >
            <Typography variant="subtitle1" align="center" fontWeight="bold">
              Total + Costo Envio
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              className={classes.price}
            >
              {currencyFormatter.format(pedido.total + pedido?.costoEnvio, {
                code: "MXN",
              })}
              MXN
            </Typography>
          </Box>
          {/* <table className="shop_table cart-totals mb-0">
            <tbody>
              <tr>
                <td colSpan="2" className="border-top-0">
                  <h3 className="text-color-dark text-center">
                    <strong>Tus Productos</strong>
                  </h3>
                </td>
              </tr>
              {pedido?.productos.map((producto) => (
                <tr key={producto.itemCode}>
                  <td className="border-top-0 pt-0">
                    <strong className="d-block text-color-dark line-height-1 font-weight-semibold">
                      {producto.nombre} <br />
                      <span className="product-qty">x{producto.cantidad}</span>
                    </strong>
                  </td>
                  <td className="border-top-0 text-right align-top pt-0">
                    <span className="amount font-weight-medium ">
                      {currencyFormatter.format(producto.total, {
                        code: "USD",
                      })}
                      MXN
                    </span>
                  </td>
                </tr>
              ))}

             <tr className="cart-subtotal">
                <td className="border-top-0">
                  <strong className="text-color-dark">Subtotal</strong>
                </td>
                <td className="border-top-0 text-right">
                  <strong>
                    <span
                      className="amount font-weight-medium text-success"
                      style={{ fontSize: 16 }}
                    >
                      <strong>
                        {currencyFormatter.format(pedido?.total, {
                          code: "USD",
                        })}
                        MXN
                      </strong>
                    </span>
                  </strong>
                </td>
              </tr>  <tr className="total">
                <td>
                  <strong className="text-color-dark text-3-4">
                    Total + Costo Envio
                  </strong>
                </td>
                <td className="text-right">
                  <strong className="">
                    <span className="amount text-success text-5">
                      {currencyFormatter.format(
                        pedido.total + pedido?.costoEnvio,
                        {
                          code: "USD",
                        }
                      )}
                      MXN
                    </span>
                  </strong>
                </td>
              </tr>
            </tbody>
          </table> */}
        </div>
      </Box>
    </Paper>
  );
};

export default CardProductoOrden;
