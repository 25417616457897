import React from "react";
import currencyFormatter from "currency-formatter";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { green } from "@mui/material/colors";

const useStyle = makeStyles((theme) => ({
  price: {
    color: green[500],
    textAlign: "right",
  },
}));
const ProductoOrden = ({ producto, large = false }) => {
  const classes = useStyle();
  return (
    <Box mt={2}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant={large ? "h6" : "caption"} fontWeight="bold">
          {`${producto.nombre} `}
        </Typography>
        <Typography variant={large ? "body1" : "body1"} fontWeight="bold">
          x{producto.cantidad}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          align="left"
          className={classes.price}
        >
          {currencyFormatter.format(producto.total, {
            code: "MXN",
          })}
          {producto.moneda}
        </Typography>
      </Box>
    </Box>
  );
};

ProductoOrden.propTypes = {
  producto: PropTypes.object.isRequired,
};
export default ProductoOrden;
