import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { carritoReducer } from "../reducers/carritoReducer";
import { authReducer } from "../reducers/authReducer";
import { disponibilidadReducer } from "../reducers/disponibilidadReducer";
import { uiReducer } from "../reducers/uiReducer";
import { pedidoReducer } from "../reducers/pedidoReducer";
import { direccionesReducer } from "../reducers/direccionesReducer";
import { unregistredReducer } from "../reducers/unregistredReducer";
const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const reducers = combineReducers({
  auth: authReducer,
  carrito: carritoReducer,
  ui: uiReducer,
  disponibilidad: disponibilidadReducer,
  pedido: pedidoReducer,
  direcciones: direccionesReducer,
  unregistredUser: unregistredReducer,
});

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);
