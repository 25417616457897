import React from "react";
import PropTypes from "prop-types";
import { Box, Pagination, Container } from "@mui/material";
import { useHistory } from "react-router";
import useQueryParams from "../../hooks/useQueryParams";
const Paginador = ({ paginasTotales, paginaActual }) => {
  const history = useHistory();
  const { query } = useQueryParams();
  const handleChange = (event, value) => {
    query.delete("page");
    query.append("page", value);
    history.push({
      pathname: window.location.pathname,
      search: query.toString(),
    });
  };
  return (
    <Container>
      <Box display="flex" flexDirection="row-reverse" mt={10}>
        <Pagination
          page={paginaActual ? paginaActual : 2}
          count={paginasTotales}
          onChange={handleChange}
          color="primary"
        />
      </Box>
    </Container>
  );
};

Paginador.propTypes = {
  paginasTotales: PropTypes.number.isRequired,
  paginaActual: PropTypes.number.isRequired,
};
export default Paginador;
