import { useState, useEffect, Suspense } from "react";
import Loader from "react-loader-spinner";
import { urlServices } from "../../helper/urlsBase";
import { useFetch } from "../../hooks/useFetch";
import Slider from "react-slick";
import ProductoRelacionadoItem from "./ProductoRelacionadoItem";
import { Box, Typography } from "@mui/material";
import { settings } from "../../helper/configSliders";
const ProductosRelacionados = ({ producto }) => {
  const [itemCode, setItemCode] = useState(producto.itemCodeHdr);

  useEffect(() => {
    if (producto) {
      setItemCode(producto.itemCode);
    }
  }, [producto]);

  const ur = `${urlServices.URL_SERVICE_TIENDA}api/v2/Productos/Relacionados/${itemCode}`;
  const { data: productosRelacionados } = useFetch(ur);
  return (
    <Box mt={10}>
      <Suspense
        fallback={
          <Box mt={10} display="flex" justifyContent="center">
            <Loader type="ThreeDots" color="#fe5000" height={80} width={80} />
          </Box>
        }
      >
        {productosRelacionados?.length > 0 && (
          <>
            <Typography variant="subtitle1" color="primary" align="center">
              Los que compraron este producto, también se llevaron
            </Typography>
            <Box justifyContent="center">
              <Slider {...settings(productosRelacionados)}>
                {productosRelacionados?.map((producto) => {
                  return (
                    <Box key={producto.itemCode}>
                      <ProductoRelacionadoItem
                        isAddProducto={!producto?.itemCode?.includes("BASE")}
                        producto={producto}
                      />
                    </Box>
                  );
                })}
              </Slider>
            </Box>
          </>
        )}
      </Suspense>
    </Box>
  );
};

export default ProductosRelacionados;
