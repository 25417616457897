import { encryptStorage } from "./localStorage";

const unRegistredLsName = () => ({
  name: "unregus",
});

export const setLocalUnregistredUser = (usuario) => {
  encryptStorage.setItem(unRegistredLsName, usuario);
  return usuario;
};

export const getLocalUnregistredUser = () => {
  const usuario = encryptStorage.getItem(unRegistredLsName);
  if (usuario) {
    return usuario
  }
  return {}
};
