import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { ScrollToTop } from "react-router-scroll-to-top";
import AuthRouter from "./AuthRouter";
import MainContenetRoute from "./MainContenetRoute";
import { firebase } from "../firebase/config";
import {UtmParams} from './UtmParams' 

const AppRouter = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const auth = getAuth(firebase);
    onAuthStateChanged(auth, (user) => {
    
    });
  }, [dispatch]);
  return (
    
      <Router basename="/">
        <ScrollToTop />
        <UtmParams/>
        <Switch>
          <Route path="/auth" component={AuthRouter} />
          <Route path="/" component={MainContenetRoute} />
        </Switch>
      </Router>
    
  );
};

export default AppRouter;
