import React from "react";
import { Container, Box, Typography } from "@mui/material";
import Banner from "../components/index/Banner";
import Destacados from "../components/index/Destacados";
import SinCostoEnvio from "../components/index/SinCostoEnvio";
import { ArrowForwardIos } from "@mui/icons-material";
import { Link } from "react-router-dom";
const Index = () => {
  return (
    <>
      <Box>
        <Banner />
      </Box>
      <Box mt={-5} style={{ position: "relative" }}>
        <Container>
          <Destacados />
        </Container>
      </Box>
      <Box mt={15}>
        <SinCostoEnvio />
      </Box>
      <Box display="flex" flexDirection="row-reverse" pr={10} mt={10}>
        <Link
          to="/productos"
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <Typography alignContent="center" alignItems="center">
            Ver todos los productos <ArrowForwardIos style={{ fontSize: 12 }} />
          </Typography>
        </Link>
      </Box>
    </>
  );
};

export default Index;
