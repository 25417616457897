import {
  GradeOutlined,
  SentimentDissatisfiedOutlined,
} from "@mui/icons-material";
import { Box, Typography, Grid } from "@mui/material";
import { useState, useEffect, Suspense } from "react";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { getComentarios } from "../../../repository/comentariosRepository";
import Comentario from "./Comentario";
import FormComentario from "./FormComentario";
import NoAuthComent from "./NoAuthComent";

const ComentariosList = ({ idProductoHdr }) => {
  const { auth } = useSelector((state) => state);
  const [comentarios, setComentarios] = useState([]);
  const [newComent, setNewComent] = useState(1);
  useEffect(() => {
    getComentarios(idProductoHdr)
      .then((coment) => {
        setComentarios(coment);
      })
      .catch((e) => {});
  }, [idProductoHdr, newComent]);
  const getNewComents = () => setNewComent((c) => c + 1);
  return (
    <Box>
      <Suspense
        fallback={
          <Box mt={10} display="flex" justifyContent="center">
            <Loader type="ThreeDots" color="#fe5000" height={80} width={80} />
          </Box>
        }
      >
        <Box mb={2} sx={{ display: "flex", alignItems: "center" }}>
          <GradeOutlined
            sx={{ fontSize: 40, marginRight: 1 }}
            color="primary"
          />
          <Typography variant="h4" textAlign="left">
            Reseñas
          </Typography>
        </Box>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6} lg={6} sx={{ width: "100%" }}>
            {comentarios?.length !== 0 ? (
              comentarios?.map((comentario) => (
                <Box mt={3} key={comentario?.id}>
                  <Comentario comentario={comentario} />
                </Box>
              ))
            ) : (
              <Box mt={5}>
                <Box display="flex" justifyContent="center" mb={2}>
                  <SentimentDissatisfiedOutlined
                    color="primary"
                    style={{ fontSize: 50 }}
                  />
                </Box>
                <Typography textAlign="center">
                  Aún no hay reseñas del producto,sé el primero en darnos tu
                  opinión!
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            {auth?.token ? (
              <>
                <Typography variant="h6">
                  Dejanos saber tu opinion acerca del producto
                </Typography>
                <Box mt={1}>
                  <FormComentario
                    getNewComents={getNewComents}
                    idProductoHdr={idProductoHdr}
                  />
                </Box>
              </>
            ) : (
              <NoAuthComent />
            )}
          </Grid>
        </Grid>
      </Suspense>
    </Box>
  );
};

export default ComentariosList;
