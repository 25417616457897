import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { useParams } from "react-router-dom";
import ListProducto from "../components/catalogo/ListProducto";
import Paginador from "../components/catalogo/Paginador";
import ErrorInfo from "../components/shared/ErrorInfo";
import { urlServices } from "../helper/urlsBase";
import { useFetch } from "../hooks/useFetch";
import Filtros from "../components/catalogo/Filtros";
import Ordenar from "../components/catalogo/Ordenar";
import { Box, Grid, Paper, useTheme, useMediaQuery, Typography, AccordionSummary, Accordion, AccordionDetails} from "@mui/material";
import MetaComponent from "../components/shared/MetaComponent";
import Sticky from "react-stickynode";
import useQueryParams from "../hooks/useQueryParams";
import { ExpandMoreOutlined } from "@mui/icons-material";
import OmarContainer from "../components/shared/OmarContainer";
import TagManager from 'react-gtm-module'

const Catalogo = () => {
  const { query } = useQueryParams();

  const { idCatalogo, catalogo } = useParams();
  const [pageSize] = useState(20);

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const [filtros] = useState({
    precioMin: 0,
    precioMax: 9999,
  });

  const [pagina, setpagina] = useState( query.get("page") ? query.get("page") : 1);
  const [material, setmaterial] = useState( query.get("material") ? query.get("material") : "");
  const [empaque, setempaque] = useState(query.get("empaque") ? query.get("empaque") : "" );
  const [order, setOrder] = useState("");
  const [envioGratis, setEnvioGratis] = useState( query.get("envioGratis") ? query.get("envioGratis") : ""
  );
  //creamos url del servico de productos
  const urlConsulta = !idCatalogo
    ? `${urlServices.URL_SERVICE_TIENDA}api/v4/Productos?res=${pageSize}&pag=${
        pagina - 1
      }&empaque=${empaque}&material=${material}&PrecioMin=${
        filtros.precioMin
      }&precioMax=${filtros.precioMax}&${order}&envioGratis=${
        envioGratis.toUpperCase() === "YES"
      }`
    : `${
        urlServices.URL_SERVICE_TIENDA
      }api/v4/Productos/ProductosCatalogo?idCat=${idCatalogo}&result=${pageSize}&pag=${
        pagina - 1
      }&empaque=${empaque}&material=${material}&PrecioMin=${
        filtros.precioMin
      }&precioMax=${filtros.precioMax}&${order}&envioGratis=${
        envioGratis.toUpperCase() === "YES"
      }`;

  const { data, loading } = useFetch(urlConsulta);

  //Calculamos el total de paginas que nos manda la api
  const totalProductos = data
    ? data.total
      ? data.total
      : data.totalProductos
    : 0;
  const totalPaginas = data ? Math.ceil(totalProductos / pageSize) : 0;

  const cambiarOrdenProductos = (order) => {
    setOrder(order);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setpagina(query.get("page") ? Number(query.get("page")) : 1);
    setempaque(query.get("empaque") ? query.get("empaque") : "");
    setmaterial(query.get("material") ? query.get("material") : "");
    setEnvioGratis(query.get("envioGratis") ? query.get("envioGratis") : "");
  }, [query]);
  
  useEffect(() => {
    if(data)
       TagManager.dataLayer({
          dataLayer:{
            ecommerce: {products:data?.productos,category:catalogo?catalogo.replaceAll("-"," "):"todos",filters:{empaque,material,...filtros}},
            event:"view_item_list"
          } 
        })
  
  }, [data,catalogo,filtros,empaque,material])
  
  return (
    <>
      <Box mt={2}>
        <MetaComponent
          titulo={`Nuestros productos${catalogo ? `- ${catalogo}` : ``}`}
          description={`Nuestros productos${catalogo ? `- ${catalogo}` : ``}`}/>
        <OmarContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} lg={2}>
              <Box mt={5}>{matchesMd ? <FiltrosWeb /> : <FiltrosMobile />}</Box>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              {loading ? (
                <Box display="flex" justifyContent="center" mt={10}>
                  <Loader type="ThreeDots" color="#fe5000" height={150} width={150}/>
                </Box>
              ) : data ? (
                <>
                  {data.productos && data.productos.length !== 0 ? (
                    <>
                      <Box display="flex" flexDirection="row-reverse">
                        <Ordenar cambiarOrdenProductos={cambiarOrdenProductos}/>
                      </Box>
                      <Box>
                        <ListProducto key="list-producto" data={data.productos}/>
                      </Box>
                    </>
                  ) : (
                    <Box>
                      <ErrorInfo text="Lo sentimos no se encontraron productos relacionados con la búsqueda" />
                    </Box>
                  )}
                  {totalPaginas > 0 && (
                    <Paginador paginasTotales={totalPaginas} paginaActual={pagina}/>
                  )}
                </>
              ) : (
                <ErrorInfo text="No se pudieron cargar los productos" />
              )}
            </Grid>
          </Grid>
        </OmarContainer>
      </Box>
      <div id="bottom-catalogo"></div>
    </>
  );
};

const FiltrosWeb = () => {
  return (
    <Sticky top={200} bottomBoundary="#bottom-catalogo">
      <Paper elevation={5}>
        <Box p={3}>
          <Filtros />
        </Box>
      </Paper>
    </Sticky>
  );
};
const FiltrosMobile = () => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        aria-controls="acordeon-filters"
        id="acordeon-filters"
      >
        <Typography>Filtros</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Filtros />
      </AccordionDetails>
    </Accordion>
  );
};
export default Catalogo;
