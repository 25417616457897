import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { firebase } from "../../firebase/config";
import Direccion from "./Direccion";
import { Box, Button, Typography } from "@mui/material";
import { Add, SentimentDissatisfiedOutlined } from "@mui/icons-material";
import Loader from "react-loader-spinner";
import ModalDireccion from "../direcciones/ModalAddDireccion";
import { useSelector } from "react-redux";
import {
  getDireccionAction,
  selectDireccionAction,
} from "../../actions/direcciones";
import { useDispatch } from "react-redux";
import AvatarTitle from "../shared/AvatarTitle";
const ListDirecciones = () => {
  const [token, settoken] = useState(null);
  const dispatch = useDispatch();
  const { direcciones } = useSelector((state) => state);
  const { loading, data } = direcciones;
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    const auth = getAuth(firebase);
    const cancell = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user) {
          user.getIdToken(true).then((token) => {
            settoken(token);
          });
        }
      }
    });
    return () => {
      cancell();
    };
  }, []);

  useEffect(() => {
    dispatch(getDireccionAction(token));
  }, [token, dispatch]);

  const openModalDireccion = () => {
    setIsOpenModal(true);
  };
  const handleSelectDireccion = (direccion) => {
    dispatch(selectDireccionAction(direccion));
  };
  return (
    <>
      <ModalDireccion isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} token={token}/>
      <div>
        <AvatarTitle title="Direcciones" stepp="1" />
        <div my={5}>
          {loading ? (
            <Box my={10} display="flex" justifyContent="center">
              <Loader type="ThreeDots" color="#fe5000" height={90} width={90} />
            </Box>
          ) : data?.length > 0 ? (
            <>
              {data?.map((direccion) => (
                <Direccion
                  key={direccion.id}
                  direccion={direccion}
                  token={token}
                  handleSelectDireccion={handleSelectDireccion}
                />
              ))}
            </>
          ) : (
            <Box mt={10} mb={10}>
              <Typography variant="h6" align="center" color="primary">
                No tienes direcciones agregadas
              </Typography>
              <Box
                mt={2}
                display="flex"
                justifyContent="center"
                color="primary"
              >
                <SentimentDissatisfiedOutlined
                  color="primary"
                  style={{ fontSize: 50 }}
                />
              </Box>
            </Box>
          )}
        </div>
        <Box my={5} display="flex" flexDirection="row-reverse">
          <Button
            onClick={openModalDireccion}
            component="span"
            color="primary"
            variant="contained"
          >
            <Add /> Agregar dirección
          </Button>
        </Box>
      </div>
    </>
  );
};

export default ListDirecciones;
