import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";

import MetaComponent from "../components/shared/MetaComponent";
const NotFound = () => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <MetaComponent
        titulo="Pagina no encontrada"
        description="La pagina que buscas no se pudo encontrar"
      />
      <section content="not-found ">
        <Container>
          <Box py={20} mt={20}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Typography
                    variant="h1"
                    align="center"
                    fontWeight="bold"
                    lineHeight="2"
                  >
                    404!
                  </Typography>
                  <Typography
                    variant="h6"
                    align="center"
                    fontWeight="bold"
                  >
                    Lo sentimos, pero la página que buscaba no existe.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sx={{ marginTop: matchesMd ? 10 : 0 }}>
                <Box display="flex" justifyContent="center">
                  <Link to="/">
                    <img
                      alt="Industrias Pegaduro"
                      src="https://www.pegaduro.com.mx/img/LogoPegaduro.png"
                    />
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default NotFound;
