import {useState,useEffect} from 'react'
import { setUtmsLocalStorage } from '../helper/utmLocalStorage';
import useQueryParams from '../hooks/useQueryParams'

export const UtmParams = () => {
  const { query } = useQueryParams();

  const [utmParams] = useState({
    utmSource:query.get("utm_source"),
    utmMedium:query.get("utm_medium"),
    utmCampaign:query.get("utm_campaign"),
    utmId:query.get("utm_id"),
    utmTerm:query.get("utm_term"),
    utmContent:query.get("utm_content")
  })

  useEffect(() => {
    let isParamsInUrl = false
    Object.keys(utmParams).forEach(e=> { 
      if(utmParams[e]){
        isParamsInUrl = true;
      }
    })
    if(isParamsInUrl){
      setUtmsLocalStorage(utmParams)
    }

  }, [utmParams])
  return (
    <>
    </>
  )
}
