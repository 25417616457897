import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import { Link, useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Logo from "../../assets/img/LogoPegaduro.png";
import { Box, CardActionArea, CardContent, Tooltip } from "@mui/material";
import currencyFormatter from "currency-formatter";
import { green, grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { agregarCarritoAction } from "../../actions/carrito";
import { FormatUnderlinedOutlined } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  price: {
    color: green[400],
    fontWeight: "bold",
  },
  link: {
    color: "inherit",
    textDecoration: "inherit",
  },
  lastPrice :{
    color: grey[400],
    textDecoration: "line-through"
  }
}));

const ProductoRelacionadoItem = ({
  producto,
  elevation = 0,
  isAddProducto = false,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const imagenes = producto.multimedia.filter((mul) => mul.tipo === "Imagen");
  const dispatch = useDispatch();
  const handleclickAgregarProducto = () =>
    dispatch(agregarCarritoAction(producto, 1));

  const handleClickViewProducto = () =>
    history.push(`/info-producto-${producto.itemCode.toLowerCase()}`);

  return (
    <Box m={1}>
      <Card elevation={elevation} sx={{ borderRadius: 2 }}>
        <Link
          to={`/info-producto-${producto.itemCode.toLowerCase()}`}
          className={classes.link}
        >
          <CardActionArea >
            <CardContent>
              <Box display="flex" justifyContent="center" width="100%">
                <img component="img" alt={producto?.nombre} height="240" src={imagenes[0] ? imagenes[0].urlMultimedia : Logo}/>
              </Box>
              <Typography gutterBottom variant="subtitle2" component="div" align="center" >
                <Tooltip title={producto.nombre.trim()}>
                  {producto.nombre.length > 24 ? (
                    <Typography align="center" variant="subtitle2" component="div">
                      {`${producto.nombre.trim().slice(0, 15)}...`}
                    </Typography>
                  ) : (
                    <Typography align="center" variant="subtitle2" component="div">
                      {producto.nombre}{" "}
                    </Typography>
                  )}
                </Tooltip>
              </Typography>

 
              {
                producto.descuento > 0 ?(
                  <>
                  <Typography variant="body2" align="center" className={classes.price}>
                      {currencyFormatter.format(
                    (producto.precio + producto.precio * producto.iva) ,
                    {
                      code: "MXN",
                    }
                  )}
                  {producto.moneda}
                </Typography>

                    <Typography variant="body2" align="center" className={classes.lastPrice}>
                      {currencyFormatter.format(
                    producto.precioSinDescuento + producto.precioSinDescuento * producto.iva ,
                    {
                      code: "MXN",
                    }
                  )}
                  {producto.moneda}
                </Typography>

                  </>
                ) :(
                <>
                <Typography variant="body2" align="center" className={classes.price}>
                      {currencyFormatter.format(
                    (producto.precio + producto.precio * producto.iva) ,
                    {
                      code: "MXN",
                    }
                  )}
                  {producto.moneda}
                </Typography>
                <Typography variant="body2" align="center" className={classes.price}>
                  <br/>
                </Typography>
                </>)

              }
              
            </CardContent>
          </CardActionArea>
        </Link>
        <CardActions>
          <Button fullWidth size="small" onClick={(e) => {
              if (isAddProducto) {
                handleclickAgregarProducto();
              } else {
                handleClickViewProducto();
              }
            }}>
            {isAddProducto ? <>Agregar al carrito</> : "Ver Producto"}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default ProductoRelacionadoItem;
