import React from "react";
import short from "short-uuid";
import { Box } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { Link } from "react-router-dom";
const CaroucelImagenes = ({ multimedia, breakeMd, producto }) => {
  return (
    <Carousel
      autoPlay={false}
      animation="slide"
      timeout={0.4 * 1000}
      navButtonsAlwaysInvisible={true}
    >
      {multimedia?.map((imagen) => {
        return (
          <Box
          key={short.generate()}
          >
            <Link
              to={`/info-producto-${producto.itemCode.toLowerCase()}`}
              style={{ textDecoration: "inherit", color: "inherit" }}
             
            >
              <Box display="flex" justifyContent="center" alignContent="center">
                <img
                  style={{
                    opacity: producto.stock <= 0 ? "0.2" : "1",
                    maxWidth: "100%",
                  }}
                  height={breakeMd ? "230" : "170"}
                  src={`/${imagen.urlMultimedia}`}
                  alt={producto.nombre}
                />
              </Box>
            </Link>
          </Box>
        );
      })}
    </Carousel>
  );
};

export default CaroucelImagenes;
