import React, { useState } from "react";
import Card from "@mui/material/Card";
import { useDispatch } from "react-redux";
import currencyFormatter from "currency-formatter";
import { Link, useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { agregarCarritoAction } from "../../actions/carrito";

import {
  Button,
  CardActions,
  Box,
  Tooltip,
  Grid,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Add,
  LocalOfferOutlined,
  LocalShippingOutlined,
  SentimentDissatisfiedOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import ReactPixel from "react-facebook-pixel";
import { makeStyles } from "@mui/styles";
import { green, grey, red } from "@mui/material/colors";
import Logo from "../../assets/img/LogoPegaduro.png";
import CaroucelImagenes from "./CaroucelImagenes";
const useStyles = makeStyles((theme) => ({
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
  containerImg: {
    textAlign: "center",
  },
  price: {
    color: green[400],
    fontWeight: "bold",
  },
  noStock: {
    color: red[400],
    fontWeight: "bold",
  },
  lastPrice :{
    color: grey[400],
    textDecoration: "line-through",
  },
  cardStyles: {
    position: "relative",
    "& .hidden-button": {
      display: "none",
    },
    "&:hover .hidden-button": {
      display: "flex",
    },
  },
}));
const ProductoTest = ({ producto }) => {
  const classes = useStyles();
  const theme = useTheme();
  const breakeMd = useMediaQuery(theme.breakpoints.up("md"));
  const imagenes = producto.multimedia
    ? producto.multimedia.filter((mul) => mul.tipo === "Imagen")
    : [];

  const history = useHistory();

  const dispatch = useDispatch();
  const handleAdd = () => {
    if (!producto.hijos) {
      ReactPixel.track("AddToCart", { producto: producto.nombre, cantidad: 1 });
      setopen(true);

      if(producto.cantidadPaquete === 0 )
      {
        producto.cantidadPaquete = 1;
      }

      dispatch(agregarCarritoAction(producto, producto.cantidadPaquete));
    } else {
      history.push(`/info-producto-${producto.itemCode.toLowerCase()}`);
    }
  };

  const [open, setopen] = useState(false);
  const handleClose = () => {
    setopen(false);
  };

  const handleVerProducto = () => {
    history.push(`/info-producto-${producto.itemCode.toLowerCase()}`);
  };
  const cantidadPaquete = producto.cantidadPaquete?producto.cantidadPaquete:1
  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" variant="filled">
          Se agregó el producto al carrito
        </Alert>
      </Snackbar>
      <Card elevation={12}>
        <Grid container p={1}>
          <Grid item xs={4} md={12}>
            {imagenes?.length > 0 ? (
              <CaroucelImagenes
                multimedia={imagenes}
                breakeMd={breakeMd}
                producto={producto}
              />
            ) : (
              <Box display="flex" justifyContent="center">
                <img
                  style={{
                    opacity: producto.stock <= 0 ? "0.2" : "1",
                    maxWidth: "100%",
                  }}
                  height={breakeMd ? "230" : "170"}
                  src={Logo}
                  alt={producto.nombre}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={8} md={12}>
            <Box p={1}>
              <Link
                to={`/info-producto-${producto.itemCode.toLowerCase()}`}
                style={{ textDecoration: "inherit", color: "inherit" }}
              >
                <Tooltip title={producto.nombre.trim()}>
                  {producto.nombre.length > 24 ? (
                    <Typography
                      align={breakeMd ? "center" : "left"}
                      variant="subtitle2"
                      component="div"
                    >
                      {`${producto.nombre.trim().slice(0, 25)}...`}
                    </Typography>
                  ) : (
                    <Typography
                      align={breakeMd ? "center" : "left"}
                      variant="subtitle2"
                      component="div"
                    >
                      {producto.nombre}{" "}
                    </Typography>
                  )}
                </Tooltip>
              </Link>
              <Box my={1}>
                {producto.stock <= 0 ? (
                  <Typography
                    color="primary"
                    variant="caption"
                    className={classes.wrapIcon}
                  >
                    <SentimentDissatisfiedOutlined
                      color="primary"
                      style={{ marginRight: 2 }}
                    />{" "}
                    Producto no disponible
                  </Typography>
                ) : (
                  producto.costoEnvios && (
                    <Typography
                      color="primary"
                      variant="caption"
                      className={classes.wrapIcon}
                    >
                      {producto.costoEnvios <= 1 ? (
                        <>
                          <LocalShippingOutlined
                            color="primary"
                            style={{ marginRight: 2 }}
                          />{" "}
                          Envío sin costo!
                        </>
                      ) : (
                        <>
                          <LocalOfferOutlined
                            color="primary"
                            style={{ marginRight: 2 }}
                          />{" "}
                          Envío gratis a partir de {producto.costoEnvios}
                        </>
                      )}
                    </Typography>
                  )
                )}
              </Box>

              <Box sx={{ height: 50 }}>
                <Typography variant="caption" color="text.secondary">
                  {producto.descCorta
                    ? producto.descCorta
                    : `Descripción no disponible`}
                </Typography>
              </Box>
              <Box my={1}>

                  
                {
                  producto.descuento > 0 ?(
                  <>
                      <Typography
                      fontSize={16}
                      className={classes.price}
                      align="left"
                    >
                      {currencyFormatter.format(
                        ((producto.precio + producto.iva * producto.precio)) * cantidadPaquete,
                        { code: "MXN"}
                      )}
                      {producto.moneda}
                    </Typography>
                      
                    <Typography
                      fontSize={16}
                      className={classes.lastPrice}
                      align="left"
                    >
                      {currencyFormatter.format(
                        (producto.precioSinDescuento + producto.iva * producto.precioSinDescuento) * cantidadPaquete,
                        { code: "MXN"}
                      )}
                      {producto.moneda}
                    </Typography>
                  </>
                  ):(
                    <>
                <Typography
                  fontSize={16}
                  className={classes.price}
                  align="left"
                >
                  {currencyFormatter.format(
                    (producto.precio + producto.iva * producto.precio) * cantidadPaquete,
                    { code: "MXN"}
                  )}
                  {producto.moneda}
                </Typography>

                  <Typography> <br/></Typography>
</>
                  )
                }

              </Box>
            </Box>
          </Grid>
        </Grid>
        <CardActions>
          <Button
            variant="contained"
            startIcon={
              producto.stock && !producto.hijos ? (
                <Add />
              ) : (
                <VisibilityOutlined />
              )
            }
            fullWidth
            size="small"
            onClick={
              producto.stock > 0 && !producto.hijos
                ? handleAdd
                : handleVerProducto
            }
          >
            {producto.stock && !producto.hijos
              ? "Agregar"
              : !producto.hijos
              ? "Ver"
              : "Ver presentaciones"}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default ProductoTest;
