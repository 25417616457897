import { getLocalDisonibilidad } from "../helper/disponibilidadLocalStorage";
import { disponibilidadTypes } from "../types/types";


export const disponibilidadReducer = (state  = getLocalDisonibilidad(),action)=>{
    switch (action.type) {
        case disponibilidadTypes.edit_disponibilidad:
          return action.payload;
        default:
          return state;
      }
}