import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Badge,
  Box,
  Grid,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
} from "@mui/material";
import TabsCategorias from "./TabsCategorias";
import Buscador from "./Buscador";
import { AccountCircle, Menu, ShoppingCartOutlined } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import MenuLoged from "./MenuLoged";
import PopUpCorreo from "./PopUpCorreo";
import DrawerMenu from "./DrawerMenu";
import Logo from "../../assets/img/LogoPegaduro.png";
const useStyles = makeStyles({
  headerLegend: {
    backgroundColor: "#fE5000",
    padding: 1,
  },
  headerLegendTitle: {
    color: "white",
  },
  logo: {
    widht: 80,
    height: 100,
  },
  tabsContainer: {},
});

const TopBar = () => {
  const theme = useTheme();
  const breakePointMd = useMediaQuery(theme.breakpoints.up("md"));
  const breakePointLg = useMediaQuery(theme.breakpoints.up("lg"));

  const classes = useStyles();
  const { carrito, auth, unregistredUser } = useSelector((state) => state);
  const [open, setopen] = useState(false);

  const history = useHistory();

  const location = useLocation();

  const productos = carrito.filter(p=>!p.isPaquete)
  const paquetesHdrsIds = [] 
  carrito.filter(p=>p.isPaquete).forEach((p)=>{ 
    if(!paquetesHdrsIds.includes(p.idProductoHdr)){
        paquetesHdrsIds.push(p.idProductoHdr)}})
  const totalProductos = productos.length + paquetesHdrsIds.length
  const handleClose = () => {
    setopen(false);
  };

  const handleOpenCarrito = () => {
    if (auth?.token) {
      return history.push("/carrito");
    }
    if (unregistredUser) {
      if (unregistredUser?.email) {
        return history.push("/carrito");
      }
    }
    return setopen(true);
  };

  const [openDrawer, setopenDrawer] = useState(false);
  const handleCloseDrawer = () => {
    setopenDrawer(false);
  };
  const handleOpenDrawer = () => {
    setopenDrawer(true);
  };
  return (
    <>
      <PopUpCorreo
        open={open}
        handleClose={handleClose}/>
      <DrawerMenu
        open={openDrawer}
        handleClose={handleCloseDrawer}
        handleOpenDrawer={handleOpenDrawer}
      />
      <header id="header-sticky">
        <Paper elevation={1}>
          <Box>
            {/* Legend parte superior */}
            {location.pathname === "/" && (
              <Link
                to="/productos?envioGratis=yes"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                <Box
                  className={`${classes.headerLegend}`}
                  display="flex"
                  justifyContent="center"
                >
                  <Typography
                    align="center"
                    variant="subtitle1"
                    className={`${classes.headerLegendTitle} animate__animated`}
                  >
                    CONSULTA NUESTROS PRODUCTOS SIN COSTO DE ENVÍO
                    <img
                      style={{ marginLeft: 10 }}
                      alt="envio_gratis"
                      src="/img/iconos/icono_carrito.gif"
                    />
                  </Typography>{" "}
                </Box>
              </Link>
            )}
            {/* Body de la navegacion */}
            <Grid container>
              <Grid item xs={4} sm={2} md={1}>
                {isMobile ? (
                  <Box display="flex" flexDirection="row">
                    <Box>
                      <IconButton
                        color="primary"
                        onClick={() => setopenDrawer(true)}
                      >
                        <Menu style={{ fontSize: 30 }} />
                      </IconButton>
                    </Box>{" "}
                    <Box textAlign="center" py={2}>
                      <Link to="/">
                        <img
                          src={Logo}
                          alt="Industrias Pegaduro"
                          className={classes.logo}
                        />
                      </Link>
                    </Box>
                  </Box>
                ) : (
                  <Box textAlign="center" py={2}>
                    <Link to="/">
                      <img
                        src={Logo}
                        alt="Industrias Pegaduro"
                        className={classes.logo}
                      />
                    </Link>
                  </Box>
                )}
              </Grid>
              <Grid item xs={8} sm={10} md={11}>
                <Box my={2} pr={2}>
                  <Grid container>
                    <Grid item xs={12} md={10} order={{ xs: 2, md: 1 }}>
                      <Box>
                        <Buscador />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={2} order={{ xs: 1, md: 2 }}>
                      <Grid
                        container
                        justifyContent={breakePointMd ? "start" : "end"}
                        alignItems="center"
                      >
                        <Box ml={2}>
                          <IconButton
                            // component={Link}
                            // to="/carrito"
                            onClick={handleOpenCarrito}
                            style={{
                              color: "inherit",
                              textDecoration: "inherit",
                            }}
                          >
                            <Badge
                              badgeContent={totalProductos}
                              color="primary"
                            >
                              <ShoppingCartOutlined />
                            </Badge>
                          </IconButton>
                        </Box>
                        <Box ml={2}>
                          {auth.token ? (
                            <>
                              <MenuLoged />

                              <Typography variant="caption">
                                {!breakePointMd
                                  ? ""
                                  : breakePointLg &&
                                    `${auth.nombre.slice(0, 10)}...`}
                              </Typography>
                            </>
                          ) : (
                            <IconButton component={Link} to="/auth/login">
                              <AccountCircle />{" "}
                              <Typography
                                variatn="caption"
                                style={{ marginLeft: 4 }}
                              >
                                Ingresar
                              </Typography>
                            </IconButton>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                {!isMobile && (
                  <Box>
                    <TabsCategorias />
                  </Box>
                )}
              </Grid>
            </Grid>
            <Box></Box>
          </Box>
        </Paper>
      </header>
    </>
  );
};

export default TopBar;
