import { getPedidoLocalStorage } from "../helper/pedidoLocalStorage";
import { pedidoTypes } from "../types/types";

const initialState = () => {
  const pedido = getPedidoLocalStorage();
  return {
    ...pedido,
    validForm: false,
  };
};
export const pedidoReducer = (state = initialState(), action) => {
  switch (action.type) {
    case pedidoTypes.editar_info_pedido:
      return action.payload;
    case pedidoTypes.editar_detalle:
      return action.payload;
    case pedidoTypes.editar_validacion_pedido:
      return action.payload;
    case pedidoTypes.edit_costo_envio:
      return action.payload;
    default:
      return state;
  }
};
