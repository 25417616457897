import {
  deleteConfigAuth,
  getConfigAuth,
  postConfigAuth,
  putConfigAuth,
} from "../helper/postConfig";
import { urlServices } from "../helper/urlsBase";
import { direccionesTypes } from "../types/types";
import { store } from "../store/store";
export const getDireccionAction = (token) => {
  return async (dispatch) => {
    dispatch({
      type: direccionesTypes.load_direcciones,
      payload: {
        data: null,
        loading: true,
        error: "",
      },
    });
    if (token) {
      const response = await fetch(
        `${urlServices.URL_SERVICE_TIENDA}api/v1/Direcciones`,
        getConfigAuth(token)
      );

      if (response.ok) {
        const data = await response.json();
        dispatch({
          type: direccionesTypes.get_direcciones,
          payload: {
            data: data,
            loading: false,
            error: "",
          },
        });
      }
    }
  };
};
export const addDireccionAction = (token, direccion) => {
  return async (dispatch) => {
    dispatch({
      type: direccionesTypes.load_direcciones,
      payload: {
        data: null,
        loading: true,
        error: "",
      },
    });
    const response = await fetch(
      `${urlServices.URL_SERVICE_TIENDA}api/v1/Direcciones`,
      postConfigAuth(direccion, token)
    );
    if (response.ok) {
      const data = await response.json();
      dispatch({
        type: direccionesTypes.add_direcciones,
        payload: {
          data: data,
          loading: false,
          error: "",
        },
      });
    } else {
      const error = await response.text();
      console.log(error);
    }
  };
};
export const deleteDireccionAction = (id, token) => {
  return async (dispatch) => {
    dispatch({
      type: direccionesTypes.load_direcciones,
      payload: {
        data: null,
        loading: true,
        error: "",
      },
    });
    const response = await fetch(
      `${urlServices.URL_SERVICE_TIENDA}api/v1/Direcciones/Eliminar?idDireccion=${id}`,
      deleteConfigAuth(token)
    );

    if (response.ok) {
      const data = await response.json();
      dispatch({
        type: direccionesTypes.delete_direcciones,
        payload: {
          data: data,
          loading: false,
          error: "",
        },
      });
    }
  };
};
export const updateDireccionAction = (token, direccion, id) => {
  return async (dispatch) => {
    dispatch({
      type: direccionesTypes.load_direcciones,
      payload: {
        data: null,
        loading: true,
        error: "",
      },
    });
    const response = await fetch(
      `${urlServices.URL_SERVICE_TIENDA}api/v1/Direcciones?idDireccion=${id}`,
      putConfigAuth(token, direccion)
    );

    if (response.ok) {
      const data = await response.json();

      dispatch({
        type: direccionesTypes.update_direcciones,
        payload: {
          data: data,
          loading: false,
          error: "",
        },
      });
    } else {
      const error = await response.text();
      console.log(error);
    }
  };
};
export const selectDireccionAction = (selectedDireccion) => {
  return (dispatch) => {
    const newDirecciones = store
      .getState()
      .direcciones.data.map((direccion) => {
        if (direccion.id === selectedDireccion.id) {
          direccion.selected = true;
        } else {
          direccion.selected = false;
        }

        return direccion;
      });
    dispatch({
      type: direccionesTypes.select_direccion,
      payload: {
        data: newDirecciones,
        loading: false,
        error: "",
      },
    });
  };
};
