import ProductoCheckOut,{PaqueteCheckOut} from "../checkout/ProductoCheckOut";
import {  Typography, Box, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import PrecioCard from "../checkout/PrecioCard";
const DatosPedido = ({
  carrito,
}) => {
  const { costoEnvio: envioPedido } = useSelector((state) => state.pedido);
  const subTotal = carrito
    .map((item) =>  (item.iva * item.precio + item.precio) * item.cantidad)
    .reduce((a, b) => a + b, 0);
  const productos = carrito.filter(p=>!p.isPaquete)
  const paquetesHdrsIds = [] 
  carrito.filter(p=>p.isPaquete).forEach((p)=>{ 
      if(!paquetesHdrsIds.includes(p.idProductoHdr)){
          paquetesHdrsIds.push(p.idProductoHdr)}})
  return (
    <>

        <Typography variant="h6" fontWeight="bold" align="center">
          Tu orden
        </Typography>
        <Box mt={2}>
          <Typography variant="subtitle2" fontWeight="bold">
            Productos
          </Typography>
        </Box>
        <Box>
          {productos.map((producto, i) => (
            <ProductoCheckOut key={producto.itemCode} producto={producto} />
          ))}
          {paquetesHdrsIds.map(hdrs=> 
            {
              const productosPaquete = carrito.filter(p=>p.idProductoHdr === hdrs)
              console.log(productosPaquete)
              const precio =productosPaquete.map(producto=>{
                return(producto.descuento > 0 ? ((producto.precio * producto.iva + producto.precio)- (producto.precio * producto.descuento)) * producto.cantidad :(producto.precio * producto.iva + producto.precio) *producto.cantidad)
              }).reduce((a,b)=>a+b,0)
              return <PaqueteCheckOut nombre={productosPaquete[0]?.productoHdr?.nombre} 
              cantidad={(productosPaquete.map(p=>p.cantidad).reduce((a,b)=>a+b,0))} 
              precio={precio}/>
            })}
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        <PrecioCard price={envioPedido} title={"Costo de envío"} />
        <PrecioCard price={subTotal} title={"Subtotal"} />
        <PrecioCard
          price={envioPedido + subTotal}
          title={"Total"}
          large
        />
        <Box my={2}>
          <Divider />
        </Box>
        <Typography variant="caption">
          El monto total que se muestra es el precio final con IVA y costos de
          envió incluido.
        </Typography>
    </>
  );
};

export default DatosPedido;
