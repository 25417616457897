import React from "react";
import { Box, Paper, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import currencyFormatter from "currency-formatter";
import "moment/locale/es-mx";
import ListProductos from "./ListProductos";
import Logo from "../../assets/img/LogoPegaduro.png";
import { green } from "@mui/material/colors";
const useStyle = makeStyles((theme) => ({
  price: {
    color: green[500],
    fontWeight: "bold",
  },
}));
const Pedido = ({ pedido }) => {
  moment.locale("es-mx");
  const classes = useStyle();
  return (
    <Box my={2}>
      <Paper elevation={3}>
        <Box p={3}>
          <Grid container>
            <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
              <Box my={2}>
                <Box>
                  <Typography variant="h6" fontWeight="bold">
                    Pedido a nombre de:
                    <Typography variant="h6">{pedido.nombreCliente}</Typography>
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Numero de pedido:{" "}
                    <Typography variant="body1">{pedido.id}</Typography>
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2" fontWeight="600">
                    Fecha de su orden:{" "}
                    <Typography variant="body2" fontWeight="300">
                      {moment(pedido?.fecha).format("LL")}
                    </Typography>
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2" fontWeight="600">
                    Correo electrónico:{" "}
                    <Typography variant="body2" fontWeight="300">
                      {pedido.correo}
                    </Typography>
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2" fontWeight="600">
                    Dirección de envió:{" "}
                    <Typography variant="body2" fontWeight="300">
                      {` ${pedido?.direccion?.calle} #${pedido?.direccion?.numero} ${pedido?.direccion?.colonia} ${pedido?.direccion?.colonia} , ${pedido?.direccion?.estado}`}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
              <Box
                display="flex"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                my={2}
              >
                <img src={Logo} alt="Logo" style={{ width: "50%" }} />
              </Box>
            </Grid>
          </Grid>
          <Box mt={4}>
            <ListProductos productos={pedido.productos} />
          </Box>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Box display="flex" my={2}>
              <Box>
                <Typography variant="subtitle2" fontWeight="bold">
                  Costo Envío
                </Typography>
                <Typography variant="subtitle2" className={classes.price}>
                  {currencyFormatter.format(pedido?.costoEnvio, {
                    code: "USD",
                  })}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" my={2}>
              <Box>
                <Typography variant="subtitle2" fontWeight="bold">
                  Total + Envío
                </Typography>
                <Typography
                  variant="subtitle1"
                  align="right"
                  className={classes.price}
                >
                  {currencyFormatter.format(pedido.total + pedido?.costoEnvio, {
                    code: "USD",
                  })}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Pedido;
