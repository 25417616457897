import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Avatar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/LogoPegaduro.png";
import { useFetch } from "../../hooks/useFetch";
import { urlServices } from "../../helper/urlsBase";
import { HomeOutlined } from "@mui/icons-material";
const useStyles = makeStyles({
  logo: {
    widht: 80,
    height: 100,
  },
});

const DrawerMenu = ({ open, handleClose, handleOpenDrawer }) => {
  const url = `${urlServices.URL_SERVICE_TIENDA}api/v2/Productos/Catalogos`;
  const { data: catalogos } = useFetch(url);
  const [categorias, setcategorias] = useState([]);
  const classes = useStyles();
  useEffect(() => {
    if (catalogos) {
      const sortedCategorias = catalogos.sort((a, b) => {
        if (a.orden < b.orden) {
          return -1;
        }
        if (a.orden > b.orden) {
          return 1;
        }
        return 0;
      });
      sortedCategorias.unshift({
        id: 0,
        nombre: "Todos",
        descripcion: null,
        orden: 0,
        iconoUrl: "",
        navegacion: true,
      });
      setcategorias(sortedCategorias);
    }
  }, [catalogos]);
  return (
    <div>
      <>
        <SwipeableDrawer
          onOpen={handleOpenDrawer}
          anchor="left"
          open={open}
          onClose={handleClose}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={handleClose}
            onKeyDown={handleClose}
          >
            <Box textAlign="center" py={2}>
              <Link to="/">
                <img
                  src={Logo}
                  alt="Industrias Pegaduro"
                  className={classes.logo}
                />
              </Link>
            </Box>
            <Box p={2}>
              <Typography variant="subtitle2" align="center" fontWeight="bold">
                Categorias
              </Typography>
            </Box>
            <Divider />
            <List>
              {categorias?.map((item) => (
                <ListItem
                  button
                  key={item.id}
                  component={Link}
                  to={
                    item.id === 0
                      ? `/productos`
                      : `/productos-${item.nombre.replaceAll(" ", "-")}/${
                          item.id
                        }`
                  }
                >
                  <ListItemIcon>
                    {item.id === 0 ? (
                      <HomeOutlined style={{ fontSize: 40 }} />
                    ) : (
                      <Avatar src={item.iconoUrl} alt={item.nombre} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={item.nombre} />
                </ListItem>
              ))}
            </List>
            {/* <Divider />
            <List>
              {["All mail", "Trash", "Spam"].map((text, index) => (
                <ListItem button key={text}>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List> */}
          </Box>
        </SwipeableDrawer>
      </>
    </div>
  );
};
export default DrawerMenu;
