import { postConfigAuth } from "../helper/postConfig";
import { urlServices } from "../helper/urlsBase";

export const postComentario = async (comentario, token) => {
  try {
    const url = `${urlServices.URL_SERVICE_TIENDA}api/v2/Productos/Comentario`;
    const response = await fetch(url, postConfigAuth(comentario, token));
    if (response.ok) {
      return true;
    }
  } catch (error) {
    return false;
  }
  return false;
};

export const getComentarios = async (idProductoHdr) => {
  try {
    const response = await fetch(
      `${urlServices.URL_SERVICE_TIENDA}api/v2/Productos/Comentario/${idProductoHdr}`
    );
    if (response.ok) {
      return await response.json();
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
