import { Box, Avatar, Typography } from "@mui/material";
import React from "react";

const AvatarTitle = ({ title, stepp }) => {
  return (
    <Box display="flex" alignItems="center" justifiyContent="center" mb={4}>
      <Avatar
        sx={{
          backgroundColor: "#FE5000",
          marginRight: 1,
          width: 30,
          height: 30,
        }}
      >
        {stepp}
      </Avatar>
      <Typography variant="subtitle1" fontWeight="bold" align="left">
        {title}
      </Typography>
    </Box>
  );
};

export default AvatarTitle;
