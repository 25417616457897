import React, { useEffect, useRef, useState, useCallback } from "react";

import { Fab, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog, TextField, Button,} from "@mui/material";
import {
  CheckCircleOutline,
  LocalShippingRounded,
  SentimentVeryDissatisfiedOutlined,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import Loader from "react-loader-spinner";
import useForm from "../../hooks/useForm";
import { urlServices } from "../../helper/urlsBase";
import { postConfig } from "../../helper/postConfig";
import { useDispatch } from "react-redux";
import { changeDisponibilidad } from "../../actions/disponibilidad";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: 0,
    top: "auto",
    left: "auto",
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));
const initialDisponibilidad = {
  data: null,
  error: null,
  loading: null,
};
const DialogDisponibilidad = () => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const { form, handleChange } = useForm({
    codigoPostal: "",
  });
  const { codigoPostal } = form;
  const [disponibilidad, setdisponibilidad] = useState(initialDisponibilidad);
  //Revisas el codigo postal con la api de disponibilidad
  useEffect(() => {
    const regex = /^[0-9]*$/;
    if (codigoPostal.length === 5 && regex.test(codigoPostal)) {
      setdisponibilidad({
        data: null,
        error: null,
        loading: true,
        mensaje: "",
      });
      fetch(
        `${urlServices.URL_SERVICE_TIENDA}api/v1/Envios/ValidaCP`,
        postConfig({
          cp: codigoPostal,
        })
      )
        .then((data) => {
          if (isMounted.current) {
            if (data.status === 200) {
              setdisponibilidad({
                data: data,
                error: null,
                loading: false,
                mensaje:
                  "Tu zona se encuentra disponible comienza a agregar productos al carrito!",
              });
              dispatch(
                changeDisponibilidad({
                  codigoPostal: codigoPostal,
                  disponibilidad: true,
                })
              );
            } else {
              setdisponibilidad({
                data: data,
                error: "Lo sentimos tu zona no se encuentra disponible",
                loading: false,
              });
              dispatch(
                changeDisponibilidad({
                  codigoPostal: codigoPostal,
                  disponibilidad: false,
                })
              );
            }
          }
        })
        .catch((e) => {
          if (isMounted.current) {
            setdisponibilidad({
              data: null,
              error: "Lo sentimos tu zona no se encuentra disponible",
              loading: false,
            });
          }
        });
    } else {
      setdisponibilidad(initialDisponibilidad);
    }
  }, [codigoPostal, dispatch]);
  const [y, setY] = useState(window.scrollY);

  const [scrollDirection, setScrollDirection] = useState(true);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        setScrollDirection(true);
      } else if (y < window.scrollY) {
        setScrollDirection(false);
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);
  return (
    <div>
      {scrollDirection && (
        <div
          className={`${classes.margin} animate__animated  animate__fadeInUp animate__faster`}
        >
          <Fab
            variant="extended"
            size="small"
            component="span"
            color="primary"
            onClick={handleClickOpen}
          >
            <LocalShippingRounded className={classes.extendedIcon} />
            Revisa disponibilidad en tu zona
          </Fab>
        </div>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">Disponibilidad</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para revisar la disponibilidad en su zona por favor ingrese su
            codigo postal
          </DialogContentText>
          <TextField
            autoFocus
            color="primary"
            autoComplete="off"
            margin="dense"
            onChange={handleChange}
            value={codigoPostal}
            name="codigoPostal"
            label="Codigo postal"
            variant="standard"
            type="text"
            fullWidth
          />
          {disponibilidad.loading && (
            <div className="d-flex justify-content-center">
              <Loader type="ThreeDots" color="#fe5000" />
            </div>
          )}
          {disponibilidad.error && (
            <div className="mt-3">
              <div style={{ color: "red" }}>
                <div>
                  <SentimentVeryDissatisfiedOutlined
                    style={{ width: 30, height: 30 }}
                  />
                  Lo sentimos tu zona no se encuentra disponible
                </div>
              </div>
            </div>
          )}
          {disponibilidad.mensaje && (
            <div className="mt-3">
              <div style={{ color: "green" }}>
                <div>
                  <CheckCircleOutline style={{ width: 30, height: 30 }} />{" "}
                  <span>{disponibilidad.mensaje}</span>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogDisponibilidad;
