import { Box, Card, Typography } from "@mui/material";
import React from "react";
import Logo from "../../assets/img/LogoPegaduro.png";
import { settingsPresentaciones } from "../../helper/configSliders";
import Slider from "react-slick";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  item: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
const Presentaciones = ({ productos, handleChangeProducto }) => {
  const classes = useStyles();
  return (
    <Box className="presentaciones">
      <Slider centerMode={false} {...settingsPresentaciones(productos)}>
        {productos?.map((producto) => {
          const imagenes = producto.multimedia.filter(
            (mul) => mul.tipo === "Imagen"
          );
          return (
            <Box key={producto.itemCode} className={classes.item} >
              <Card
                key={producto.itemCode}
                elevation={1}
                onClick={() => handleChangeProducto(producto)}
              >
                <Box p={2}>
                  <img
                    src={imagenes[0] ? `./${imagenes[0].urlMultimedia}` : Logo}
                    alt={producto.nombre}
                    style={{ maxWidth: "100%" }}
                  />
                </Box>
                <Typography sx={{pading:2}} align="center" fontWeight="bold" variant="caption"> {producto.etiqueta}</Typography>
              </Card>
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
};

export default Presentaciones;
