import { uiTypes } from "../types/types";

const initialState = {
  error: { error: false, mensaje: null },
};
export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case uiTypes.show_error:
      return action.payload;
    case uiTypes.dismiss_error:
      return action.payload;
    default:
      return state;
  }
};
