import React from "react";
import { Helmet } from "react-helmet";
const MetaComponent = ({ titulo, description }) => {
  return (
    <Helmet>
      <meta name="description" content={description} />
      <title>{`Pegaduro ${titulo ? ` - ${titulo}` : ""}`}</title>
    </Helmet>
  );
};

export default MetaComponent;
