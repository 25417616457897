import { httpClient } from "./axiosconfig"

export const getRemoteGirosEmpresa = async()=> {
    const response = await httpClient.get("/api/v1/Notificaciones/giros")
    if(response.status===200){
        return response.data
    }
    throw new Error( "Error al obtener los giros");
}

export const suscribir = async(user) => {
    console.log(user)
    const response = await httpClient.post("/api/v1/Notificaciones/Registrar",user)
    if(response.status===200){
        return response.data
    }
    throw new Error( "Error al obtener los giros");
}