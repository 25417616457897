import { useRef, useState } from "react";
import {  Divider, Paper, Typography, Box,Switch } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import ProductoCheckOut, { PaqueteCheckOut } from "./ProductoCheckOut";
import { useEffect } from "react";
import useEnvio from "../../hooks/useEnvio";
import { useSelector, useDispatch } from "react-redux";
import { postConfig } from "../../helper/postConfig";
import { urlServices } from "../../helper/urlsBase";
import {editCarritoPedidoAction,editCostoEnvioAction,} from "../../actions/pedido";
import Loader from "react-loader-spinner";
import PrecioCard from "./PrecioCard";

const CheckOutCard = ({ confirmarPago, setError,validando,facturacion,changeFacturacion }) => {
  const { carrito } = useSelector((state) => state.pedido);
  const { envio, errorEnvio } = useEnvio(carrito);
  const dispatch = useDispatch();
  const itemCodes = useRef(carrito);
  const [cargando, setCargando] = useState(false);

  useEffect(() => {
    dispatch(editCostoEnvioAction(envio));
  }, [envio, dispatch]);
  useEffect(() => {
    setCargando(true);
    fetch(`${urlServices.URL_SERVICE_TIENDA}api/v4/Productos/Carrito`,postConfig(itemCodes.current.map((c) => c.itemCode)))
      .then((response) => response.json())
      .then((data) => {
        setCargando(false);
        const nuevoCarrito = data?.map((producto) => {
          let cantidad = itemCodes.current.find(
            (c) => c.itemCode === producto.itemCode
          )?.cantidad;

          if (cantidad <= producto.stock) {
            producto.cantidad = cantidad;
          } else {
            producto.cantidad = producto.stock;
            if (producto.stock <= 0) {
              setError(
                "Lamentamos avisarte que algunos productos de tu carrito fueron removidos por problemas de stock"
              );
            } else {
              setError(
                "Lo sentimos algunas cantidades de sus productos cambiaron por problemas de stock"
              );
            }
          }
          return producto;
        });
        dispatch(
          editCarritoPedidoAction(nuevoCarrito.filter((p) => p.cantidad > 0))
        );
      })
      .catch(e=>console.log(e));
  }, [dispatch, setError]);

  useEffect(() => {
    if (errorEnvio) {
      setError(
        "Error al obtener los costos de envío por favor recarga la pagina o intentelo mas tarde"
      );
    }
  }, [errorEnvio, setError]);
  const subTotal = carrito
    .map((item) =>  ((item.iva * item.precio) + item.precio) * item.cantidad)
    .reduce((a, b) => a + b, 0);

console.log(carrito[0].iva);
console.log(carrito[0].precio);
console.log(subTotal);

  const productos = carrito.filter(p=>!p.isPaquete)
    const paquetesHdrsIds = [] 
  carrito.filter(p=>p.isPaquete).forEach((p)=>{ 
      if(!paquetesHdrsIds.includes(p.idProductoHdr)){
          paquetesHdrsIds.push(p.idProductoHdr)}})
  
  return (
    <Paper elevation={6}>
      <Box p={4}>
        {cargando ? (
          <Box display="flex" justifyContent="center">
            <Loader type="ThreeDots" color="#fe5000" height={50} width={50} />
          </Box>
        ) : (
          <>
            <Typography variant="h6" fontWeight="bold" align="center">
              Tu orden
            </Typography>
            <Box mt={2}>
              <Typography variant="subtitle2" fontWeight="bold">
                Productos
              </Typography>
            </Box>
            <Box>
              {productos.map((producto) => (
                <ProductoCheckOut key={producto.itemCode} producto={producto} />
              ))}
              {paquetesHdrsIds.map(hdrs=> 
                {
                  const productosPaquete = carrito.filter(p=>p.idProductoHdr === hdrs)
                  console.log(productosPaquete)
                  const precio =productosPaquete.map(producto=>(producto.precio * producto.iva + producto.precio) * producto.cantidad)
                  .reduce((a,b)=>a+b,0)
                  return <PaqueteCheckOut nombre={productosPaquete[0]?.productoHdr?.nombre} 
                  cantidad={(productosPaquete.map(p=>p.cantidad).reduce((a,b)=>a+b,0))} 
                  precio={precio}/>
                })}
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            <PrecioCard price={envio} title={"Costo de envío"} />
            <PrecioCard price={subTotal} title={"Subtotal"} />
            <PrecioCard price={envio + subTotal} title={"Total"} large />
            <Box my={2}>
              <Divider />
            </Box>
            <Typography variant="subtitle2">
              El monto total que se muestra es el precio final con IVA y costos
              de envió incluido.
            </Typography>
            <Box my={2}>
              <Divider />
            </Box>
            <p  style={{textAlign:"center",fontSize:12,fontWeight:"bold"}}>
              En caso de requerir factura favor de marcar la siguiente opción y llenar con tus datos de facturación el formulario que aparecerá antes de realizar el pago
            </p>
            <Box my={4} sx={{display:"flex",alignContent:"center",alignItems:"center"}}>
            
              <Switch checked={facturacion} onChange={changeFacturacion}  size="small"/>
              <Typography variant="subtitle2">¿Requiere Factura? </Typography>
            </Box>
            <Box mt={2}>
              <LoadingButton 
                fullWidth
                loading={validando}
                component="span"
                variant="contained"
                color="primary"
                endIcon={<i className="fas fa-arrow-right ml-2"></i>}
                onClick={confirmarPago}
                disabled={errorEnvio}
              >
                Pagar
              </LoadingButton>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default CheckOutCard;
