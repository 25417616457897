import { IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { ArrowDownward, ArrowUpward, Toc } from "@mui/icons-material";
import React from "react";
import { useState } from "react";

const Ordenar = ({ cambiarOrdenProductos }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [orderBy, setorderBy] = useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (campo, orden, comentario) => {
    setAnchorEl(null);
    if (!comentario) return;
    cambiarOrdenProductos(`${orden}=${campo}`);

    setorderBy(`Ordenado por ${comentario}`);
  };
  return (
    <>
      <div>
        <IconButton
          component="span"
          aria-label="more"
          aria-controls="long-menu"
          onClick={handleClick}
        >
          <span style={{ fontSize: 14 }}>
            {orderBy === "" ? "Ordenar por" : orderBy}
          </span>
          <Toc color="primary" />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          disableScrollLock={true}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={(e) =>
              handleClose("Precio", "orderByDesc", "Mayor precio")
            }
            style={{ fontSize: 12 }}
          >
            <ListItemIcon>
              <ArrowUpward fontSize="small" />
            </ListItemIcon>
            Mayor precio
          </MenuItem>
          <MenuItem
            onClick={(e) => handleClose("Precio", "orderBy", "Menor precio")}
            style={{ fontSize: 12 }}
          >
            <ListItemIcon>
              <ArrowDownward fontSize="small" />
            </ListItemIcon>
            Menor precio
          </MenuItem>
          <MenuItem
            onClick={(e) => handleClose("Nombre", "orderBy", "a-z")}
            style={{ fontSize: 12 }}
          >
            <ListItemIcon>
              <ArrowUpward fontSize="small" />
            </ListItemIcon>
            A - Z
          </MenuItem>
          <MenuItem
            onClick={(e) => handleClose("Nombre", "orderByDesc", "z-a")}
            style={{ fontSize: 12 }}
          >
            <ListItemIcon>
              <ArrowDownward fontSize="small" />
            </ListItemIcon>
            Z - A
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};

export default Ordenar;
