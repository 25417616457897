import { useState } from "react";
const useForm = (initialState = {}) => {
  const [form, setForm] = useState(initialState);

  const clearForm = () => {
    setForm(initialState);
  };

  const handleChange = ({ target }) => {
    
    setForm({
      ...form,
      [target.name]: target.value,
    });
  };

  return { form, clearForm, handleChange, setForm };
};

export default useForm;
