import { encryptStorage } from "./localStorage";

const authNameLs = {
  name: "alts",
};

export const getAuthLS = () => {
  const auth = encryptStorage.getItem(authNameLs.name);
  if (auth) {
    return auth;
  }
  return {
    token: false,
    fechaExpiracion: "",
  };
};

export const setAuthLS = (auth) => {
  encryptStorage.setItem(authNameLs.name, auth);
  return encryptStorage.getItem(authNameLs.name);
};

export const clearAuthLs = () => {
  encryptStorage.removeItem(authNameLs.name);
  return {
    token: false,
    fechaExpiracion: "",
  };
};
