import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {Box,Button,Typography,Grid,Divider,TextField,Breadcrumbs,Link,Rating,Container} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import Presentaciones from "./Presentaciones";
import { AddShoppingCartOutlined, LocalOfferOutlined, NavigateNextOutlined, LocalShipping, PictureAsPdfOutlined, ImportContactsOutlined, AddOutlined, RemoveOutlined, Check,} from "@mui/icons-material";
import ReactPixel from "react-facebook-pixel";
import TagManager from 'react-gtm-module'
import { green, red, grey } from "@mui/material/colors";
import CaroucelImageProducto from "./CaroucelImageProducto";
import currencyFormatter from "currency-formatter";
import ProductosRelacionados from "./ProductosRelacionados";
import ListIconsCaracteristicas from "./ListIconsCaracteristicas";
import { useSnackbar } from "notistack";
import { isMobile } from "react-device-detect";
import VideosGalery from "./VideosGalery";
import ModalProductoRecomendado from "./ModalProductoRecomendado";
import Espesificaciones from "./espesificaciones/Espesificaciones";
import { ModosEmpleoList } from "./modoempleo/ModosEmpleoList";
import ComentariosList from "./comentarios/ComentariosList";
import {ModalPaquete} from "./ModalPaquete";
import { validateNotDecimalNumber } from "../../helper/validaciones";
import useForm from "../../hooks/useForm";
import { agregarCarritoAction } from "../../actions/carrito";
import PaymentLogos from "../../assets/img/visa_paypal_mastercard.png";

const useStyles = makeStyles((theme) => ({
  margin: {
    bottom: 10,
    right: 2,
  },
  price: {
    color: green[400],
    fontWeight: "bold",
  },
  textRed: {
    color: red[400],
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100vh",
  },
  subtitle: {
    fontWeight: "bold",
    color: "#000",
  },
  btnContainer: {
    marginTop: 20,
    [theme.breakpoints.up("md")]: {
     
      marginTop: 0,
    },
  },
  imageCard: {
    width: "100%",
    height: "100%",
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
  lastPrice :{
    color: grey[600],
    textDecoration: "line-through",
    paddingLeft: 10,
    paddingRight: 10,


  },
  discount : {
    color: "#ffffff",
    padding: "10px",
    background : "#FF5C00",
  }
}));

const InformacionProducto = ({ producto, handleChangeProducto, productos,cantidadPaquete,isPaquete }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { form, clearForm, handleChange, setForm } = useForm({cantidad: 1});


  const { cantidad } = form;
  const [openModalRecomendado, setOpenModalRecomendado] = useState(false);
  const [cantidadRelacionado, setCantidadRelaciondo] = useState(0);
  const [isOpenModalPaquete,setIsOpenModalPaquete] = useState(false);
  const addCarrito = () => {

    if ( cantidad > 0 && cantidad <= producto.stock) {
      ReactPixel.track("AddToCart", { producto: producto.nombre, cantidad });
      
      TagManager.dataLayer({
        dataLayer:{
          ecommerce: {
            products:[ {
              name:producto.nombre,
              quantity: cantidad,
              procuctId:producto.itemCode,
              unityPrice:producto.precio,
              iva:producto.iva,
              currency:producto.moneda,
            }]
          },
          event:"add_to_cart"
        } 
      })
      dispatch(agregarCarritoAction(producto, parseInt(cantidad, 10)));
      if (producto?.productoPaquete) {
        setCantidadRelaciondo(cantidad * producto?.productoPaquete?.cantidad);
        setOpenModalRecomendado(true);
      }
      enqueueSnackbar(`El producto se agrego correctamente`, { variant: "success" });
      clearForm();
      return;
    }
    enqueueSnackbar(`La cantidad del producto debe ser mayor a 0 y menor que ${producto.stock}`, { variant: "error" });
  };
  const multimedia = isPaquete ? producto.productoHdr.multimediaHdrs:producto.multimedia
  const videos = multimedia.filter(
    (mul) => mul.tipo === "youtube" || mul.tipo === "video"
  );
  const addOne = () => {
    if (Number(cantidad) <= Number(producto.stock)) {
      setForm((f) => ({ ...f, cantidad: Number(cantidad) + 1 }));
    } else {
      enqueueSnackbar(`La cantidad del producto debe ser menor que ${producto.stock}`, { variant: "error" });
    }
  };

  const subOne = () => {
    if (cantidad > 1) {
      setForm((f) => ({ ...f, cantidad: Number(cantidad) - 1 }));
    }
  };

  const handleCloseModalRecomendado = () => setOpenModalRecomendado(false);

  const addProductoRelacionado = (producto, cantidad) => {
    ReactPixel.track("AddToCart", { producto: producto.nombre, cantidad });
    dispatch(agregarCarritoAction(producto, parseInt(cantidad, 10)));
    setOpenModalRecomendado(false);
  };

  const handleCloseModalPaquete = () => setIsOpenModalPaquete(false)
  const handleOpenModalPaquete  = () => setIsOpenModalPaquete(true)

  const addPaquetecarrito = (productos )=>{

     const totalProductos  = productos.map(p=>p.cantidad ).reduce((a,b)=>a+b,0)
     if (totalProductos <= 0){
          enqueueSnackbar("No tienes agregado ningún producto al paquete!", { variant: "error" });
          return false
     }
     if(!(totalProductos>=cantidadPaquete)){
          enqueueSnackbar(`El paquete debe contener mas de ${cantidadPaquete} piezas`, { variant: "error" });
          return false
     }
            
     TagManager.dataLayer({
      dataLayer:{
        ecommerce: {
          products:productos.map(p=>({ name:p.nombre, quantity:p.cantidad, procuctId:p.itemCode, unityPrice:p.precio, iva:p.iva, currency:p.moneda}))
        },
        event:"add_to_cart"}})
     productos.forEach(prod => {
      dispatch(agregarCarritoAction(prod, parseInt(prod.cantidad, 10)));
     });
     enqueueSnackbar(`Los productos se agregaron correctamente`, { variant: "success" });
     handleCloseModalPaquete()
     return true;

  }

  return (
    <>
      {producto?.productoPaquete?.producto && (
        <ModalProductoRecomendado open={openModalRecomendado} handleClose={handleCloseModalRecomendado} producto={producto?.productoPaquete?.producto}
          cantidad={cantidadRelacionado}
          addProducto={addProductoRelacionado}/>
      )}
      {isPaquete&&<ModalPaquete productHdr={ producto.productoHdr} combo={productos} isOpen={isOpenModalPaquete} handleClose={handleCloseModalPaquete} cantidadPaquete={cantidadPaquete} addPaquetecarrito={addPaquetecarrito}/>}
      
      <Grid>
        <Box>
          <Container>
            <Box my={5} display="flex" flexDirection="row">
              <Breadcrumbs separator={<NavigateNextOutlined fontSize="small" />} aria-label="breadcrumb">
                <Typography variant="subtitle2" color="inherit" href="/">
                  Productos
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ textDecoration: "none" }}
                  component={RouterLink}
                  to={`/productos-${producto?.catalogo?.nombre.replaceAll(" ", "-")}/${producto?.catalogo?.id}`}
                  color="text.primary">
                  {producto?.catalogo?.nombre}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Box>
                  <CaroucelImageProducto multimedia={multimedia} />
                </Box>
                {!isMobile && videos.length > 0 ? (
                  <Box mt={5} mr={2}>
                    <VideosGalery videos={videos} />
                  </Box>
                ) : null}
              </Grid>

              <Grid item xs={12} md={8}>
                <Box mt={1}>
                  <Typography display="inline" fontWeight="bold" variant="subtitle2">
                    Clave del producto:
                    <Typography display="inline" className={classes.subtitle} variant="body2">
                      {producto.itemCode}
                    </Typography>
                  </Typography>
                </Box>
                <Typography variant="h5">{isPaquete?producto.productoHdr?.nombre:producto.nombre}</Typography>
                <Box mt={2}>
                  <Typography variant="body1">{producto.productoHdr?.descLarga}</Typography>
                </Box>
                <Box mt={2} display="flex">
                  <Rating readOnly size="large" value={producto.calificacion ? producto.calificacion : 5}/>
                  <Box sx={{ marginLeft: 1 }}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      ¡Califica este producto!
                    </Typography>
                  </Box>
                </Box>
                {productos?.length > 1 && !isPaquete ? (
                  <Box>
                     <Typography variant="h6">Presentaciones</Typography>
                    <Presentaciones productos={productos} handleChangeProducto={handleChangeProducto}/>
                  </Box>
                ):null}

                <Box mt={5}>
                  {producto.caracterizticas.map((c) => {
                    if (c.tipo === "lista") { return (
                        <div key={c.id}>
                          <Typography variant="subtitle1" fontWeight="bold">
                            {c.titulo}
                          </Typography>
                          <ul>
                            {c?.detalle.map((detalle) => (
                              <li my={1} key={detalle.id}>
                                <Box>
                                  <Typography variant="body1">
                                    {detalle.descripcion}
                                  </Typography>
                                </Box>
                              </li>
                            ))}
                          </ul>
                        </div>
                      );
                    }
                    if (c.tipo === "lista-checks") {
                      return (
                        <div key={c.id}>
                          <Typography variant="subtitle1" fontWeight="bold">
                            {c.titulo}
                          </Typography>
                          <ul style={{ listStyleType: "none", padding: 0 }}>
                            {c?.detalle.map((detalle) => (
                              <li my={1} key={detalle.id}>
                                <Box display="flex" alignItems={"center"}>
                                  <Check
                                    sx={{
                                      color: "green",
                                      marginRight: 2,
                                      fontSize: 45,
                                    }}
                                  />
                                  <Typography variant="h6">
                                    {detalle.descripcion}
                                  </Typography>
                                </Box>
                              </li>
                            ))}
                          </ul>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Box>
               
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} >
                  <Box display="flex"  alignItems="center">
                    

                    {
                      producto.descuento > 0 ? (
                        <>                 
                          <Typography variant="h4" fontWeight="bold"  className={classes.price}>
                            {currencyFormatter.format(( (producto.precio + producto.precio * producto.iva) )*cantidadPaquete , {   code: "MXN", })}
                            {producto.moneda}
                          </Typography>

                          <Typography variant="h5" fontWeight="bold"  className={classes.lastPrice}>
                            {currencyFormatter.format((producto.precioSinDescuento + producto.precioSinDescuento * producto.iva)*cantidadPaquete, {   code: "MXN", })}
                            {producto.moneda}
                          </Typography>

                          <Typography variant="h6" fontWeight="bold"  className={classes.discount}>    
                           <span>AHORRA </span> 
                           {
                             producto.descuento * 100           
                           }
                           %
                          </Typography>
                        </>
                      ):(
                        <>
                          <Typography variant="h4" fontWeight="bold"  className={classes.price}>
                            {currencyFormatter.format(cantidadPaquete<=0 ? (producto.precio + producto.precio * producto.iva):(producto.precio + producto.precio * producto.iva)*cantidadPaquete, {   code: "MXN", })}
                            {producto.moneda}
                          </Typography>
                        </>
                      )
                    }

                  </Box>

                  </Grid>
                  <Grid item xs={6} sm={4}>
                    {!producto.costoEnvios || producto.costoEnvios <= 1 ? (
                      <Box display="flex" justifyContent="center" alignContent="center" alignItems="center">
                        <LocalShipping sx={{ fontSize: 40 }} color="primary" />
                        <Typography color="primary" variant="subtitle1">
                          Envío sin costo!
                        </Typography>
                      </Box>
                    ) : (
                      <Box display={"flex"} justifyContent="center" alignContent="center" alignItems="center" >
                        <LocalOfferOutlined
                          sx={{ fontSize: 40 }}
                          color="primary"
                        />{" "}
                        <Typography color="primary" variant="subtitle2">
                          Envío gratis a partir de {producto.costoEnvios}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Box>
                      <Typography fontWeight="bold" variant="subtitle2" textAlign="center">
                        Pague seguro con
                      </Typography>
                    </Box>
                    <Box>
                      <Box display="flex" justifyContent="center">
                        <img src={PaymentLogos} style={{ maxWidth: "100%" }} alt="mastercard logo"/>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={2} md={4}>
                  <Grid container mt={2}>
                    <Grid item xs={12} md={4}>
                      <Box width="100%" display="flex">
                        {!isPaquete&&<TextField label="Cantidad" type="number" name="cantidad" min="1" size="small" step="1" variant="outlined" onKeyPress={validateNotDecimalNumber} 
                                    value={cantidad} onChange={handleChange}/>} 
                        {isMobile && (
                          <Box display="flex">
                            <Button fullWidth onClick={subOne}>
                              <RemoveOutlined />
                            </Button>
                            <Button fullWidth>
                              <AddOutlined onClick={addOne} />
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={isPaquete?12:8}>
                      <Box className={classes.btnContainer}>
                        <Button size="small" className={classes.input} onClick={( ()=> { 
                          if(!isPaquete)
                              addCarrito()
                          else
                              handleOpenModalPaquete() })} variant="contained" color="primary" fullWidth>
                          <AddShoppingCartOutlined /> Agregar al carrito
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box my={3} mr={2}>
                  <Divider style={{ height: 2 }} />
                </Box>
                <Box mt={4}>
                  <ListIconsCaracteristicas
                    rutas={producto.iconosCaracterizticas?.map(
                      (caract) => caract.url
                    )}
                  />
                </Box>
                <Box mt={5} display="flex">
                  {producto.fichaTecnica && (
                    <Box>
                      <Button
                        href={producto.fichaTecnica}
                        target="_blank"
                        component={Link}
                        startIcon={<PictureAsPdfOutlined />}
                      >
                        Ficha Técnica
                      </Button>
                    </Box>
                  )}
                  {producto.msds && (
                    <Box>
                      <Button component={Link} startIcon={<ImportContactsOutlined />} >
                        MSDS
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
            {isMobile && videos.length > 0 ? (
              <Box mt={2}>
                <VideosGalery videos={videos} />
              </Box>
            ) : null}
            <Box mt={10} mb={2}>
              <Grid container spacing={10}>
                {producto.especifiaciones?.length > 0 && (
                  <Grid item xs={12} md={6}>
                    <Espesificaciones
                      espesificaciones={producto.especifiaciones}
                    />
                  </Grid>
                )}
                {producto.modoEmpleo?.length > 0 && (
                  <Grid item xs={12} md={6}>
                    <ModosEmpleoList modosEmpleo={producto.modoEmpleo} />
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box mt={4}>
              <ComentariosList idProductoHdr={producto.idProductoHdr} />
            </Box>
            <ProductosRelacionados producto={producto} />
          </Container>
        </Box>
      </Grid>
    </>
  );
};

export default InformacionProducto;
 