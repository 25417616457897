import React from "react";
import { isMobile } from "react-device-detect";
import ImageGallery from "react-image-gallery";
import { Box } from "@mui/material";

const CaroucelImageProducto = ({ multimedia }) => {
  const images = multimedia?.filter((mul) => mul.tipo === "Imagen");

  const arrayImage = images?.map((item) => ({
    original: `/${item.urlMultimedia}`,
    thumbnail: `/${item.urlMultimedia}`,
  }));
  return (
    <Box>
      <ImageGallery
        thumbnailPosition={isMobile ? "bottom" : "bottom"}
        showIndex={false}
        showNav={false}
        showPlayButton={false}
        showFullscreenButton={false}
        autoPlay={false}
        showBullets={false}
        items={arrayImage}
      />
    </Box>
  );
};

export default CaroucelImageProducto;
