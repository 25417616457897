import React from "react";
import { Switch, Redirect, useHistory } from "react-router-dom";
import { Grid, CssBaseline } from "@mui/material";
import { Router } from "@mui/icons-material";
import Login from "../pages/Login";
import Registro from "../pages/Registro";

import Logo from "../assets/img/LogoPegaduro.png";
import ResetPassword from "../pages/ResetPassword";
import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100vh",
    },
    image: {
      backgroundImage: `url(${Logo})`,
      backgroundRepeat: "no-repeat",
      backgroundColor: theme.palette.mode === "light" ? grey[50] : grey[900],

      backgroundPosition: "center",
      "&:hover:not(.Mui-disabled)": {
        cursor: "pointer",
      },
    },
  };
});
const AuthRouter = () => {
  const classes = useStyles();

  const history = useHistory();
  const handleLinkHome = () => {
    history.push("/");
  };
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />

      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        className={classes.image}
        onClick={handleLinkHome}
      />
      <Switch>
        <Router exact path="/auth/login" component={Login} />
        <Router exact path="/auth/registro" component={Registro} />
        <Router exact path="/auth/reset-password" component={ResetPassword} />
        <Redirect to="/NotFound" />
      </Switch>
    </Grid>
  );
};

export default AuthRouter;
