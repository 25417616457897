import { Box, Typography } from "@mui/material";
import React from "react";
import LogoPegaduro from "../../assets/img/LogoPegaduro.png";
const ErrorInfo = ({ text }) => {
  return (
    <Box p={10}>
      <Box display="flex" justifyContent="center" m={5}>
        <img className="text-center" alt="Industrias Pegaduro" src={LogoPegaduro} />
      </Box>
      <Box width="100%">
        <Typography align="center" variant="h5">
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default ErrorInfo;
