import React, { useState, useEffect } from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { urlServices } from "../../helper/urlsBase";
import { useParams } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
const styleChek = {
  transform: "scale(1)",
};

const Filtros = () => {
  const { idCatalogo } = useParams();
  const { query } = useQueryParams();
  const history = useHistory();
  const { data } = useFetch(
    `${urlServices.URL_SERVICE_TIENDA}api/v1/Productos/Filtos?${
      idCatalogo ? `idCatalogo=${idCatalogo}` : ""
    }`
  );

  const [materialState, setmaterialState] = useState(
    query.get("material") ? query.get("material") : ""
  );
  const [empaqueState, setempaqueState] = useState(
    query.get("empaque") ? query.get("empaque") : ""
  );
  const cambiarFiltros = ({ target }) => {
    query.delete("page");
    query.delete(target.name);
    target.value && query.append(target.name, target.value);

    history.replace({
      search: query.toString(),
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    setempaqueState(query.get("empaque") ? query.get("empaque") : "");
    setmaterialState(query.get("material") ? query.get("material") : "");
  }, [query]);
  return (
    <>
      <Box>
        <Box mt={1}>
          <Typography variant="subtitle1" fontWeight="bold">
            Empaque
          </Typography>

          <RadioGroup
            name="empaque"
            value={empaqueState}
            onChange={cambiarFiltros}
          >
            <FormControlLabel
              value=""
              color="primary"
              style={styleChek}
              control={<Radio color="primary" />}
              label={<span style={{ fontSize: ".85rem" }}>Todos</span>}
            />

            {data?.empaques?.map(
              (empaque) =>
                empaque && (
                  <FormControlLabel
                    value={empaque}
                    color="primary"
                    key={empaque}
                    style={styleChek}
                    control={<Radio color="primary" />}
                    label={
                      <span style={{ fontSize: ".85rem" }}>{empaque}</span>
                    }
                  />
                )
            )}
          </RadioGroup>
        </Box>
        <Box mt={1}>
          <Typography variant="subtitle1" fontWeight="bold">
            Material
          </Typography>

          <RadioGroup
            name="material"
            value={materialState}
            onChange={cambiarFiltros}
          >
            <FormControlLabel
              value=""
              style={styleChek}
              color="primary"
              control={<Radio color="primary" />}
              label={<span style={{ fontSize: ".85rem" }}>Todos</span>}
            />

            {data?.material?.map(
              (material) =>
                material && (
                  <FormControlLabel
                    value={material}
                    key={material}
                    style={styleChek}
                    color="primary"
                    control={<Radio color="primary" />}
                    label={
                      <span style={{ fontSize: ".85rem" }}>{material}</span>
                    }
                  />
                )
            )}
          </RadioGroup>
        </Box>
      </Box>
    </>
  );
};

export default Filtros;
