import { Box } from "@mui/material";
import React from "react";
import ReactPlayer from "react-player/file";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import shortUUID from "short-uuid";
import { FiberManualRecord } from "@mui/icons-material";
const VideosGalery = ({ videos }) => {
  return (
    <Box>
      <Carousel
        showIndicators={true}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        emulateTouch
        autoFocus
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          const defStyle = {
            marginLeft: 20,
            color: "#ffa175",
            cursor: "pointer",
          };
          const style = isSelected
            ? { ...defStyle, color: "#fe5000" }
            : { ...defStyle };
          return (
            <span
              style={style}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            >
              <FiberManualRecord style={{ fontSize: 12 }} />
            </span>
          );
        }}
        infiniteLoop={true}
      >
        {videos?.map((video) => {
          if (video.tipo === "youtube") {
            return (
              <Box key={shortUUID()}>
                <Youtube src={video.urlBase + video.urlMultimedia} />
              </Box>
            );
          } else if (video.tipo === "video") {
            return (
              <Box key={shortUUID()}>
                <Video src={video.urlBase + video.urlMultimedia} />
              </Box>
            );
          }
          return null;
        })}
      </Carousel>
    </Box>
  );
};

const Youtube = ({ src, title }) => {
  return (
    <Box width="100%">
      <iframe  width="100%"  title={title} src={src} allowFullScreen />
    </Box>
  );
};

const Video = ({ src }) => {
  return (
    <Box>
      <ReactPlayer
        height={280}
        width="100%"
        controls={true}
        url={src}
        allowFullScreen
      />
    </Box>
  );
};

export default VideosGalery;
