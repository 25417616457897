import { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";

import { theme } from "./theme/configMaterial";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Provider } from "react-redux";
import { store } from "./store/store";
import AppRouter from "./routes/AppRouter";
import DialogDisponibilidad from "./components/shared/DialogDisponibilidad";
import { idPixel } from "./facebookpixel/config";
import ReactPixel from "react-facebook-pixel";

const EcommerceApp = () => {
  //Ab3HiggD28oqyg6_ojNmT3lHPa5vYlyzc5uY8cdRUCSbGBbFntajmnWinuJGBYpIVRqH7XnBaWqKEYiP
  useEffect(() => {
    ReactPixel.init(idPixel);
    ReactPixel.pageView();
  }, []);

  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={4 * 1000}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <PayPalScriptProvider
            options={{
              "client-id":
              "Ad5vRk4XllYF6Yzv0xsLWb5AfWhkxkbTN_esZTf3-0LbTtBXCZ6NfNu0jzBSVEdq6b0PAHlpM4isOSad",//productivo
                //"Ab3HiggD28oqyg6_ojNmT3lHPa5vYlyzc5uY8cdRUCSbGBbFntajmnWinuJGBYpIVRqH7XnBaWqKEYiP",
              currency: "MXN",
              commit: true,
              components: "buttons"
            }}
          >
            <>
              <AppRouter />
              <DialogDisponibilidad />
            </>
          </PayPalScriptProvider>
        </ThemeProvider>
      </Provider>
    </SnackbarProvider>
  );
};

export default EcommerceApp;
