import { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select, Grid, Container } from "@mui/material";
import Pedido from "./Pedido";
import { nombresMeses } from "../../helper/validaciones";

const ListPedidos = ({ pedidos }) => {
  //Estados de los select de año y mes
  const [date, setdate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  });
  console.log(pedidos)
  const objectYears = pedidos?.reduce((r, a) => {
    r[new Date(a.fecha).getFullYear()] =
      r[new Date(a.fecha).getFullYear()] || [];
    r[new Date(a.fecha).getFullYear()].push(a);
    return r;
  }, Object.create(null));

  const objectMonths = objectYears[date.year]?.reduce((r, a) => {
    r[new Date(a.fecha).getMonth()] = r[new Date(a.fecha).getMonth()] || [];
    r[new Date(a.fecha).getMonth()].push(a);
    return r;
  }, Object.create(null));

  const [pedidosFiltrado, setpedidosFiltrado] = useState(pedidos);
  const años = Object.keys(objectYears);
  const meses = Object.keys(objectMonths);
  const handleChange = ({ target }) => {
    setdate((state) => ({
      ...state,
      [target.name]: [target.value],
    }));
  };

  useEffect(() => {
    if (date.year === 0) {
      setpedidosFiltrado([]);
    } else {
      const filterPedidos = pedidos.filter(
        (p) => new Date(p.fecha).getFullYear() === Number(date.year)
      );
      const pedidosFiltradosMes = filterPedidos.filter(
        (p) => new Date(p.fecha).getMonth() === Number(date.month)
      );
      setpedidosFiltrado(pedidosFiltradosMes);
    }
  }, [pedidos, date]);

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-label">Año</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={date.year}
              name="year"
              onChange={handleChange}
              MenuProps={{ autoFocus: false, disableScrollLock: true }}
            >
              {años.map((año) => (
                <MenuItem key={año} value={año}>
                  {año}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-label">Mes</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={date.month}
              name="month"
              onChange={handleChange}
              MenuProps={{ autoFocus: false, disableScrollLock: true }}
            >
              {meses.map((mes, i) => (
                <MenuItem key={i} value={mes}>
                  {nombresMeses[mes]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {pedidosFiltrado.map((pedido) => (
          <Grid item xs={12}>
            <Pedido key={pedido.id} pedido={pedido} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ListPedidos;
