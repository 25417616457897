import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  AccountCircle,
  AssignmentIndOutlined,
  ExitToAppOutlined,
} from "@mui/icons-material";
import { Menu, MenuItem, ListItemIcon, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { logOutAction } from "../../actions/auth";
const MenuLoged = () => {
  const [anchor, setAnchor] = React.useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleMenuClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = (campo, orden, comentario) => {
    setAnchor(null);
  };

  const logoutHandleClick = () => {
    setAnchor(null);
    dispatch(logOutAction());
    history.push("/");
  };
  return (
    <>
      <IconButton
        id="basic-button"
        onClick={handleMenuClick}
        aria-controls="long-menu"
        aria-haspopup="true"
        aria-expanded={anchor ? "true" : undefined}
      >
        <AccountCircle fontSize="medium" style={{ color: "#000" }} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        sx={{ width: 200 }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem component={Link} onClick={handleClose} to="/mis-pedidos">
          <ListItemIcon>
            <AssignmentIndOutlined />
          </ListItemIcon>
          Mis pedidos
        </MenuItem>
        <MenuItem onClick={logoutHandleClick}>
          <ListItemIcon>
            <ExitToAppOutlined />
          </ListItemIcon>
          Salir
        </MenuItem>
      </Menu>
    </>
  );
};

export default MenuLoged;
