import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import { urlServices } from "../../helper/urlsBase";
import { useFetch } from "../../hooks/useFetch";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalNuestrasPlantas = ({ handleClose, open }) => {
  const { data: sucursales } = useFetch(
    `${urlServices.URL_SERVICE_TIENDA}api/Partials/Sucursales`
  );

  return (
    <Dialog
      open={open}
      Transition={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle color="primary" id="alert-dialog-slide-title">
        Contacto
      </DialogTitle>
      <DialogContent>
        {sucursales?.map((sucursal) => {
          return (
            <Box key={sucursal.id}>
              <Typography variant="subtitle1" color="primary">
                {sucursal.nombre}
              </Typography>
              {sucursal.contactoSucursal?.map((contacto) => {
                if (contacto.tipo === "Telefono") {
                  return (
                    <Box p={1} key={contacto.id}>
                      <Typography variant="subtitle2">
                        <i
                          style={{ color: "#fe5000", paddingRight: 5 }}
                          className="fas fa-phone-alt text-color-primary"
                        ></i>
                        <a
                          style={{
                            color: "inherit",
                            textDecoration: "inherit",
                          }}
                          href={`tel:${contacto.contacto}`}
                          className="ml-2  text-color-black"
                        >
                          {contacto.contacto}
                        </a>
                      </Typography>
                    </Box>
                  );
                } else if (contacto.tipo === "Correo") {
                  return (
                    <Box p={1} key={contacto.id}>
                      <Typography variant="subtitle2">
                        <i
                          style={{ color: "#fe5000", paddingRight: 5 }}
                          className="far fa-envelope text-color-primary"
                        ></i>
                        <a
                          style={{
                            color: "inherit",
                            textDecoration: "inherit",
                          }}
                          href={`mailto:(${contacto.contacto})`}
                          className="ml-2 text-color-black"
                        >
                          {contacto.contacto}
                        </a>
                      </Typography>
                    </Box>
                  );
                } else if (contacto.tipo === "Direccion") {
                  return (
                    <Box p={1} key={contacto.id}>
                      <Typography variant="subtitle2">
                        <i
                          style={{ color: "#fe5000", paddingRight: 5 }}
                          className="fas fa-map-marker-alt text-color-primary"
                        ></i>
                        <a
                          style={{
                            color: "inherit",
                            textDecoration: "inherit",
                          }}
                          href={contacto.url}
                          target="_blank"
                          rel="noreferrer"
                          className="ml-2 text-color-black"
                        >
                          {contacto.contacto}
                        </a>
                      </Typography>
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button component="span" onClick={handleClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalNuestrasPlantas;
