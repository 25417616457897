import React from "react";
import { Link } from "react-router-dom";
import { Link as ExternalLink } from "@mui/material";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useFetch } from "../../hooks/useFetch";
import { urlServices } from "../../helper/urlsBase";

const useStyle = makeStyles((theme) => ({
  bannerContainer: {
    position: "relative",
    height: 300,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      height: 450,
    },
    [theme.breakpoints.up("md")]: {
      height: 550,
    },
    [theme.breakpoints.up("lg")]: {
      height: 650,
    },
    [theme.breakpoints.up("xl")]: {
      height: 700,
    },
  },
  banner: {},
  video: {
    height: "100%",
    width: "100%",
  },
  image: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
}));

const Banner = () => {
  const url = `${urlServices.URL_SERVICE_TIENDA}api/Partials/Banner`;
  const { data: banners } = useFetch(url);

  return (
    <div>
      {banners?.length > 0 && (
        <Carousel
          interval={1000*4}
          autoPlay={true}
          showIndicators={false}
          showStatus={false}
          showArrows={true}
          showThumbs={false}
          emulateTouch
          autoFocus
          infiniteLoop={true}
          transitionTime={600}
        >
          {banners[0].detalle.map((det) => {
            if (det.tipo === "video") {
              return (
                <VideoBanner
                  src={det.urlBanner}
                  key={det.id}
                  urlEnlace={det.url}
                  urlExterna={det.urlExterna}
                />
              );
            } else {
              return (
                <ImageBanner
                  url={det.urlBanner}
                  alt={det.alt}
                  key={det.id}
                  urlEnlace={det.url}
                  urlExterna={det.urlExterna}
                />
              );
            }
          })}
        </Carousel>
      )}
    </div>
  );
};

const ImageBanner = ({ url, alt, urlEnlace, urlExterna }) => {
  const classes = useStyle();

  if (!urlEnlace) {
    return (
      <Box className={classes.bannerContainer} sx={{backgroundImage:`url(${url})`,objectFit:"cover",backgroundRepeat:"no-repeat",backgroundSize:"100% 100%"}}>
        {/* <img className={classes.image} src={url} alt={alt} /> */}
      </Box>
    );
  } else if (urlExterna) {
    return (
      <ExternalLink target="_blank" href={urlEnlace}>
        <Box className={classes.bannerContainer} sx={{backgroundImage:`url(${url})`,objectFit:"cover",backgroundRepeat:"no-repeat",backgroundSize:"100% 100%"}}>
          {/* <img className={classes.image} src={url} alt={alt} /> */}
        </Box>
      </ExternalLink>
    );
  } else {
    return (
      <Link to={urlEnlace}>
        <Box className={classes.bannerContainer} sx={{backgroundImage:`url(${url})`,objectFit:"cover",backgroundRepeat:"no-repeat",backgroundSize:"100% 100%"}}>
          {/* <img className={classes.image} src={url} alt={alt} /> */}
        </Box>
      </Link>
    );
  }
};

const VideoBanner = ({ src, urlEnlace, urlExterna }) => {
  const classes = useStyle();
  if (!urlEnlace) {
    return (
      <Box className={classes.bannerContainer}>
        <video className={classes.video} src={src} muted autoPlay loop />
      </Box>
    );
  } else if (urlExterna) {
    return (
      <ExternalLink target="_blank" href={urlEnlace}>
        <Box className={classes.bannerContainer}>
          <video className={classes.video} src={src} muted autoPlay loop />
        </Box>
      </ExternalLink>
    );
  } else {
    return (
      <Link to={urlEnlace}>
        <Box className={classes.bannerContainer}>
          <video className={classes.video} src={`https://tienda.pegaduro.com.mx/${src}`} muted autoPlay loop />
        </Box>
      </Link>
    );
  }
};

export default Banner;
