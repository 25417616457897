import { useEffect, useState} from "react";
import { Redirect, useParams } from "react-router-dom";
import { urlServices } from "../helper/urlsBase";
import { Box } from "@mui/material";
import TagManager from 'react-gtm-module'
import MetaComponent from "../components/shared/MetaComponent";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import { useFetch } from "../hooks/useFetch";
import InformacionProducto from "../components/infoproducto/InformacionProducto";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const InfoProducto = () => {
  const { id } = useParams();
  const query = useQuery();
  const itemCode = query.get("itemCode");
  const [producto, setproducto] = useState(null);
  const { data, loading } = useFetch(`${urlServices.URL_SERVICE_TIENDA}api/v4/productos/Detalle?itemcode=${id.trim()}`);
console.log(data)
  useEffect(() => {
    if (producto) {
      ReactPixel.track("ViewContent", {
        content_ids: producto.itemCode,
        content_category: producto?.catalogo?.nombre,
        currency: producto.moneda,
        content_name: producto?.nombre,
        content_type: "producto",
        value: producto.precio + producto.precio * producto.iva,
      });
      TagManager.dataLayer({
        dataLayer:{ ecommerce: { name:producto.nombre, procuctId:producto.itemCode, unityPrice:producto.precio, iva:producto.iva, currency:producto.moneda, }, event:"select_item"} 
      })
    }
  }, [producto]);
  
  useEffect(() => {
    if (data?.productos && data?.productos.length) {
    
        const selectedProducto = itemCode? data.productos.filter((p) => p.itemCode === itemCode)[0] ||data.productos[0] : data.productos[0];
        setproducto(selectedProducto);
    }
  }, [data, itemCode]);


  if ((!data?.productos || data?.productos?.length <= 0) && !loading) { 
    return <Redirect to="/NotFound" />;
  }

  const handleChangeProducto = (producto) => {
    window.scrollTo(0, 0);
    setproducto(producto);
  };
  return (
    <>
      <MetaComponent titulo={producto?.nombre} description={producto?.descLarga}/>
      <Box mt={5} mb={10}>
 
          {producto && data?.productos && (
            <InformacionProducto producto={producto} productos={data?.productos} handleChangeProducto={handleChangeProducto} isPaquete= {data.productoHdr?.paquete} cantidadPaquete={data.productoHdr?.cantidadPaquete}/>
          )}

      </Box>
    </>
  );
};

export default InfoProducto;
