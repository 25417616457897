import { httpClient } from "./axiosconfig"

export const createOrder = async(order)=> {
    const response = await httpClient.post("/api/v3/Pedidos/create-order",order)
    if(response.status===200){
        return response.data
    }
    throw new Error( "Error al crear la orden");
}

export const confirmOrder = async(order) =>{
    const response = await httpClient.post("/api/v3/Pedidos/confirm-order",order)
    if(response.status===200){
        return response.data
    }
    throw new Error( "Error al confirmar la orden pendiente de pago");
}