import { Redirect, Switch, Route } from "react-router-dom";
import Footer from "../components/shared/Footer";
import TopBar from "../components/topbar/TopBar";
import Carrito from "../pages/Carrito";
import Catalogo from "../pages/Catalogo";
import CheckOut from "../pages/CheckOut";
import ConfirmarPago from "../pages/ConfirmarPago";
import Index from "../pages/Index";
import InfoProducto from "../pages/InfoProducto";
import MisPedidos from "../pages/MisPedidos";
import NotFound from "../pages/NotFound";
import OrdenCompletada from "../pages/OrdenCompletada";
import Unauthorized from "../pages/Unauthorized";
import UserActionPage from "../pages/UserActionPage";

const MainContenetRoute = () => {

  return (
    <>
      <TopBar />
      <main className="content">
        <Switch>
          <Route exact path="/" component={Index} />
          <Route
            exact
            path={["/productos", "/productos-:catalogo/:idCatalogo"]}
            component={Catalogo}
          />
          <Route path="/info-producto-:id" component={InfoProducto} />
          {/* <Route path="/producto-test-:id" component={InfoProductoV2} /> */}
          <Route exact path="/carrito" component={Carrito} />
          <Route exact path="/check-out" component={CheckOut} />
          <Route exact path="/confirmar-pago" component={ConfirmarPago} />
          <Route exact path="/mis-pedidos" component={MisPedidos} />
          <Route path="/revisar-orden" component={OrdenCompletada} />
          <Route exact path="/unauthorized" component={Unauthorized} />
          <Route exact path="/verify-email" component={UserActionPage} />
          <Route exact path={["/NotFound", "*"]} component={NotFound} />
          <Redirect to="/NotFound" />
        </Switch>
      </main>
      <Footer />
    </>
  );
};

export default MainContenetRoute;
