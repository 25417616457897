import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main:"#fe5000",
     
    },
    secondary: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: ` "Open Sans", "sans-serif";`,
  },
});
