import React from "react";
import { Stepper, Box, Typography, Step, StepButton } from "@mui/material";
import { BuildOutlined, CheckOutlined } from "@mui/icons-material";

export const ModosEmpleoList = ({ modosEmpleo }) => {
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <BuildOutlined sx={{ fontSize: 30, marginRight: 1 }} color="primary" />
        <Typography variant="h5" fontWeight="bold">
          Modos de empleo
        </Typography>
      </Box>
      <Box>
        {modosEmpleo.map(({ id, tipo, titulo, detalle }) => {
          if (tipo === "text") {
            return (
              <Box mt={4} key={id}>
                <Typography variant="h6">{titulo}</Typography>
                <Box mt={2}>
                  {detalle?.map((d) => (
                    <Box key={d.id}>
                      <Typography variant="body2">{d.modoEmpleo}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            );
          } else if (tipo === "list-number") {
            return (
              <Box mt={4} key={id}>
                <Typography variant="h6">{titulo}</Typography>
                <Box mt={2}>
                  <Stepper orientation="vertical">
                    {detalle?.map((d, i) => (
                      <Step active key={d.id}>
                        <StepButton disableRipple={true}>
                          {d.modoEmpleo}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Box>
            );
          } else if (tipo === "list") {
            return (
              <Box mt={4} key={id}>
                <Typography variant="h6">{titulo}</Typography>
                <Box mt={2}>
                  {detalle?.map((d, i) => (
                    <Box
                      active
                      mt={0.4}
                      key={d.id}
                      sx={{ display: "flex", alingItems: "center" }}
                    >
                      <CheckOutlined
                        sx={{ marginRight: 1, fontSize: 20, color: "green" }}
                      />
                      <Typography variant="body1">{d.modoEmpleo}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            );
          } else if (tipo === "canada") {
            return null;
          } else {
            return null;
          }
        })}
      </Box>
    </>
  );
};
