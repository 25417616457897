import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TagManager from "react-gtm-module";
import validator from "validator";
import Sticky from "react-stickynode";
import Stepper from "../components/carrito/Stepper";
import CheckOutCard from "../components/checkout/CheckOutCard";
import FormFacuturacion from "../components/checkout/FormFacuturacion";


import useForm from "../hooks/useForm";
import { editInfoPedidoAction } from "../actions/pedido";
import MetaComponent from "../components/shared/MetaComponent";
import FormDireccion from "../components/checkout/FormDireccion";
import ComentariosPedido from "../components/checkout/ComentariosPedido";
import ListDirecciones from "../components/checkout/ListDirecciones";
import ReactPixel from "react-facebook-pixel";
import { validateRFC } from "../helper/validaciones";
import { Alert, Container, Grid, Paper } from "@mui/material";
import { Box } from "@mui/material";
import AvatarTitle from "../components/shared/AvatarTitle";
import useEnvio from "../hooks/useEnvio";

import{getLocalUnregistredUser} from '../helper/UnregistredLocalStorage'
const CheckOut = () => {
  const history = useHistory();
  const [facturacion, setFacturacion] = useState(false);
  const { pedido, auth, direcciones } = useSelector((state) => state);
  const [validando, setValidando] = useState(false);
  const { carrito } = pedido;
  const dispatch = useDispatch();
  const { form: formFacturacion, handleChange: handleChangeFacturacion } =
    useForm({
      razonSocial: "",
      rfcFacturacion: "",
      domicilioFacturacion: "",
      municipioFacturacion: "",
      codigoPostalFacturacion: "",
      estadoFacturacion: "",
      regimenFiscal: "",
    });
  const unregisteredEmail = getLocalUnregistredUser()?.email
  const { form, handleChange, setForm } = useForm({
    apellidos: "",
    nombres: "",
    calle: "",
    numeroInterior: "",
    numeroExterior: "",
    codigoPostal: "",
    colonia: "",
    municipio: "",
    estado: "",
    numeroTelefono: "",
    correoElectronico:unregisteredEmail?unregisteredEmail:"",
    correoElectronicoConfirmar: "",
    comentarios: "",
  });

  const [validarMensaje, setValidarMensaje] = useState("");

  //Validacion carrito vacion regresa a la ventana del carrito
  useEffect(() => {
    try {
      if (carrito) {
        if (carrito.length < 1) {
          history.push("/carrito");
        }
      } else {
        history.push("/carrito");
      }
    } catch (e) {
      history.push("/carrito");
    }
  }, [carrito, history]);


  const {apellidos,nombres,municipio,calle,numeroExterior,numeroTelefono,colonia,estado,correoElectronico,correoElectronicoConfirmar,codigoPostal,usosCFDI,
  } = form;

  const { razonSocial, rfcFacturacion, domicilioFacturacion, municipioFacturacion, codigoPostalFacturacion, estadoFacturacion, regimenFiscal,
  } = formFacturacion;
  //validamos el formulario
  const validarFormularios = () => {
    if (validator.isEmpty(nombres.trim())) {
      setValidarMensaje("El campo de nombre(s) no puede ir vacío");
      return false;
    }
    if (validator.isEmpty(apellidos.trim())) {
      setValidarMensaje("El campo de apellidos no puede ir vacío");
      return false;
    }
    if (!validator.isEmail(correoElectronico)) {
      setValidarMensaje("El correo electronico que ingreso no es valido");
      return false;
    }
    if (correoElectronico !== correoElectronicoConfirmar) {
      setValidarMensaje("Los correos electrónicos deben coincidir");
      return false;
    }
    if (!validator.isMobilePhone(numeroTelefono.trim(), "es-MX")) {
      setValidarMensaje(
        "El numero telefono no es valido el numero de digitos del telefono debe ser de 10"
      );
      return false;
    }
    if (validator.isEmpty(calle.trim())) {
      setValidarMensaje("La calle que ingreso no es valida");
      return false;
    }
    if (validator.isEmpty(numeroExterior.trim())) {
      setValidarMensaje("El numero exterior no es valido");
      return false;
    }
    if (!validator.isPostalCode(codigoPostal, "MX")) {
      setValidarMensaje("El codigo postal que ingreso no es valido");
      return false;
    }
    if (validator.isEmpty(colonia.trim())) {
      setValidarMensaje("Por favor seleccione una colonia");
      return false;
    }
    if (validator.isEmpty(municipio.trim())) {
      setValidarMensaje("El municipio que ingreso no es valido");
      return false;
    }
    if (validator.isEmpty(estado.trim())) {
      setValidarMensaje("El estado que ingreso no es valido");
      return false;
    }

    setValidarMensaje("");
    return true;
  };
  //validamos formulario de facturación
  const validarFacturacion = async () => {
    if (validator.isEmpty(razonSocial)) {
      setValidarMensaje("Se requiere de razón social para facturar");
      return false;
    }
    if (validator.isEmpty(usosCFDI.trim())) {
      setValidarMensaje("Se requiere un motivo de factuación");
      return false;
    }
    if (validator.isEmpty(rfcFacturacion.trim())) {
      setValidarMensaje("Se requiere de un RFC para facturar");
      return false;
    }
    if(!(rfcFacturacion.trim().length===12 || rfcFacturacion.trim().length===13))
    {
      setValidarMensaje("El RFC que ingreso no es válido");
      return false;
    }
    const validRfc = await validateRFC(rfcFacturacion);
    if (!validRfc) {
      setValidarMensaje("RFC no valido");
      return false;
    }
    if (validator.isEmpty(regimenFiscal.trim())) {
      setValidarMensaje("Seleccione un régimen fiscal");
      return false;
    }
    if (validator.isEmpty(domicilioFacturacion)) {
      setValidarMensaje("Se requiere de domicilio para facturar");
      return false;
    }
    if (!validator.isPostalCode(codigoPostalFacturacion, "MX")) {
      setValidarMensaje("El código postal de facturación no es válido");
      return false;
    }
    if (validator.isEmpty(municipioFacturacion)) {
      setValidarMensaje("Se requiere del municipio para facturar");
      return false;
    }

    if (validator.isEmpty(estadoFacturacion)) {
      setValidarMensaje("Se requiere del estado para facturar");
      return false;
    }

    return true;
  };

  //cambiamos el estado de que si tiene facturación o no
  const changeFacturacion = () => {
    setFacturacion(!facturacion);
  };

  const confirmarPago = async () => {
    if (auth.token) {
      setValidando(true);
      await confirmarPagoRegistrado();
      setValidando(false);
      window.scrollTo(0, 0);
    } else {
      setValidando(true);

      await confirmarPagoNoRegistrado();
      setValidando(false);
      window.scrollTo(0, 0);
    }
  };
  const subTotal = carrito
    .map((item) => ((item.iva * item.precio + item.precio) * item.cantidad))
    .reduce((a, b) => a + b, 0);

  const { envio } = useEnvio(carrito);
  useEffect(() => {
    if (subTotal + envio > 0) {
      ReactPixel.track("InitiateCheckout", {
        currency: "MXN",
        value: subTotal + envio,
        productos: carrito,
      });
    }
  }, [carrito, subTotal, envio]);

  const confirmarPagoRegistrado = async () => {
    const direccionSeleccionada = direcciones.data?.find(
      (direccion) => direccion.selected
    );

    if (direccionSeleccionada) {
      const direccionSend = {
        apellidos: direccionSeleccionada.apellidos,
        nombres: direccionSeleccionada.nombre,
        municipio: direccionSeleccionada.municipio,
        calle: direccionSeleccionada.calle,
        numeroExterior: direccionSeleccionada.numeroExterior,
        numeroTelefono: direccionSeleccionada.telefono,
        usosCFDI: usosCFDI,
        colonia: direccionSeleccionada.colonia,
        estado: direccionSeleccionada.estado,
        correoElectronico: direccionSeleccionada.correo,
        codigoPostal: direccionSeleccionada.codigoPostal,
      };
      if (facturacion) {
        const validFacturacion = await validarFacturacion();

        if (validFacturacion) {
          dispatch(editInfoPedidoAction(direccionSend, formFacturacion));
          TagManager.dataLayer({
            dataLayer: {
              ecommerce: {
                shippingInfo: form,
                products: carrito.map((p) => ({
                  name: p.nombre,
                  quantity: p.cantidad,
                  procuctId: p.itemCode,
                  unityPrice:  p.precio,
                  iva: p.iva,
                  currency: p.moneda,
                })),
              },
              event: "add_shipping_info",
            },

          });
          history.push("/confirmar-pago");
          
        }
      } else {
        TagManager.dataLayer({
          dataLayer: {
            ecommerce: {
              shippingInfo: form,
              products: carrito.map((p) => ({
                name: p.nombre,
                quantity: p.cantidad,
                procuctId: p.itemCode,
                unityPrice:  p.precio,
                iva: p.iva,
                currency: p.moneda,
              })),
            },
            event: "add_shipping_info",
          },
        });
        dispatch(editInfoPedidoAction(direccionSend, null));
        history.push("/confirmar-pago");
      }
    } else {
      setValidarMensaje("Seleccione una dirección");
    }
  };

  const confirmarPagoNoRegistrado = async () => {
    if (validarFormularios()) {
      TagManager.dataLayer({
        dataLayer: {
          ecommerce: {
            shippingInfo: form,
            products: carrito.map((p) => ({name: p.nombre,quantity: p.cantidad,procuctId: p.itemCode,unityPrice: p.precio ,iva: p.iva,currency: p.moneda})),
          },
          event: "add_shipping_info",
        },
      });
      if (facturacion) {
        const validFacturacion = await validarFacturacion();
        if (validFacturacion) {
          ReactPixel.track("AddPaymentInfo", {
            datosEnvio: form,
            datosFacturacion: formFacturacion,
            value: envio + subTotal,
            currency: "MXN",
          });
          dispatch(editInfoPedidoAction(form, formFacturacion));
          history.push("/confirmar-pago");
        }
      } else {
        ReactPixel.track("AddPaymentInfo", {
          datosEnvio: form,
          value: envio + subTotal,
          currency: "MXN",
        });
        dispatch(editInfoPedidoAction(form, null));
        history.push("/confirmar-pago");
      }
    }
  };

  return (
    <>
      <MetaComponent
        titulo="Datos de Envío"
        description="Formulario de cofirmacion de datos de Envío"
      />
      <Container>
        <Stepper stepp="formEnvio" />
      </Container>

      <Container>
        {validarMensaje.trim() !== "" && (
          <Box mb={4}>
            <Alert variant="filled" severity="error" elevation={4}>
              {validarMensaje}
            </Alert>
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Box>
              {auth.token ? (
                <ListDirecciones />
              ) : (
                <>
                  <Paper elevation={4}>
                    <Box py={4} px={3}>
                      <AvatarTitle title="Datos de envío" stepp="1" />
                      <FormDireccion
                        form={form}
                        setForm={setForm}
                        handleChange={handleChange}
                      />
                      <ComentariosPedido
                        form={form}
                        handleChange={handleChange}
                      />
                    </Box>
                  </Paper>
                </>
              )}
              <FormFacuturacion
                formFacturacion={formFacturacion}
                handleChange={handleChange}
                handleChangeFacturacion={handleChangeFacturacion}
                setForm={setForm}
                changeFacturacion={changeFacturacion}
                facturacion={facturacion}
              />
              {/*x */}
              <div id="checkout-form-id"></div>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Sticky bottomBoundary="#checkout-form-id">
              <CheckOutCard
                validando={validando}
                confirmarPago={confirmarPago}
                setError={setValidarMensaje}
                facturacion={facturacion}
                changeFacturacion={changeFacturacion}
              />
            </Sticky>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CheckOut;
