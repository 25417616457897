import { Paper, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteProductoCarritoAction } from "../../actions/carrito";
import ProductoCarrito from "./ProductoCarrito";
import { ProductoPaquete } from "./ProductoPaquete";
const ListProducto = ({ costoEnvio }) => {
  const dispatch = useDispatch();
  const { carrito } = useSelector((state) => state);

  const removeItem = (itemCode) => {
    dispatch(deleteProductoCarritoAction(itemCode));
  };

  const removePaquete = (itemsCode) =>{
      itemsCode.forEach(itemCode => {
        dispatch(deleteProductoCarritoAction(itemCode));
      });
  } 
    
  const productos = carrito.filter(p=>!p.isPaquete)
  const paquetesHdrsIds = [] 
  carrito.filter(p=>p.isPaquete).forEach((p)=>{ 
    if(!paquetesHdrsIds.includes(p.idProductoHdr)){
        paquetesHdrsIds.push(p.idProductoHdr)}})

  return (
    <Paper elevation={3}>
      <Box p={4}>
        {productos &&
          productos.length > 0 &&
          productos.map((item) => (
            <div key={item.itemCode}>
              <ProductoCarrito costoEnvio={costoEnvio} removeItem={removeItem} producto={item}/>
            </div>
          ))}
          {paquetesHdrsIds.map(hdrs=><ProductoPaquete key={hdrs} productos={carrito.filter(p=>p.idProductoHdr === hdrs)} removePaquete={removePaquete}/>)}
      </Box>
    </Paper>
  );
};




export default ListProducto;
