import { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Paper,
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Tooltip,
  Alert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Copyright from "../components/shared/Copyright";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import useForm from "../hooks/useForm";
import {
  enviarEmailVerificacion,
  registroAction,
  singInGoogleAction,
} from "../actions/auth";
import GoogleButton from "react-google-button";
import Loader from "react-loader-spinner";
import validator from "validator";
import { authTypes } from "../types/types";
import ReactPixel from "react-facebook-pixel";
import { LockOutlined } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      padding: 100,
    },
  },
  iconAvatar: {
    fontSize: 70,
    marginBottom: 15,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
}));
const Registro = () => {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const [error, setError] = useState({
    error: false,
    mensaje: "",
    tipo: "",
  });
  const [succes, setsucces] = useState({
    succes: false,
    mensaje: "",
    tipo: "",
  });
  const { token, loading } = auth;
  const {
    form: registro,
    handleChange,
    clearForm,
  } = useForm({
    nombres: "",
    apellidos: "",
    telefono: "",
    correo: "",
    password: "",
    passwordConfirm: "",
  });

  //variables form
  const { nombres, apellidos, telefono, correo, password, passwordConfirm } =
    registro;
  useEffect(() => {
    if (token) {
      history.push("/");
    }
  }, [token, history]);

  const handleClickRegistro = (e) => {
    e.preventDefault();
    if (validarFormulario()) {
      setError({
        error: false,
        mensaje: "",
        tipo: "",
      });
      ReactPixel.track("CompleteRegistration", correo);
      dispatch(registroAction(registro));
    }
  };

  const handleClickRegistroGoogle = (e) => {
    e.preventDefault();
    dispatch(singInGoogleAction());
  };
  const validarFormulario = () => {
    if (validator.isEmpty(nombres)) {
      setError({ error: true, mensaje: "Ingrese sus nombre", tipo: "nombre" });
      return false;
    }
    if (validator.isEmpty(apellidos)) {
      setError({
        error: true,
        mensaje: "Ingrese sus apellidos",
        tipo: "apellidos",
      });

      return false;
    }
    if (!validator.isEmail(correo)) {
      setError({
        error: true,
        mensaje: "El correo electrónico que ingreso no es valido",
        tipo: "correo",
      });
      return false;
    }
    if (!validator.isMobilePhone(telefono.trim(), "es-MX")) {
      setError({
        error: true,
        mensaje: "El numero de teléfono que ingreso no es valido",
        tipo: "telefono",
      });

      return false;
    }

    if (!validator.isStrongPassword(password)) {
      setError({
        error: true,
        mensaje:
          "La contraseña que ingreso no es valida o es insegura, debe de contener al menos 8 caracteres, una letra mayúscula, una minúscula, un numero y un caracter especial",
        tipo: "password",
      });
      return false;
    }

    if (password.toLowerCase() !== passwordConfirm.toLowerCase()) {
      setError({
        error: true,
        mensaje: "Las contraseñas deben coincidir",
        tipo: "confirmpassword",
      });
      return false;
    }
    return true;
  };
  const handleSendEmail = async () => {
    const correo = await enviarEmailVerificacion();
    if (!correo.enviado) {
      setError({ error: true, mensaje: correo.mensaje, tipo: "" });

      setTimeout(() => {
        setError({ error: false, mensaje: correo.mensaje, tipo: "" });
      }, 6000);
    } else {
      setError({ error: false, mensaje: null, tipo: "" });
    }
  };

  const limpiarFormulario = useRef(clearForm);
  useEffect(() => {
    if (auth.type === authTypes.registro) {
      limpiarFormulario.current();
      setsucces({
        succes: true,
        mensaje: `Se ha enviado un correo de confirmación a ${auth.email}`,
        tipo: "registro",
      });
    }
  }, [auth]);
  return (
    <Grid item xs={12} sm={8} md={6} component={Paper} elevation={2} square>
      <div className={classes.paper}>
        <LockOutlined className={classes.iconAvatar} color="primary" />
        <Typography component="h1" variant="h4">
          Tienda Pegaduro
        </Typography>

        <form className={classes.form} noValidate>
          {error.error || auth.error ? (
            <Box mt={2} mb={2} className="animate__animated animate__fadeIn">
              <Alert variant="filled" severity="error">
                {error.mensaje ? error.mensaje : auth.error}
              </Alert>
            </Box>
          ) : null}
          {succes.succes ? (
            <Box mt={2} mb={2} className="animate__animated animate__fadeIn">
              <Alert variant="filled" severity="success">
                {succes.mensaje}
              </Alert>
              <Box mt={1}>
                <Button
                  onClick={handleSendEmail}
                  component="span"
                  fullWidth
                  color="primary"
                >
                  reenviar correo
                </Button>
              </Box>
            </Box>
          ) : null}
          <TextField
            variant="standard"
            margin="normal"
            required
            autoFocus
            autoComplete="off"
            fullWidth
            error={error.tipo === "nombre"}
            id="nombres"
            label="Nombre(s)"
            name="nombres"
            value={nombres}
            onChange={handleChange}
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            error={error.tipo === "apellidos"}
            onChange={handleChange}
            name="apellidos"
            label="Apellidos"
            type="text"
            value={apellidos}
          />
          <Tooltip title="Ejemplo: tiendapegaduro@gmail.com">
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              onChange={handleChange}
              error={error.tipo === "correo"}
              name="correo"
              value={correo}
              label="Correo Electrónico"
              type="email"
            />
          </Tooltip>
          <Tooltip title="El teléfono debe contener 10 digitos">
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              onChange={handleChange}
              error={error.tipo === "telefono"}
              name="telefono"
              value={telefono}
              label="Teléfono"
              type="tel"
            />
          </Tooltip>
          <Tooltip title="La contraseña debe de contener al menos 8 caracteres, una letra mayúscula, una minúscula, un numero y un caracter especial">
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              onChange={handleChange}
              error={error.tipo === "password"}
              name="password"
              value={password}
              label="Contraseña"
              type="password"
            />
          </Tooltip>
          <Tooltip title="Las contraseñas deben coincidir">
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              onChange={handleChange}
              error={error.tipo === "confirmpassword"}
              name="passwordConfirm"
              value={passwordConfirm}
              label="Confirmar contraseña"
              type="password"
            />
          </Tooltip>
          {loading ? (
            <Box display="flex" justifyContent="center" mt={5} mb={5}>
              <Loader type="TailSpin" color="#fe5000" height={50} width={50} />
            </Box>
          ) : (
            <Box>
              <Box mt={4}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={handleClickRegistro}
                  className={classes.submit}
                >
                  Registrarme
                </Button>
              </Box>

              <Box mt={2} mb={2} display="flex" justifyContent="flex-end">
                <GoogleButton
                  type="light"
                  style={{ textAlign: "left" }}
                  label="Ingresa con Google"
                  onClick={handleClickRegistroGoogle}
                />
              </Box>
            </Box>
          )}
        </form>
        <Box display="flex" justifyContent="flex-end"></Box>
        <Grid container>
          {/* <Grid item xs>
            <Link href="#" variant="body2">
              Olvidaste tu contraseña?
            </Link>
          </Grid> */}
          <Grid item>
            <RouterLink to="/auth/login" className={classes.link}>
              {"Ya tienes cuenta? Ingresa"}
            </RouterLink>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </div>
    </Grid>
  );
};

export default Registro;
