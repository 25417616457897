import React from "react";
import { urlServices } from "../../helper/urlsBase";

import { useFetch } from "../../hooks/useFetch";
import {  Grid, TextField, FormControl, InputLabel, Select, MenuItem, Paper, InputAdornment,} from "@mui/material";
import { Box } from "@mui/system";
import AvatarTitle from "../shared/AvatarTitle";
import { ApartmentOutlined, BusinessOutlined, DescriptionOutlined, Home, LocationCityOutlined, MarkunreadMailboxOutlined,} from "@mui/icons-material";
const FormFacuturacion = ({ handleChange, handleChangeFacturacion, formFacturacion, facturacion,}) => {
  /*Revisar que el componente esta activo */

  const { data: usosCFDI } = useFetch(`${urlServices.URL_SERVICE_TIENDA}api/v1/Envios/CFDI`);
  const { data:regimensFiscal} = useFetch(`${urlServices.URL_SERVICE_TIENDA}api/v1/Envios/regimen-fiscal`)
  const {razonSocial,rfcFacturacion,domicilioFacturacion,municipioFacturacion,codigoPostalFacturacion,estadoFacturacion} = formFacturacion;
  return (
    <form className="needs-validation">

      {facturacion && (
        <Box mt={4}>
          <Paper elevation={6}>
            <Box className="animate__animated  animate__fadeIn" py={4} px={3}>
              <AvatarTitle title="Datos de facturación" stepp="2" />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BusinessOutlined />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    label="Razón Social"
                    name="razonSocial"
                    value={razonSocial}
                    onChange={handleChangeFacturacion}
                  />
                </Grid>
                {/* alg */}
                <Grid item xs={12} md={6}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="test-select-label-cfdi">
                      Uso CFDI
                    </InputLabel>
                    <Select
                      labelId="test-select-label-cfdi"
                      label="Uso CFDI"
                      name="usosCFDI"
                      defaultValue=""
                      MenuProps={{
                        autoFocus: false,
                        disableScrollLock: false,
                      }}
                      onChange={handleChange}
                    >
                      <MenuItem disabled={true} value="">
                        --Seleccione una opción--
                      </MenuItem>
                      {usosCFDI?.map((cfdi) => (
                        <MenuItem value={cfdi.clave} key={cfdi.clave}>
                          {cfdi.usoCfdi1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="test-select-label-regimen">
                    Régimen fiscal
                    </InputLabel>
                    <Select
                      labelId="test-select-label-regimen"
                      label="Régimen fiscal"
                      name="regimenFiscal"
                      defaultValue=""
                      MenuProps={{autoFocus: false,disableScrollLock: false}}
                      onChange={handleChangeFacturacion}
                    >
                      <MenuItem disabled={true} value="">
                        --Seleccione una opción--
                      </MenuItem>
                      {regimensFiscal?.map((regimenFiscal) => (
                        <MenuItem value={regimenFiscal.value} key={regimenFiscal.value}>
                          {regimenFiscal.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DescriptionOutlined />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    label="RFC"
                    name="rfcFacturacion"
                    value={rfcFacturacion}
                    onChange={handleChangeFacturacion}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="text"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Home />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    label=" Domicilio fiscal"
                    name="domicilioFacturacion"
                    value={domicilioFacturacion}
                    onChange={handleChangeFacturacion}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MarkunreadMailboxOutlined />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    label="Código Postal"
                    name="codigoPostalFacturacion"
                    value={codigoPostalFacturacion}
                    onChange={handleChangeFacturacion}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    type="text"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationCityOutlined />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    label="Municipio"
                    name="municipioFacturacion"
                    value={municipioFacturacion}
                    onChange={handleChangeFacturacion}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    type="text"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ApartmentOutlined />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    label="Estado"
                    name="estadoFacturacion"
                    value={estadoFacturacion}
                    onChange={handleChangeFacturacion}
                    required
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      )}
    </form>
  );
};

export default FormFacuturacion;
