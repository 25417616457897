import React from "react";
import { Box, Paper ,Grid} from "@mui/material";

const ListIconsCaracteristicas = ({ rutas ,iconSize=60}) => {
  return (
    <Grid container spacing={.8}>
      {rutas.map((icono) => {
        return (
          <Grid item xs={6} md={2} key={icono}>
            <Paper elevation={4} >
              <Box p={1} display="flex" justifyContent="center">
                <img style={{  height: iconSize }} src={icono} alt={icono}/>
              </Box>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ListIconsCaracteristicas;