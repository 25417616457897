import {
actualizarCarritoLS,
addCarritoLocalStorage,
clearCarritoLocalStorage,
deteleItemCarritoLocalStorage,
editCantidadCarritoLocalStorage,
editarProductoLocalStorage,
getCarritoLocalStorage,
} from "../helper/carritoLocalStorage";
import { compare } from "../helper/validaciones";
import { carritoTypes } from "../types/types";

export const agregarCarritoAction = (producto, cantidad) => {
  return (dispatch) => {
    const carrito = addCarritoLocalStorage(producto, cantidad);
    dispatch({ type: carritoTypes.add, payload: carrito.sort(compare) });
  };
};

export const editProductoAction = (newProduct) => {
  return (dispatch) => {
    const carrito = editarProductoLocalStorage(newProduct);
    dispatch({
      type: carritoTypes.edit_producto,
      payload: carrito.sort(compare),
    });
  };
};

export const editProductoCantidadAction = (itemCode, cantidad) => {
  return (dispatch) => {
    const carrito = editCantidadCarritoLocalStorage(itemCode, cantidad);
    dispatch({
      type: carritoTypes.edit_producto_cantidad,
      payload: carrito.sort(compare),
    });
  };
};

export const deleteProductoCarritoAction = (itemCode) => {
  return (dispatch) => {
    const carrito = deteleItemCarritoLocalStorage(itemCode);
    dispatch({
      type: carritoTypes.delete_producto,
      payload: carrito.sort(compare),
    });
  };
};

export const updateAllCarritoAction = (newCarrito) => {
  return (dispatch) => {
    const oldCarrito = getCarritoLocalStorage();

    const finalCarrito = newCarrito.filter((producto) => {
    const prod = oldCarrito.find((element) => element.itemCode === producto.itemCode);
      return prod;
    });
    
    const carrito = actualizarCarritoLS(finalCarrito);
    dispatch({
      type: carritoTypes.edit_carrito,
      payload: carrito.sort(compare),
    });
  };
};

export const clearCarritoAction = () => {
  return (dispatch) => {
    const carrito = clearCarritoLocalStorage();
    dispatch({
      type: carritoTypes.delete_carrito,
      payload: carrito.sort(compare),
    });
  };
};
