import React from "react";
import currencyFormatter from "currency-formatter";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { green } from "@mui/material/colors";

const useStyle = makeStyles((theme) => ({
  price: {
    color: green[500],
    textAlign: "right",
  },
}));
const ProductoCheckOut = ({ producto, large = false , }) => {
  const classes = useStyle();
  return (
    <Box mt={2}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant={large?"h6":"caption"} fontWeight="bold">
          {`${producto.nombre} `}
        </Typography>
        <Typography variant={large?"body1":"body1"} fontWeight="bold">
          x{producto.cantidad}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="caption"
          fontWeight="bold"
          align="right"
          className={classes.price}
        >
           {currencyFormatter.format(
           (producto.precio * producto.iva + producto.precio) *
              producto.cantidad,
            {
              code: "MXN",
            }
          )}
          {producto.moneda}
        </Typography>
      </Box>
    </Box>
  );
};

export const PaqueteCheckOut = ({ nombre,cantidad,precio,large=false}) => {
  const classes = useStyle();
  return (
    <Box mt={2}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant={large?"h6":"caption"} fontWeight="bold">
          {`${nombre} `}
        </Typography>
        <Typography variant={large?"body1":"body1"} fontWeight="bold">
          x{cantidad}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="caption"
          fontWeight="bold"
          align="right"
          className={classes.price}
        >
           {currencyFormatter.format(precio,{  code: "MXN",})}
           MXN
        </Typography>
      </Box>
    </Box>
  );
};

ProductoCheckOut.propTypes = {
  producto: PropTypes.object.isRequired,
};
export default ProductoCheckOut;
