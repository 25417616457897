import { Typography, Box ,Grid,Button,Divider} from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { green } from "@mui/material/colors";
import currencyFormatter from "currency-formatter";
import Logo from "../../assets/img/LogoPegaduro.png";
import TagManager from "react-gtm-module";
const useStyles = makeStyles((theme) => ({
  link: {
    color: "inherit",
    textDecoration: "inherit",
  },
  price: {
    color: green[400],
    paddingLeft: 4,
  },
  inputCantidad: {},
}));

export const ProductoPaquete = ({ productos ,removePaquete}) => {
  const producto  = productos[0]
  const imagenes = producto.multimedia
  ? producto.multimedia.filter((mul) => mul.tipo === "Imagen")
  : [];
  const classes = useStyles();
  const removeCar =()=>{
   
    TagManager.dataLayer({
      dataLayer:{
        ecommerce: {
          products:productos.map(p=>({ name:p.nombre, quantity:p.cantidad, procuctId:p.itemCode, unityPrice:p.precio, iva:p.iva, currency:p.moneda}))
        },
        event:"remove_from_cart"}})
    removePaquete(productos.map(p=>p.itemCode))
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={5} lg={3}>
          <Box display="flex" justifyContent="center" alignItems="center" alignContent="center"
          >
            <Link
              to={`./info-producto-${producto.itemCodeHdr}`}
              className={`${classes.link}`}
              title={producto.nombre}
            >
              <img
                src={imagenes[0] ? `./${imagenes[0].urlMultimedia}` : Logo}
                alt={producto.nombre}
                style={{ maxWidth: "80%" }}
              />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} md={7} lg={9}>
          <Box paddingLeft={0} my={1}>
            <Grid container>
              <Grid item xs={12} md={8} lg={9}>
                <Box pr={2} mt={2}>
                  <Link to={`./info-producto-${producto.itemCodeHdr}`} className={classes.link} >
                    <Typography fontWeight="bold" variant="subtitle1  ">
                      {producto?.productoHdr?.nombre}
                    </Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>

            <Box sx={{marginY:1}}>
              <Typography variant="caption">
                Costo por unidad:
                <Typography variant="caption" fontWeight="bold" className={classes.price} >
                  {currencyFormatter.format(producto.precio + producto.iva * producto.precio,{  code: "USD",} )}{" "}
                  {producto.moneda}
                </Typography>
              </Typography>
            </Box>
            <Typography variant="subtitle2">Productos del paquete</Typography>
            <Grid container sx={{marginTop:1}} spacing={1}>
              {productos.map(p=>
                <Grid key={p.itemCode} item xs={6} md={4}>
                  <Typography variant="caption">{p.etiqueta} x{p.cantidad}</Typography>
                </Grid>
                )}
            </Grid>

            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center" alignContent="center">
              <Box>
                <Button color="primary" variant="text" style={{ fontSize: 12 }} onClick={removeCar }>
                  <i className="fas fa-trash-alt" style={{ marginRight: 5 }}></i>
                  Eliminar del carrito
                </Button>
              </Box>
              <Typography variant="subtitle2" fontWeight="bold" className={classes.price}>
                {currencyFormatter.format(
                     productos?.map(c=>c.cantidad).reduce((a,b) => a+b,0) *
                    (producto.precio + producto.iva * producto.precio),
                  { code: "MXN" }
                )}{" "}
                {producto.moneda}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};
