import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/LogoPegaduro.png";
import Loader from "react-loader-spinner";
import { Button, Paper, TextField, Box, Typography } from "@mui/material";
import { urlServices } from "../../helper/urlsBase";
import { Search } from "@mui/icons-material";
import ReactPixel from 'react-facebook-pixel'
const Buscador = () => {
  const [busqueda, setBusqueda] = useState({
    busqueda: "",
  });

  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  const [state, setState] = useState({
    data: null,
    loading: false,
    error: null,
  });

  useEffect(() => {
    if (busqueda.busqueda.trim() === "") {
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      if (isMounted.current) {
        setState({ data: null, loading: true, error: null });
        fetch(
          `${urlServices.URL_SERVICE_TIENDA}api/v4/Productos?res=9999&pag=0&bus=${busqueda.busqueda}&PrecioMin=1&PrecioMax=9999`
        )
          .then((resp) => resp.json())
          .then((data) => {
            if (isMounted.current) {
              console.log(data)
              ReactPixel.track('Search',busqueda?.busqueda)
              setState({
                loading: false,
                error: null,
                data,
              });
            }
          })
          .catch((e) => {
            setState({
              data: null,
              loading: false,
              error: e,
            });
          });
      }
    }, 350);
    return () => clearTimeout(delayDebounceFn);
  }, [busqueda]);

  return (
    <div
      onBlur={() => {
        setTimeout(() => {
          setBusqueda({
            busqueda: "",
          });
        }, 200);
      }}
      style={{ position: "relative" }}
    >
      {/* Input buscador */}
      <Box display="flex" alignItems="center">
        {/* <Paper
          component="form"
          sx={{ pl: "5px", display: "flex", alignItems: "center" }}
        > */}
        <TextField
          autoComplete="off"
          name="busqueda"
          type="text"
          label="Buscar producto"
          fullWidth
          size="small"
          value={busqueda.busqueda}
          onChange={(e) => {
            setBusqueda({
              ...busqueda,
              [e.target.name]: e.target.value,
            });
          }}
        />
        <Button
          size="small"
          variant="contained"
          color="primary"
          sx={{ p: "8px" }}
        >
          <Search />
        </Button>
        {/* </Paper> */}
      </Box>
      {/* contenedor resultados */}
      <Box
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 2,
        }}
      >
        {state.loading ? (
          <Paper elevation={5}>
            <Box display="flex" justifyContent="center" p={2}>
              <Loader type="TailSpin" color="#fe5000" height={50} width={50} />
            </Box>
          </Paper>
        ) : (
          busqueda.busqueda !== "" && (
            <Paper elevation={5}>
              <Box>
                {state.data?.productos.slice(0, 7).map((producto) => {
                  const imagenes = producto.multimedia
                    ? producto.multimedia.filter((mul) => mul.tipo === "Imagen")
                    : [];

                  return (
                    <Paper key={producto.itemCode} elevation={1}>
                      <Box p={1} my={1}>
                        <Link
                          to={`/info-producto-${producto.itemCode.toLowerCase()}`}
                          key={producto.itemCode}
                          style={{
                            color: "inherit",
                            textDecoration: "inherit",
                          }}
                        >
                          <Box
                            display="flex"
                            alignContent="center"
                            alignItems="center"
                          >
                            <Box>
                              <img
                                alt="producto"
                                className="img-fluid"
                                style={{ height: 70 }}
                                src={
                                  imagenes[0]
                                    ? `/${imagenes[0].urlMultimedia}`
                                    : Logo
                                }
                              />
                            </Box>
                            <Box ml={3}>
                              <Typography variant="subtitle2">
                                {producto.nombre} {producto.itemCodes}
                              </Typography>
                              <Typography variant="caption">
                                {producto.descCorta}
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                    </Paper>
                  );
                })}
              </Box>
            </Paper>
          )
        )}
      </Box>
    </div>
  );
};

export default Buscador;
