import { useEffect, useRef, useState } from "react";
import CardInfo from "../components/carrito/CardInfo";
import ListProducto from "../components/carrito/ListProducto";
import Stepper from "../components/carrito/Stepper";
import ErrorInfo from "../components/shared/ErrorInfo";

import useEnvio from "../hooks/useEnvio";
import { urlServices } from "../helper/urlsBase";
import { postConfig } from "../helper/postConfig";
import { useSelector } from "react-redux";
import { updateAllCarritoAction } from "../actions/carrito";
import { useDispatch } from "react-redux";
import MetaComponent from "../components/shared/MetaComponent";
import { Alert, Container, Grid } from "@mui/material";

const Carrito = () => {
  const { carrito } = useSelector((state) => state);
  const [error, seterror] = useState("");
  const itemCodes = useRef(carrito);

  const dispatch = useDispatch();
  useEffect(() => {
    fetch(
      `${urlServices.URL_SERVICE_TIENDA}api/v4/Productos/Carrito`,postConfig(itemCodes.current.map((c) => c.itemCode)))
      .then((response) => response.json())
      .then((data) => {
        const nuevoCarrito = data?.map((producto) => {
          let cantidad = itemCodes.current.find(
            (c) => c.itemCode === producto.itemCode
          )?.cantidad;

          if (cantidad <= producto.stock) {
            producto.cantidad = cantidad;
          } else {
            producto.cantidad = producto.stock;
            if (producto.stock <= 0) {
              seterror( "Lamentamos avisarte que algunos productos de tu carrito fueron removidos por problemas de stock");
            } else {
              seterror(
                "Lo sentimos algunas cantidades de sus productos cambiaron por problemas de stock"
              );
            }
          }
          return producto;
        });
        dispatch(
          updateAllCarritoAction(nuevoCarrito.filter((p) => p.cantidad > 0))
        );
      })
      .catch((e) => {});
  }, [dispatch]);

  const { costoEnvio } = useEnvio(carrito);

  return (
    <>
      <MetaComponent
        titulo="Carrito de compras"
        description="mi carrito de compras"
      />
      <Stepper stepp="carrito" />
      <Container>
        {error.trim() !== "" && (
          <Alert variant="filled" severity="warning">
            {error}
          </Alert>
        )}
        {carrito.length > 0 ? (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <ListProducto costoEnvio={costoEnvio} />
              </Grid>
              <Grid item xs={12} md={4} className="animate__animated animate__fadeIn" >
                <CardInfo costoEnvio={costoEnvio} />
              </Grid>
            </Grid>
          </>
        ) : (
          <ErrorInfo text="No has agregado productos al carrito" />
        )}
      </Container>
    </>
  );
};

export default Carrito;
