import { useState,useEffect,useRef } from "react";
import { urlServices } from "../helper/urlsBase";

const useValidCp = (codigoPostal,setForm) => {
  const [validCp, setValidCp] = useState(null);
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  useEffect(() => {
    const regex = /^[0-9]*$/;
    if (codigoPostal.length === 5 && regex.test(codigoPostal)) {
      fetch(
        `${urlServices.URL_SERVICE_TIENDA}api/v1/Pedidos/CP/${codigoPostal}`
      )
        .then((resp) => resp.json())
        .then((data) => {
          if (isMounted.current) {
            if (data.mensaje === "Valido") {
              setValidCp(true);
              setForm((f) => ({
                ...f,
                municipio: data.cp.response.municipio,
                estado: data.cp.response.estado,
              }));
            } else {
              setValidCp(false);
              setForm((f) => ({
                ...f,
                municipio: "",
                estado: "",
              }));
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [codigoPostal, setForm]);
  return { validCp };
};

export default useValidCp;
