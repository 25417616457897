import { setPedidoLocalStorage } from "../helper/pedidoLocalStorage";
import { pedidoTypes } from "../types/types";
import { store } from "../store/store";

export const editCarritoPedidoAction = (carrito) => {
  return (dispatch) => {
    const pedido = store.getState().pedido;
    const newPedido = setPedidoLocalStorage({
      ...pedido,
      carrito,
    });
    dispatch({
      type: pedidoTypes.editar_detalle,
      payload: newPedido,
    });
  };
};

export const editInfoPedidoAction = (formulario, formularioFacturacion) => {
  return (dispatch) => {
    const pedido = store.getState().pedido;
    const newPedido = setPedidoLocalStorage({
      ...pedido,
      formulario,
      formularioFacturacion,
      validForm: true,
    });

    dispatch({ type: pedidoTypes.editar_info_pedido, payload: newPedido });
  };
};

export const editCostoEnvioAction = (costoEnvio) => {
  return (dispatch) => {
    const pedido = store.getState().pedido;
    const newPedido = setPedidoLocalStorage({
      ...pedido,
      costoEnvio,
    });
    dispatch({ type: pedidoTypes.edit_costo_envio, payload: newPedido });
  };
};

export const invalidFormAction = () => {
  return (dispatch) => {
    const pedido = store.getState().pedido;
    const newPedido = setPedidoLocalStorage({
      ...pedido,
      validForm: false,
    });
    dispatch({ type: pedidoTypes.editar_info_pedido, payload: newPedido });
  };
};
