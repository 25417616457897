import { CheckCircleOutline } from "@mui/icons-material";
import { Container, Box, Typography } from "@mui/material";
import React from "react";
const OrdenSucces = ({ orden }) => {
  return (
    <>
      <Container>
        <Box display="flex" justifyContent="center">
          <CheckCircleOutline style={{ fontSize: 150, color: "#4a934a" }} />
        </Box>
        <Typography align="center" variant="h6">
          Hola,{" "}
          {`${orden?.pedido?.nombreCliente} ${orden.pedido.apellidosCliente} `}
          <br />
          Gracias por tu compra
        </Typography>
      </Container>
      <hr />
    </>
  );
};

export default OrdenSucces;
