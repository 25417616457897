import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useState } from "react";
import { Button, Grid, Box, Typography, TextField, Divider, useTheme, useMediaQuery, Snackbar, Alert,} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { green, grey } from "@mui/material/colors";
import currencyFormatter from "currency-formatter";
import TagManager from 'react-gtm-module'
import { validateNotDecimalNumber } from "../../helper/validaciones";
import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import { editProductoCantidadAction } from "../../actions/carrito";
import Logo from "../../assets/img/LogoPegaduro.png";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "inherit",
    textDecoration: "inherit",
  },
  price: {
    color: green[400],
    paddingLeft: 4,
  },
  lastPrice :{
    color: grey[400],
    textDecoration: "line-through",
    paddingLeft: 4,
  },
  inputCantidad: {},
}));

const ProductoCarrito = ({ producto, removeItem, costoEnvio }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const dispatch = useDispatch();

  const envio = costoEnvio.filter((c) => c.empaque === producto.empaque);
  const cobroEnvio = envio[0] ? envio[0].costoEnvio : 0;
  const [cantidad, setCantidad] = useState({
    cantidad: producto.cantidad,
  });

  useEffect(() => {
    setCantidad({
      cantidad: producto.cantidad,
    });
  }, [producto]);
  const handleChange = ({ target }) => {
    if (target.value < 0) {
      return;
    }
    if (target.value > producto.stock) {
      setError(true);
      return;
    }
    if (target.value > 0) {
      dispatch(editProductoCantidadAction(producto.itemCode, target.value));
    }
    setCantidad({
      ...cantidad,
      [target.name]: target.value,
    });
  };

  const imagenes = producto.multimedia
    ? producto.multimedia.filter((mul) => mul.tipo === "Imagen")
    : [];

  const removeProduct = () => {
    removeItem(producto.itemCode);
    TagManager.dataLayer({
      dataLayer:{
        ecommerce: {
          products:[ {
            name:producto.nombre,
            quantity:cantidad,
            procuctId:producto.itemCode,
            unityPrice:producto.precio,
            iva:producto.iva,
            currency:producto.moneda,
          }]
        },
        event:"remove_from_cart"
      } 
    })
  };

  const handleFocusLost = () => {
    if (cantidad.cantidad <= 0) {
      setCantidad({
        ...cantidad,
        cantidad: 1,
      });
    } else {
      editProductoCantidadAction(producto.itemCode, cantidad.cantidad);
    }
  };

  const addOne = () => {
    if (producto.cantidad < producto.stock) {
      dispatch(
        editProductoCantidadAction(producto.itemCode, producto.cantidad + 1)
      );
    } else {
      setError(true);
    }
  };
  const subOne = () => {
    if (producto.cantidad > 1) {
      dispatch(
        editProductoCantidadAction(producto.itemCode, producto.cantidad - 1)
      );
    }
  };
  const [error, setError] = useState(false);

  const handleClose = () => {
    setError(false);
  };
  return (
    <>
      <Snackbar open={error} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" variant="filled">
          Máximo Stock {producto.stock}
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid item xs={12} md={5} lg={3}>
          <Box display="flex" justifyContent="center" alignItems="center" alignContent="center">
            <Link to={`./info-producto-${producto.itemCodeHdr}?itemCode=${producto.itemCode}`} className={`${classes.link}`} title={producto.nombre} >
              <img src={imagenes[0] ? `./${imagenes[0].urlMultimedia}` : Logo} alt={producto.nombre} style={{ maxWidth: "80%" }}/>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} md={7} lg={9}>
          <Box paddingLeft={0} my={1}>
            <Grid container>
              <Grid item xs={12} md={8} lg={9}>
                <Box pr={2} mt={2}>
                  <Link to={`./info-producto-${producto.itemCodeHdr}?itemCode=${producto.itemCode}`} className={classes.link}>
                    <Typography fontWeight="bold" variant="subtitle1  ">
                      {producto.nombre}
                    </Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>
    

            <Box mt={2}>
              <Typography variant="caption">

                {
                  producto.descuento > 0 ?
                  (
                      <>
              Precio anterior:  
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  className={classes.lastPrice}
                >
                  {currencyFormatter.format(
                    producto.precioSinDescuento + producto.iva * producto.precioSinDescuento,
                    {
                      code: "USD",
                    }
                  )}
                  {producto.moneda}
                </Typography>
                      <br />
                Costo por unidad:
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  className={classes.price}
                >
                  {currencyFormatter.format(
                    (producto.precio + producto.iva * producto.precio),
                    {
                      code: "USD",
                    }
                  )}
                  {producto.moneda}
                </Typography>
                      </>
                  ):
                  (
                    <>
                    Costo por unidad:
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  className={classes.price}
                >
                  {currencyFormatter.format(
                    (producto.precio + producto.iva * producto.precio) ,
                    {
                      code: "USD",
                    }
                  )}
                  {producto.moneda}
                </Typography></>
                  )
                }

                


              </Typography>
            </Box>
            <Box my={2} display="flex">
              <TextField
                autoComplete="off"
                type="number"
                size="small"
                label="cantidad"
                sx={{ width: matches ? "100%" : "25%" }}
                fullWidth={matches ? true : false}
                inputProps={{ style: {} }}
                name="cantidad"
                onBlur={handleFocusLost}
                className={classes.inputCantidad}
                onChange={handleChange}
                onKeyPress={validateNotDecimalNumber}
                value={cantidad.cantidad}
              />
              {isMobile && (
                <Box display="flex">
                  <Button fullWidth onClick={subOne}>
                    <RemoveOutlined />
                  </Button>
                  <Button fullWidth>
                    <AddOutlined onClick={addOne} />
                  </Button>
                </Box>
              )}
            </Box>

            {producto.costoEnvios &&
              producto.costoEnvios > 1 &&
              cantidad.cantidad < producto.costoEnvios &&
              cobroEnvio > 0 && (
                <Box mt={2}>
                  <Typography color="primary" variant="subtitle2">
                    <i className="fas fa-tags"></i> Envío gratis a partir de{" "}
                    {producto.costoEnvios}
                  </Typography>
                </Box>
              )}

            <Box
              mt={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              alignContent="center"
            >
              <Box>
                <Button onClick={removeProduct} color="primary" variant="text" style={{ fontSize: 12 }}>
                  <i className="fas fa-trash-alt" style={{ marginRight: 5 }}></i>
                  Eliminar del carrito
                </Button>
              </Box>
              <Typography variant="subtitle2" fontWeight="bold" className={classes.price}>
                {currencyFormatter.format(cantidad.cantidad *(producto.precio + producto.iva * producto.precio),{  code: "MXN",})}
                {producto.moneda}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default ProductoCarrito;
