import React,{useState} from "react";
import {Grid,Dialog,Slide,DialogContent,Box,DialogActions,Button, Typography, IconButton, DialogTitle} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import {makeStyles} from '@mui/styles'
import {SentimentDissatisfiedOutlined} from '@mui/icons-material'
 
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(() => ({
  paper: { minWidth: "35%" },
}));


export const ModalPaquete = ({ combo, isOpen, handleClose,cantidadPaquete ,addPaquetecarrito,productHdr}) => {
  
  const [productos, setProductos] = useState([])
  const classess = useStyles()
  const addProduct = (product,cantidad)=> { 
     let producto  = productos.find(p=>p.itemCode === product.itemCode);
     if(!producto){
      producto = product
      producto.cantidad = cantidad
     } 
     else{
      producto.cantidad+=cantidad
     }  
     if(producto.cantidad>producto.stock)
        return
     setProductos(productosb => { 
      const filteredPaquete = productosb.filter(p=>p.itemCode !== producto.itemCode)
      filteredPaquete.push(producto)
      return filteredPaquete
     })
  }
  
  const deleteProduct =(product,cantidad)=> { 
    let producto  = productos.find(p=>p.itemCode === product.itemCode);
    if(!producto){
     return
    }
    const newCantidad = producto.cantidad - cantidad
    if(newCantidad === 0){
      setProductos(productosb => { 
        const filteredPaquete = productosb.filter(p=>p.itemCode !== producto.itemCode)
        
        return filteredPaquete
      })
    }
    else{
      setProductos(productosb => {
        const filteredPaquete = productosb.filter(p=>p.itemCode !== producto.itemCode)
        producto.cantidad = newCantidad
        filteredPaquete.push(producto)
        return filteredPaquete
      }) 
    }
  } 

   const totalProductos =  productos.map(p=>p.cantidad).reduce((a,b)=>a+b,0)
  //  const proximoPaquete = Math.floor(totalProductos/cantidadPaquete)+1

  return (
    <Dialog open={isOpen} onClose={handleClose} TransitionComponent={Transition} keepMounted aria-describedby="alert-dialog-slide-description" classes={{ paper: classess.paper }} >
      <DialogTitle >Arma tu propio paquete!</DialogTitle>
      <DialogContent >
        <Box  sx={{marginTop:1}}>
           <Typography variant="subtitle2" color={cantidadPaquete>totalProductos?"error":"green"} >{cantidadPaquete>totalProductos?`Arma tu propio paquete con ${cantidadPaquete} piezas mínimo`
                :`Felicidades completaste tu paquete, a partir de este momento puedes agregar las unidades que requieras`}</Typography> 
          <Typography variant="caption"  >{productHdr?.recomendaciones}</Typography> 
          <Grid container spacing={3} sx={{marginTop:.2}}>
            {combo?.map((producto) =>{
              const productoState  = productos.find(p=> p.itemCode === producto.itemCode)
              return (
                <Grid key={producto.itemCode} item xs={12} md={6} lg={4} sx={{marginTop:2}}  >
                  <Box sx={{display:"flex"}}>
                    <Box>
                      <Box sx={{width:38,height:38,bgcolor:`#${producto.hexColor}`,borderRadius:"50%"}}/>
                    </Box>
                    <Box sx={{marginLeft:2}}> 
                      <Box>
                       <Typography variant="body2">{producto.etiqueta} </Typography>
                      </Box>
                      {producto.stock<=0?
                      <>
                      <Box sx={{width:"100%",justifyContent:"center",display:"flex"}}>
                        <Typography textAlign={"center"} variant="caption" >{"Sin stock"}</Typography>
                      </Box>
                      <Box sx={{display:"flex",justifyContent:"center"}}>
                        <SentimentDissatisfiedOutlined fontSize="12"/>
                      </Box>
                      </>
                     :
                      <Box sx={{display:"flex",alignItems:"center"}}>
                        <IconButton sx={{borderRadius:"100%"}} size="small" onClick={()=>deleteProduct(producto,1)}><Remove fontSize="inherit"/></IconButton> 
                          <Typography variant="subtitle2">{productoState?productoState.cantidad:0}</Typography>
                        <IconButton sx={{borderRadius:"100%"}} size="small" onClick={()=>addProduct(producto,1)}><Add fontSize="inherit"/></IconButton>
                      </Box>}
                   
                    </Box>
                  </Box>
                  <Box>
                    {/* <Typography variant="caption">Stock: {producto.stock}</Typography> */}
                  </Box>
                </Grid>
                )
            } )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>{"Cancelar"}</Button>
        <Button variant="text" onClick={()=>{
          const isAdded =  addPaquetecarrito(productos)
          if(isAdded)
            setProductos([])
        }}>{"Agregar"}</Button>
      </DialogActions>
    </Dialog>
  );
};




