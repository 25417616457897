import { useLocation } from "react-router-dom";

import MetaComponent from "../components/shared/MetaComponent";
import ReseteoPasswordAction from "../components/verificacion/ReseteoPasswordAction";
import VerificarCorreoAction from "../components/verificacion/VerificarCorreoAction";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const UserActionPage = () => {
  const query = useQuery();

  // Get the one-time code from the query parameter.
  const actionCode = query.get("oobCode");
  const mode = query.get("mode");

  return (
    <>
      <MetaComponent
        titulo="Verificación de correo"
        description="Verificar correo del usuario registrado"
      />
      {mode === "resetPassword" ? (
        <ReseteoPasswordAction actionCode={actionCode} />
      ) : (
        <VerificarCorreoAction actionCode={actionCode} />
      )}
    </>
  );
};

export default UserActionPage;
