import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useQueryParams = () => {
  const query = useQuery();
  return{
    query
  }
};

export default useQueryParams;
