import React, { useEffect, useState } from "react";
import {Paper,Box,TextField,Grid,Typography,Button,Alert} from "@mui/material";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Copyright from "../components/shared/Copyright";
import useForm from "../hooks/useForm";
import GoogleButton from "react-google-button";
import {loginUserPasswordAction,singInGoogleAction,enviarEmailVerificacion} from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { LockOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      padding: 100,
    },
  },
  iconAvatar: {
    fontSize: 150,
    marginBottom: 15,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    outline: "none",
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
}));

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { form, handleChange } = useForm({
    email: "",
    password: "",
  });
  const { email, password } = form;
  const { auth } = useSelector((state) => state);
  const { token, loading } = auth;
  const [error, setError] = useState({ error: false, mensaje: null, tipo: "" });
  const [verificado, setverificado] = useState(false);
  useEffect(() => {
    if (token) {
      history.push("/");
    }
  }, [token, history]);

  const handleClickRegistroGoogle = (e) => {
    e.preventDefault();
    dispatch(singInGoogleAction());
  };

  useEffect(() => {
    if (auth.error === "Usuario no verificado") {
      setverificado(true);
    }
  }, [auth]);
  const handleLogIn = () => {
    dispatch(loginUserPasswordAction(email, password));
  };
  const handleSendEmail = async () => {
    const correo = await enviarEmailVerificacion();
    if (!correo.enviado) {
      setError({ error: true, mensaje: correo.mensaje, tipo: "" });

      setTimeout(() => {
        setError({ error: false, mensaje: correo.mensaje, tipo: "" });
      }, 6000);
    } else {
      setError({ error: false, mensaje: null, tipo: "" });
    }
  };

  return (
    <>
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={2} square>
        <div className={classes.paper}>
          <LockOutlined
            className={classes.iconAvatar}
            style={{ fontSize: 100 }}
            color="primary"
          />
          <Typography component="h1" variant="h4">
            Tienda Pegaduro
          </Typography>

          <form className={classes.form} onSubmit={handleLogIn} noValidate>
            {(error?.error || auth.error) && !verificado ? (
              <Box mt={2} mb={2}>
                <Alert variant="filled" severity="error">
                  {error.mensaje ? error.mensaje : auth.error}
                </Alert>
              </Box>
            ) : null}
            {verificado && (
              <Box mt={2} mb={2} className="animate__animated animate__fadeIn">
                <Alert variant="filled" severity="success">
                  El correo {email} no ha sido verificado por favor
                </Alert>
                <Box mt={1}>
                  <Button
                    onClick={handleSendEmail}
                    component="span"
                    fullWidth
                    color="primary"
                  >
                    reenviar correo
                  </Button>
                </Box>
              </Box>
            )}
            <TextField
              variant="standard"
              margin="normal"
              fullWidth
              id="email"
              label="Correo Electrónico"
              name="email"
              onChange={handleChange}
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              onChange={handleChange}
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recordarme"
            /> */}
            {loading ? (
              <Box display="flex" justifyContent="center" mt={5} mb={5}>
                <Loader
                  type="TailSpin"
                  color="#fe5000"
                  height={50}
                  width={50}
                />
              </Box>
            ) : (
              <Box mt={4}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleLogIn}
                  className={classes.submit}
                >
                  Iniciar Sesión
                </Button>
                <Box mt={2} mb={2} display="flex" justifyContent="flex-end">
                  <GoogleButton
                    type="light"
                    style={{ textAlign: "left" }}
                    label="Ingresa con Google"
                    onClick={handleClickRegistroGoogle}
                  />
                </Box>
              </Box>
            )}
          </form>
          <Box display="flex" justifyContent="flex-end"></Box>
          <Grid container>
            <Grid item xs>
              <RouterLink
                to="/auth/reset-password"
                variant="body2"
                className={classes.link}
              >
                Olvidaste tu contraseña?
              </RouterLink>
            </Grid>
            <Grid item>
              <RouterLink to="/auth/registro" className={classes.link}>
                {"No tienes cuenta? Registrate"}
              </RouterLink>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </>
  );
};

export default Login;
