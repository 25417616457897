import { Avatar, Box, Grid, Paper, Rating ,Typography} from "@mui/material";

const Comentario = ({ comentario }) => {
    return (
      <Paper
        elevation={4}
        square={true}
        className="animate__animated animate__fadeIn"
      >
        <Box p={3}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Avatar
                sx={{
                  background: "#FE5000",
                  width: 30,
                  height: 30,
                  fontSize: 14,
                }}
              >
                {comentario?.cliente[0]}
              </Avatar>
  
              <Box mt={2}>
                <Typography variant="subtitle2" fontWeight="bold">
                  {comentario?.cliente}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box display="flex" flexDirection="row-reverse">
                <Rating
                  precision={0.5}
                  readOnly
                  value={comentario?.calificacion}
                />
              </Box>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography variant="body2">{comentario?.comentario}</Typography>
          </Box>
        </Box>
      </Paper>
    );
  };
  export default Comentario