import React, { useEffect } from "react"
import { useLocation } from "react-router-dom";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { Container, Box } from "@mui/material";
import TagManager from 'react-gtm-module'
import Stepper from "../components/carrito/Stepper";
import CardProductoOrden from "../components/ordencompleda/CardProductoOrden";
import { urlServices } from "../helper/urlsBase";
import { useFetch } from "../hooks/useFetch";
import { clearCarritoAction } from "../actions/carrito";
import NotFound from "../pages/NotFound";
import MetaComponent from "../components/shared/MetaComponent";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const OrdenCompletada = () => {
  const query = useQuery();
  const orderId = query.get("orderId");
  const dispatch = useDispatch();
  const { data: pedido, loading } = useFetch(
    `${urlServices.URL_SERVICE_TIENDA}api/v1/Pedidos/Cargo/${orderId}`
  );
  //EEventos del inicio del carrito
  useEffect(() => {
    if (pedido) {
      if (!pedido.cargo.error) {
        dispatch(clearCarritoAction());
      }
    }
  }, [pedido, dispatch]);
  useEffect(() => {
      if(pedido){
        TagManager.dataLayer({
          
          dataLayer:{
            
            ecommerce: {
              products:pedido?.pedido?.productos,
              idOrder:pedido?.pedido?.id,
              total:pedido?.pedido?.total,
              subtotal:pedido?.pedido?.subtotal,
              direccion:pedido?.pedido?.direccion
            },
            event:"purchase",

            }})

          try{

            handleConversion()
          }
          catch(error){
            console.error("ERROR EN EL SEND TO GTM")
          }

          
      }
  }, [pedido])

  const handleConversion = () => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-11035100076/pScvCKj_qbAZEKyH-Y0p',
        value: pedido?.pedido?.total,
        currency: 'MXN',
        transaction_id: pedido?.pedido?.id
      });
    }
  };
  

  
  return (
    <>
      <MetaComponent
        titulo="Orden completada"
        description="La orden se ha completado"
      />

      {loading && (
        <Box display="flex" justifyContent="center" p={20}>
          <Loader type="ThreeDots" color="#fe5000" height={150} width={150} />
        </Box>
      )}
      {pedido && !loading ? (
        <>
          <Stepper stepp="ordencompletada" />
          <Container>
            <Box display="flex" justifyContent="center">
              <Box sx={{ width: 800 }}>
                <CardProductoOrden orden={pedido} />
              </Box>
            </Box>
          </Container>
        </>
      ) : (
        !loading && <NotFound />
      )}
    </>
  );
};

export default OrdenCompletada;
