import { Link as RouterLink } from "react-router-dom";
import {Typography } from "@mui/material";
import React from "react";

const Copyright = () => {
  return (
    < >
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright© " }

        <RouterLink to="/" style={{color:"#fe5000"}} >Pegaduro </RouterLink>

        {new Date().getFullYear()}
        {"."}
      </Typography>{" "}
    </>
  );
};

export default Copyright;
