import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { validateLenght } from "../../helper/validaciones";
const ComentariosPedido = ({ handleChange, form }) => {
  const { comentarios } = form;
  return (
    <Box mt={5}>
      <TextField
        id="outlined-multiline-static"
        label="Comentarios"
        multiline
        fullWidth
        variant="standard"
        rows={5}
        onChange={handleChange}
        name="comentarios"
        value={comentarios}
        onKeyPress={validateLenght}
        placeholder="Alguna nota en su orden de compra?"
      />
    </Box>
  );
};

export default ComentariosPedido;
