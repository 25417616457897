import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import ProductoTest from "./ProductoTest";

const ListProducto = ({ data }) => {
  return (
    <Box justifyContent="center">
      <Grid container spacing={1}>
        {data != null &&
          data.map((producto) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={producto.itemCode}>
              <ProductoTest producto={producto} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

ListProducto.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ListProducto;
