export const postConfig = (data) => ({
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
  body: JSON.stringify(data), // body data type must match "Content-Type" header
});

export const postConfigAuth = (data, token) => ({
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
    "FireAuth": token,
  },
  body: JSON.stringify(data), // body data type must match "Content-Type" header
});


export const getConfigAuth = token=>({
  method: "GET", // *GET, POST, PUT, DELETE, etc.
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
    "FireAuth": token,
  },
})

export const deleteConfigAuth = token=>({
  method: "DELETE", // *GET, POST, PUT, DELETE, etc.
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
    "FireAuth": token,
  },
})

export const putConfigAuth = (token,body)=>({
  method: "PUT", // *GET, POST, PUT, DELETE, etc.
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
    "FireAuth": token,
  },
  body:JSON.stringify(body)
})



export const postBearToken = (token,body)=>({
  method: "PUT", // *GET, POST, PUT, DELETE, etc.
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`,
  },
  body:JSON.stringify(body)
})