import { useState, useEffect } from "react";
import { firebase } from "../../firebase/config";
import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "@firebase/auth";
import {
  Typography,
  TextField,
  Box,
  Button,
  Paper,
  Container,
  Alert,
  Tooltip,
} from "@mui/material";
import {
  ArrowRightAltOutlined,
  CheckCircleOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import validator from "validator";
import Loader from "react-loader-spinner";
import useForm from "../../hooks/useForm";
import { useHistory } from "react-router-dom";
const ReseteoPasswordAction = ({ actionCode }) => {
  const { form, handleChange, clearForm } = useForm({
    password: "",
    confirmPassword: "",
  });
  const history = useHistory();
  const [isValidLink, setIsValidLink] = useState(true);
  const [succes, setSuccess] = useState(false);
  const [error, setError] = useState({
    error: false,
    mensaje: "",
  });
  const [loading, setLoading] = useState(false);
  const { password, confirmPassword } = form;
  useEffect(() => {
    const auth = getAuth(firebase);
    setLoading(true);
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        setLoading(false);
        //setIsValidLink(true);
      })
      .catch((e) => {
        console.log(e.code);
        setLoading(false);
        setIsValidLink(false);
      });
  }, [actionCode]);

  const handleChangePassword = () => {
    if (validator.isStrongPassword(password)) {
      if (password === confirmPassword) {
        const auth = getAuth(firebase);
        confirmPasswordReset(auth, actionCode, password)
          .then((result) => setSuccess(true))
          .catch((error) =>
            setError({
              error: error,
              mensaje:
                "Error al cambiar la contraseña por favor inténtelo más tardeos.",
            })
          );
        clearForm();
      } else {
        setError({ error: true, mensaje: "Las contraseñas deben coincidir" });
      }
    } else {
      setError({
        error: true,
        mensaje:
          "La contraseña debe de contener al menos 8 caracteres, una letra mayúscula, una minúscula, un numero y un caracter especial",
      });
    }
  };
  return (
    <Container>
      {loading ? (
        <Box display="flex" justifyContent="center" mt={10}>
          <Loader type="ThreeDots" color="#fe5000" height={200} width={200} />
        </Box>
      ) : isValidLink ? (
        <Box display="flex" justifyContent="center">
          <Box style={{ width: 800 }}>
            <Paper>
              <Box p={5} mt={10}>
                {!succes ? (
                  <>
                    <Box mt={2}>
                      <Typography variant="h5">Cambio de contraseña</Typography>
                    </Box>
                    <Typography vartian="p">
                      Ingrese su nueva contraseña por favor
                    </Typography>
                    {error.error && (
                      <Alert color="error">{error.mensaje}</Alert>
                    )}
                    <Box mt={3}>
                      <Tooltip title="La contraseña debe de contener al menos 8 caracteres, una letra mayúscula, una minúscula, un numero y un caracter especial">
                        <TextField
                          variant="standard"
                          name="password"
                          type="password"
                          fullWidth
                          onChange={handleChange}
                          label="Nueva contraseña"
                          value={password}
                        />
                      </Tooltip>
                    </Box>
                    <Box mt={3}>
                      <Tooltip title="Las contraseñas deben coincidir">
                        <TextField
                          variant="standard"
                          name="confirmPassword"
                          type="password"
                          fullWidth
                          onChange={handleChange}
                          label="Confirmar contraseña"
                          value={confirmPassword}
                        />
                      </Tooltip>
                    </Box>
                    <Box mt={3}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleChangePassword}
                      >
                        Cambiar contraseña
                      </Button>
                    </Box>
                  </>
                ) : (
                  <Box display="flex" justifyContent="center">
                    <Box>
                      <Typography variant="h6">
                        Se realizó con éxito el cambio de contraseña
                      </Typography>
                      <Box mt={3} display="flex" justifyContent="center">
                        <CheckCircleOutlined
                          style={{ fontSize: 120, color: "#4a934a" }}
                        />
                      </Box>
                      <Box mt={2}>
                        <Button
                          component="span"
                          color="primary"
                          variant="text"
                          fullWidth
                          onClick={() => {
                            history.push("/auth/login");
                          }}
                        >
                          {" "}
                          Ingresa <ArrowRightAltOutlined />
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Paper>
          </Box>
        </Box>
      ) : (
        <Container>
          <Box display="flex" justifyContent="center">
            <Box my={20}>
              <Typography variant="h5" align="center">
                <WarningOutlined color="primary" style={{ fontSize: 150 }} />
              </Typography>
              <Typography variant="h5" align="center" fontWeight="bold">
                El enlace es inválido o ya fue utilizado
              </Typography>
            </Box>
          </Box>
        </Container>
      )}
    </Container>
  );
};

export default ReseteoPasswordAction;
