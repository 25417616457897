import { initializeApp } from 'firebase/app';
require('firebase/auth')
const firebaseConfig = {
  apiKey: "AIzaSyD_iHwOrn3XQPhQ3iCLIdAuF-XygdPEOj0",
  authDomain: "tienda-pegaduro.firebaseapp.com",
  projectId: "tienda-pegaduro",
  storageBucket: "tienda-pegaduro.appspot.com",
  messagingSenderId: "812968945017",
  appId: "1:812968945017:web:6d9ccf19b6b4990625bba8",
  measurementId: "G-2SYC5PSFDV",
};

const firebase  =initializeApp(firebaseConfig);


export {  firebase };
