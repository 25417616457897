import { encryptStorage } from "./localStorage";

const pedidoNameLS = {
  name: "pdpd",
};

export const setPedidoLocalStorage = (newPedido) => {
  encryptStorage.setItem(pedidoNameLS.name, newPedido);
  return newPedido;
};

export const getPedidoLocalStorage = () => {
  const pedido = encryptStorage.getItem(pedidoNameLS.name);
  if (pedido) {
    return pedido;
  }
  return {
    formulario: null,
    formularioFacturacion: null,
    carrito: [],
    validForm: false,
    costoPedido: 0,
  };
};
