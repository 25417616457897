import { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Button,
  CardActions,
} from "@mui/material";
import { CheckCircleOutline, PlaceOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { deleteDireccionAction } from "../../actions/direcciones";
import ModalDireccion from "../direcciones/ModalAddDireccion";

const Direccion = ({ direccion, token, handleSelectDireccion }) => {
  const dispatch = useDispatch();
  const editDireccion = () => {
    setIsOpenModal(true);
  };

  const [isOpenModal, setIsOpenModal] = useState(false);
  const deletDirrecion = () => {
    window.scrollTo(0, 0);
    dispatch(deleteDireccionAction(direccion.id, token));
  };
  return (
    <>
      <ModalDireccion
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        token={token}
        direccion={direccion}
      />
      <Box my={2}>
        <Card elevation={3}>
          <CardActionArea onClick={() => handleSelectDireccion(direccion)}>
            <CardContent>
              <Box my={2} mx={2}>
                <Box display="flex"  justifyContent="space-between" mt={0.4}>
                  <Typography variant="subtitle1" alignItems="center" alignContent="center" color="primary">
                    {direccion.alias} <PlaceOutlined color="primary" style={{marginLeft:5}} />
                  </Typography>

                  {direccion.selected && (
                    <CheckCircleOutline
                      style={{ color: "#38a839", fontSize: 30 }}
                    />
                  )}
                </Box>
                <Box mt={0.4}>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Direccion de entrega
                  </Typography>

                  <Typography variant="body2">
                    {`${direccion.calle}
                    ${direccion.numeroExterior} ${direccion.colonia}
                    ${direccion.numeroInterior}, C.P. ${direccion.codigoPostal}
                    ${direccion.municipio}, ${direccion.estado}`}
                  </Typography>
                </Box>

                <Box mt={0.4}>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Persona encargada de la dirección
                  </Typography>
                  <Typography variant="body2">
                    {`${direccion.nombre} ${direccion.apellidos}`}
                  </Typography>
                </Box>
                {direccion.comentarios && (
                  <Box mt={0.4}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Comentarios de entrega
                    </Typography>
                    <Typography variant="body2">
                      {direccion.comentarios}
                    </Typography>
                  </Box>
                )}
              </Box>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button
              onClick={editDireccion}
              fullWidth
              component="div"
              color="primary"
              size="small"
            >
              Editar
            </Button>

            <Button
              onClick={deletDirrecion}
              fullWidth
              component="div"
              color="primary"
              size="small"
            >
              Eliminar
            </Button>
          </CardActions>
        </Card>
      </Box>
    </>
  );
};

export default Direccion;
