import * as React from "react";
import {Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,Grid,Slide,Typography,Box,} from "@mui/material";
import { Link } from "react-router-dom";
import { ShoppingCartOutlined } from "@mui/icons-material";
import ListIconsCaracteristicas from "./ListIconsCaracteristicas";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ModalProductoRecomendado = ({open,handleClose,producto,addProducto,cantidad,
}) => {
  //   dispatch(
  //     agregarCarritoAction(
  //       producto?.productoPaquete?.producto,
  //       parseInt(producto?.productoPaquete?.cantidad * cantidad, 10)
  //     )
  //   );

  const imagen = producto?.multimedia.filter((mul) => mul.tipo === "Imagen")[0];
  const url = imagen?.urlBase + imagen?.urlMultimedia;

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Suele comprarse junto con..."}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={7} md={4}>
              <Box display="flex" justifyContent="center">
              <img src={url} width="100%" alt={producto.nombre} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box my={2}>
                <Typography
                  component={Link}
                  to={`/info-producto-${producto.itemCodeHdr}`}
                  color="primary"
                  variant="subtitle1"
                  style={{ textDecoration: "inherit" }}
                >
                  {producto?.nombre}
                </Typography>
              </Box>
              <Box my={2}>
                <Typography variant="caption">{producto?.descCorta}</Typography>
              </Box>
              <Box mt={1}>
                <ListIconsCaracteristicas
                  iconSize={50}
                  rutas={producto.iconosCaracterizticas?.map(
                    (caract) => caract.url
                  )}
                />
              </Box>
              <Box mt={2} ml={1}>
                {producto.caracterizticas?.map((c) => {
                  if (c.tipo === "lista") {
                    return (
                      <div key={c.id}>
                        <Typography variant="caption" fontWeight="bold">
                          {c.titulo}
                        </Typography>
                        <ul style={{padding:0     }}>
                          {c?.detalle.map((detalle) => (
                            <li my={1} key={detalle.id}>
                              <Box>
                                <Typography variant="caption">
                                  {detalle.descripcion}
                                </Typography>
                              </Box>
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </Box>
            </Grid>
          </Grid>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<ShoppingCartOutlined />}
            onClick={() => addProducto(producto, cantidad)}
          >
            Agregar al carrito
          </Button>
          <Button onClick={handleClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalProductoRecomendado;
