import { Typography, Box } from "@mui/material";
import { green } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import React from "react";
import currencyFormatter from "currency-formatter";

const useStyle = makeStyles((theme) => ({
  price: {
    color: green[500],
  },
}));
const PrecioCard = ({ title, price ,large=false}) => {
  const classes = useStyle();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" my={1} >
      <Typography variant={large?"subtitle1":"caption"} fontWeight="bold">
        {title}
      </Typography>
      <Typography variant={large?"body1":"caption"} fontWeight="bold" className={classes.price}>
        {currencyFormatter.format(price, {
          code: "MXN",
        })}
        MXN
      </Typography>
    </Box>
  );
};

export default PrecioCard;
