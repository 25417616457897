import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const NoAuthComent = () => {
    return (
      <Box mt={5}>
        <Typography textAlign="center" varitant="subtitle1  ">
          Para dejarnos tu opinion es necesario que estes logeado
        </Typography>
        <Box mt={3} display="flex" justifyContent="center">
          <Typography variant="caption">
            No tienes cuenta?{" "}
            <Link to="/auth/registro" style={{ color: "#fe5000" }}>
              {" Registrate"}
            </Link>{" "}
            o si ya estas registrado{" "}
            <Link to="/auth/login" style={{ color: "#fe5000" }}>
              {"ingresa"}
            </Link>
          </Typography>
        </Box>
      </Box>
    );
  };
  
  export default NoAuthComent