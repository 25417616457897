import { useState, useEffect, useRef } from "react";
import { getConfigAuth } from "../helper/postConfig";

export const useFetchAuth = (url, auth) => {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  const [state, setState] = useState({
    data: null,
    loading: true,
    error: null,
  });

  useEffect(() => {
    if (auth) {
      setState({ data: null, loading: true, error: null });
      fetch(url, getConfigAuth(auth))
        .then((resp) => resp.json())
        .then((data) => {
          if (isMounted.current) {
            setState({
              loading: false,
              error: null,
              data,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          setState({
            data: null,
            loading: false,
            error: e,
          });
        });
    }
  }, [url, auth]);

  return {
    ...state,
    setState,
  };
};
