import { encryptStorage } from "./localStorage";

export const localCarrito = {
  name: "lafmdsfr",
};
export const addCarritoLocalStorage = (newProduct, cantidad) => {
  try {
    let carrito = encryptStorage.getItem(localCarrito.name);
    if (carrito === undefined || carrito === null) {
      carrito = [];
    }
    const productoFind = carrito.find(
      (producto) => producto.itemCode === newProduct.itemCode
    );
    let newCarrito = [];
    if (productoFind !== undefined) {
      newCarrito = carrito.filter(
        (producto) => producto.itemCode !== newProduct.itemCode
      );
      let cantidadAntigua = productoFind.cantidad;
      productoFind.cantidad = parseInt(cantidadAntigua) + parseInt(cantidad);
      if (productoFind.cantidad > productoFind.stock) {
        productoFind.cantidad = productoFind.stock;
      }
      newCarrito.push(productoFind);
    } else {
      newCarrito = [...carrito, { ...newProduct, cantidad }];
    }
    encryptStorage.setItem(localCarrito.name, newCarrito);

    return encryptStorage.getItem(localCarrito.name);
  } catch (e) {
    console.log(e);
    encryptStorage.setItem(localCarrito.name, []);
    return [];
  }
};

export const getCarritoLocalStorage = () => {
  try {
    let carrito = encryptStorage.getItem(localCarrito.name);
    if (carrito === undefined || carrito === null) {
      carrito = [];
    }
    return carrito;
  } catch (e) {
    encryptStorage.setItem(localCarrito.name, []);
    return [];
  }
};

export const deteleItemCarritoLocalStorage = (itemCode) => {
  try {
    let carrito = encryptStorage.getItem(localCarrito.name);
    const newCarrito = carrito.filter((item) => item.itemCode !== itemCode);
    encryptStorage.setItem(localCarrito.name, newCarrito);
    return encryptStorage.getItem(localCarrito.name);
  } catch (e) {
    encryptStorage.setItem(localCarrito.name, []);
    return [];
  }
};

export const editCantidadCarritoLocalStorage = (itemCode, cantidad) => {
  try {
    let carrito = encryptStorage.getItem(localCarrito.name);
    const newCarrito = carrito.map((item) => {
      if (item.itemCode === itemCode) {
        item.cantidad = parseInt(cantidad);
      }
      return item;
    });
    encryptStorage.setItem(localCarrito.name, newCarrito);
    return encryptStorage.getItem(localCarrito.name);
  } catch (e) {
    encryptStorage.setItem(localCarrito.name, []);
    return [];
  }
};

export const editarProductoLocalStorage = (producto) => {
  let carrito = encryptStorage.getItem(localCarrito.name);
  const newCarrito = carrito.filter(
    (item) => producto.itemCode !== item.itemCode
  );
  newCarrito.push(producto);
  encryptStorage.setItem(localCarrito.name, newCarrito);
  return encryptStorage.getItem(localCarrito.name);
};

export const clearCarritoLocalStorage = () => {
  encryptStorage.setItem(localCarrito.name, []);
  return [];
};

export const actualizarCarritoLS = (carrito) => {
  try {
    encryptStorage.setItem(localCarrito.name, carrito);
    return encryptStorage.getItem(localCarrito.name);
  } catch (e) {
    encryptStorage.setItem(localCarrito.name, []);
    return [];
  }
};
