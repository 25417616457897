import React from "react";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  container: {
    maxWidth: 1400,
    margin: "0 auto",
    padding: 15,
  },
}));
export const OmarContainer = ({ children }) => {
  const classes = useStyle();
  return <div className={classes.container}>{children}</div>;
};

export default OmarContainer;
