import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import TagManager from "react-gtm-module";
import { Alert, Container, Grid, Box, Paper } from "@mui/material";
import { createOrder, confirmOrder } from "../data/orders";
import { getUtmsLocalStorage} from '../helper/utmLocalStorage'
import DatosPedido from "../components/confirmarpago/DatosPedido";
import DatosEnvio from "../components/confirmarpago/DatosEnvio";
import Stepper from "../components/carrito/Stepper";
import MetaComponent from "../components/shared/MetaComponent";
import PayPalPayment from "../components/confirmarpago/PayPalPayment";

const ConfirmarPago = () => {
  const history = useHistory();

  const { carrito, formulario, formularioFacturacion, validForm } = useSelector(
    (state) => state.pedido
  );

  const [enviando, setEnviando] = useState(false);
  const [uuid, setuuid] = useState({
    uuid: "",
  });

  useEffect(() => {

    createOrder({
      datosFacturacion: formularioFacturacion,
      datosEnvio: formulario,
      carrito: carrito.map( (item) => { return {...item, precio: ( item.precio ) }} ),
      metodoPago: 2,
      utms:getUtmsLocalStorage()
    }).then((d) => setuuid(d));
  }, [formulario, carrito, formularioFacturacion]);

  const [error, setError] = useState({
    error: false,
    mensaje: "",
  });

  if (!validForm) {
    return <Redirect to="/check-out" />;
  }

  const onPayPalClick = async (data, actions) => {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          type: data.fundingSource === "paypal" ? "paypal" : "credit-card",
        },
        event: "payment_type",
      },
    });
  };

  const onPayPalCreateOrder = async (data, actions) => {
    try {
      if (uuid.uuid.length === 0) return actions.reject();
      return actions.order.create({
        intent: "CAPTURE",
        purchase_units: [
          {amount: {soft_descriptor: "Pago Tienda Pegaduro",currency_code: "MXN",value: Number(data.total).toFixed(2)}},
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      });
    } catch (error) {
      return actions.reject();
    }
  };

  const onPayPalCancel = (data, actions) => {};

  const onPayPalError = (data, actions) => {
    setError((e) => ({...e,error: true,mensaje: "Hubo un error al procesar el pago"}));
  };

  const onPayPalApprove = (data, actions) => {
    return actions.order.capture().then(async (details) => {
      setEnviando(true);
      const result = await confirmOrder({
        uuid: uuid.uuid,
        orderID: data.orderID,
        payerID: data.payerID,
      });
      setEnviando(false);
      history.replace(`/revisar-orden?orderId=${result.uuid}`);
    });
  };

  return (
    <>
      <MetaComponent
        titulo="Confirmación de pago"
        description="Confirmación de pago"
      />
      <Stepper stepp="confirmarPago" />
      <Container>
        {!enviando ? (
          <>
            {error.error && (
              <Box mb={2}>
                <Alert variant="filled" severity="error">
                  {error.mensaje}
                </Alert>
              </Box>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <Box>
                  <DatosEnvio
                    formulario={formulario}
                    formularioFacturacion={formularioFacturacion}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Paper elevation={4}>
                  <Box p={5}>
                    <DatosPedido carrito={carrito} />
                    <Box mt={4}>
                      {uuid.uuid.length > 0 && (
                        <PayPalPayment
                          carrito={carrito}
                          onPayPalClick={onPayPalClick}
                          onPayPalCancel={onPayPalCancel}
                          onPayPalCreateOrder={onPayPalCreateOrder}
                          onPayPalError={onPayPalError}
                          onPayPalApprove={onPayPalApprove}
                          forceReRender={[uuid]}
                        />
                      )}
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </>
        ) : (
          <Box display="flex" justifyContent="center">
            <Loader type="ThreeDots" color="#fe5000" height={300} width={300} />
          </Box>
        )}
      </Container>
    </>
  );
};

export default ConfirmarPago;
