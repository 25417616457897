import { useRef, useState, useEffect } from "react";
import { urlServices } from "../helper/urlsBase";
import { postConfig } from "../helper/postConfig";

const useEnvio = (carrito = []) => {
  const [costoEnvio, setcostoEnvio] = useState([]);

  const [calculando, setCalculando] = useState(false);
  const [errorEnvio, setErrorEnvio] = useState(false);
  //validacion de si se esta usando el componente
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  const [envio, setEnvio] = useState(0);
  useEffect(() => {
    setEnvio(
      Array.isArray(costoEnvio)
        ? costoEnvio
            .map((item) => item.costoEnvio + item.costoEnvio * item.iva)
            .reduce((a, b) => a + b, 0)
        : 0
    );
  }, [costoEnvio]);

  useEffect(() => {
    setCalculando(true);
    var result = [];
    carrito.reduce(function (res, value) {
      if (!res[value.empaque]) {
        res[value.empaque] = { empaque: value.empaque, cantidad: 0 };
        result.push(res[value.empaque]);
      }
      res[value.empaque].cantidad += value.cantidad;
      return res;
    }, {});
    if (carrito.length > 0) {
      fetch(
        `${urlServices.URL_SERVICE_TIENDA}api/v2/Envios/CostoEnvio`,
        postConfig(carrito)
      )
        .then((resp) => resp.json())
        .then((data) => {
          if (isMounted.current) {
            setCalculando(false);
            setcostoEnvio(data);
          }
        })
        .catch((e) => {
          setErrorEnvio(true);
          setCalculando(false);
          console.log(e);
        });
    } else {
      setErrorEnvio(true);
    }
  }, [carrito]);

  return {
    costoEnvio,
    envio,
    calculando,
    errorEnvio,
  };
};

export default useEnvio;
