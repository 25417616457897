import ReactDOM from "react-dom";
import EcommerceApp from "./EcommerceApp";
import "normalize.css";
import "animate.css";
import "./index.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TagManager from "react-gtm-module";
const tagManagerArgs = {
  // gtmId: "GTM-WSH29R8",
     gtmId: "GTM-TNP2D8M"
};

TagManager.initialize(tagManagerArgs);
ReactDOM.render(<EcommerceApp />, document.querySelector("#root"));
