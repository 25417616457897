import { encryptStorage } from "./localStorage";

const disponibilidad = {
  name: "dispons",
};

export const getLocalDisonibilidad = () => {
  const disponibilad = encryptStorage.getItem(disponibilidad.name);

  if (disponibilad) {
    return disponibilad;
  }
  return {};
};

export const setLocalDisponibilidad = (dis) => {
  encryptStorage.setItem(disponibilidad.name, dis);
  return encryptStorage.getItem(disponibilidad.name);
};
