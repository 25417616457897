import { useState, useEffect, useRef } from "react";
import {
  Button,
  TextField,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  DialogContentText,
  Box,
  Typography,
  Alert
} from "@mui/material";

import useForm from "../../hooks/useForm";
import validator from "validator";
import { useDispatch } from "react-redux";
import {
  addDireccionAction,
  updateDireccionAction,
} from "../../actions/direcciones";
import useValidCp from "../../hooks/useValidCp";
import { Add, Edit } from "@mui/icons-material";
const ModalDireccion = ({ isOpenModal, setIsOpenModal, token, direccion }) => {
  const dispatch = useDispatch();

  const [error, setError] = useState({
    error: false,
    mensaje: "",
  });
  const { form, handleChange, setForm, clearForm } = useForm({
    alias: "",
    nombre: "",
    apellidos: "",
    calle: "",
    numeroExterior: "",
    numeroInterior: "",
    codigoPostal: "",
    colonia: "",
    municipio: "",
    estado: "",
    comentarios: "",
    telefono:""
  });
  const {
    alias,
    nombre,
    apellidos,
    calle,
    numeroExterior,
    numeroInterior,
    codigoPostal,
    colonia,
    municipio,
    estado,
    comentarios,
    telefono
  } = form;
  const setFormState = useRef(setForm);
  useEffect(() => {
    if (direccion) {
      setFormState.current({
        alias: direccion.alias,
        nombre: direccion.nombre,
        apellidos: direccion.apellidos,
        calle: direccion.calle,
        numeroExterior: direccion.numeroExterior,
        numeroInterior: direccion.numeroInterior,
        codigoPostal: direccion.codigoPostal,
        colonia: direccion.colonia,
        municipio: direccion.municipio,
        estado: direccion.estado,
        comentarios: direccion.comentarios,
        telefono:direccion.telefono
      });
    }
  }, [direccion, setForm]);
  const { validCp } = useValidCp(codigoPostal, setForm);

  const onClose = () => {
    setIsOpenModal(false);
  };

  const handleClick = () => {
    if (validForm() && validCp) {
      window.scrollTo(0, 0);
      clearForm();
      setIsOpenModal(false);
      if (!direccion?.id) {
        dispatch(addDireccionAction(token, form));
      } else {
        dispatch(updateDireccionAction(token, form, direccion?.id));
      }
    }
  };

  const validForm = () => {
    if (validator.isEmpty(nombre.trim())) {
      setError({
        error: true,
        mensaje: "El campo de nombre(s) no puede ir vacío",
      });
      return false;
    }
    if (validator.isEmpty(apellidos.trim())) {
      setError({
        error: true,
        mensaje: "El campo de apellidos no puede ir vacío",
      });
      return false;
    }
    if (!validator.isMobilePhone(telefono.trim(), "es-MX")) {

      setError({
        error: true,
        mensaje:  "El numero telefono no es valido el numero de digitos del telefono debe ser de 10",
      });
      return false;
    }
    if (validator.isEmpty(calle.trim())) {
      setError({
        error: true,
        mensaje: "La calle que ingreso no es valida",
      });

      return false;
    }
    if (validator.isEmpty(numeroExterior.trim())) {
      setError({
        error: true,
        mensaje: "El numero exterior no es valido",
      });

      return false;
    }
    if (!validator.isPostalCode(codigoPostal, "MX")) {
      setError({
        error: true,
        mensaje: "El codigo postal que ingreso no es valido",
      });
      return false;
    }
    if (validator.isEmpty(colonia.trim())) {
      setError({
        error: true,
        mensaje: "Por favor seleccione una colonia",
      });

      return false;
    }
    if (validator.isEmpty(municipio.trim())) {
      setError({
        error: true,
        mensaje: "El municipio que ingreso no es valido",
      });

      return false;
    }
    if (validator.isEmpty(estado.trim())) {
      setError({
        error: true,
        mensaje: "El estado que ingreso no es valido",
      });
      return false;
    }
    return true;
  };
  return (
    <Dialog
      open={isOpenModal}
      onClose={onClose}
      disableScrollLock={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {direccion?.id ? (
          <>
            <Edit color="primary" /> Editar mi dirección
          </>
        ) : (
          <>
            <Add color="primary" /> Agregar dirección
          </>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          La dirección que se ingrese en esta ventana sera utilizada como
          dirección de envio para sus pedidos.
        </DialogContentText>
        {error.error && (
          <Alert variant="outlined" severity="error">
            {error.mensaje}
          </Alert>
        )}

        <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                onChange={handleChange}
                variant="standard"
               autoFocus
                margin="dense"
                label="Alias"
                type="text"
                name="alias"
                value={alias}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                onChange={handleChange}
                variant="standard"  
                margin="dense"
                label="Nombre(s)*"
                type="text"
                name="nombre"
                value={nombre}
                fullWidth
              />
            </Grid>
    
            <Grid item xs={12} md={6}>
              <TextField
                onChange={handleChange}
                variant="standard"
               
                margin="dense"
                label="Apellidos*"
                type="text"
                name="apellidos"
                value={apellidos}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                onChange={handleChange}
                variant="standard"  
                margin="dense"
                label="Telefono*"
                type="text"
                name="telefono"
                value={telefono}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                onChange={handleChange}
                variant="standard"
               
                margin="dense"
                label="Calle*"
                type="text"
                name="calle"
                value={calle}
                fullWidth
              />
            </Grid>

            <Grid xs={12} md={6} item>
              <TextField
                onChange={handleChange}
                variant="standard"
               
                margin="dense"
                label="Numero Exterior*"
                type="text"
                name="numeroExterior"
                value={numeroExterior}
                fullWidth
              />
            </Grid>

            <Grid xs={12} md={6} item>
              <TextField
                onChange={handleChange}
                variant="standard"
               
                margin="dense"
                label="Numero interior"
                type="text"
                name="numeroInterior"
                value={numeroInterior}
                fullWidth
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <TextField
                onChange={handleChange}
                variant="standard"
               
                margin="dense"
                label="Codigo Postal"
                type="text"
                name="codigoPostal"
                value={codigoPostal}
                fullWidth
              />
              {validCp === false && (
                <Typography variant="caption" color="error">
                  Código postal no disponible
                </Typography>
              )}
            </Grid>

            <Grid xs={12} md={6} item>
              <TextField
                onChange={handleChange}
                variant="standard"
               
                margin="dense"
                label="Colonia"
                type="text"
                name="colonia"
                value={colonia}
                fullWidth
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <TextField
                onChange={handleChange}
                variant="standard"
               
                margin="dense"
                label="Municipio"
                type="text"
                name="municipio"
                value={municipio}
                fullWidth
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <TextField
                onChange={handleChange}
                variant="standard"
               
                margin="dense"
                label="Estado"
                type="text"
                name="estado"
                value={estado}
                fullWidth
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                onChange={handleChange}
                variant="standard"
               
                margin="dense"
                label="Comentarios"
                type="text"
                multiline
                rows={4}
                name="comentarios"
                value={comentarios}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Box mt={5}>
          <Button component="span" onClick={onClose} color="primary">
            Cancelar
          </Button>
          <Button component="span" onClick={handleClick} color="primary">
            {direccion?.id ? "Editar" : "Agregar"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDireccion;
