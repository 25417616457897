import {useState} from "react";
import { Box, Button, Typography} from "@mui/material";
import { EmailOutlined } from "@mui/icons-material";
import {PopUpCorreo} from '../topbar/PopUpCorreo'

const FooterNewsLetter = ({ classes }) => {

  const [open, setOpen] = useState(false)
  const handleCose = ()=>{
    setOpen(false)
  }
  return (
    <>
      <PopUpCorreo open={open} handleClose={handleCose} isCarrito={false}/>
      <Box>
        <Box mb={2}>
          <Typography variant="subtitle2" fontWeight="bold" color="secondary" style={{ cursor: "pointer" }} data-target="#defaultModal">
            Suscríbete a nuestro Newsletter
          </Typography>
        </Box>
        <Box mb={2}>
          <Box mt={2}>
            <Button fullWidth variant="outlined" startIcon={<EmailOutlined />} color="secondary" size="small" onClick={()=>{setOpen(true)}}>
              Suscribirse
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FooterNewsLetter;
