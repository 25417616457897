import { urlServices } from "./urlsBase";

export const validateNotDecimalNumber = (e) => {
  const characterCode = e.key;
  if (characterCode === "Backspace") return;

  const characterNumber = Number(characterCode);
  if (characterNumber >= 0 && characterNumber <= 9) {
    if (e.currentTarget.value && e.currentTarget.value.length) {
      return;
    }
  } else {
    e.preventDefault();
  }
};

export const validateLenght = (e) => {
  const { target } = e;
  if (target.value?.length > 250) {
    e.preventDefault();
  }
};

export const compare = (a, b) => {
  if (a.nombre < b.nombre) {
    return -1;
  }
  if (a.nombre > b.nombre) {
    return 1;
  }
  return 0;
};


export const compareOrden = (a, b) => {
  if (a.orden < b.orden) {
    return -1;
  }
  if (a.orden > b.orden) {
    return 1;
  }
  return 0;
};

export const validateRFC = async (rfc) => {
  try {
    const response = await fetch(
      `${urlServices.URL_SERVICE_TIENDA}api/v1/Pedidos/RFC?RFC=${rfc}`
    );
    if (response.ok) {
      return await response.json();
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const nombresMeses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
