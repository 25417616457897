import React from "react";
import Logo from "../../assets/img/logo_footer_trasparente.png";
import { urlServices } from "../../helper/urlsBase";
import { useFetch } from "../../hooks/useFetch";
import short from "short-uuid";
import ModalNuestrasPlantas from "./ModalNuestrasPlantas";
import { Container, Box, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import FooterNewsLetter from "./FooterNewsLetter";

const useStyles = makeStyles((theme) => ({
  ribbonPegaduroShop: {
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    top: -20,
    padding: 8,
    maxWidth: "10rem",
  },
  ribbonText: {},
  footerContainerTop: {
    backgroundColor: "#212529",
    maxWidth: "100%",
    paddingTop: 10,
  },
  footerContainerBot: {
    backgroundColor: "#1c2023",
    maxWidth: "100%",
  },
  linksArrow: {
    color: "#777",
    fontSize: "0.6em",
    marginRight: 2,
    marginLeft: 4,
  },
  link: {
    color: "#777",
    textDecoration: "none",
    fontSize: "0.8em",
    fontWeight: "bold",
    "&:hover": {
      color: "white",
    },
  },
  seccionTitle: {
    color: "#ffff",
    fontWeight: "bold",
  },
  iconsSocialMedia: {
    color: "#000",
    fontSize: 12,
    "&:hover": {
      color: "white",
    },
  },
  containerItemSocial: {
    backgroundColor: "white",
    width: 20,
    height: 20,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    padding: 5,
    borderRadius: 100,
    color: "#000",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  linkSeccion: {
    color: "#777",
    "&:hover": {
      color: "white",
    },
    marginLeft: 5,
    fontSize: 12,
    textDecoration: "none",
  },
  icono: {
    color: theme.palette.primary.main,
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
}));
const Footer = () => {
  const classes = useStyles();
  const { data: footer } = useFetch(
    `${urlServices.URL_SERVICE_TIENDA}api/Partials/Footer  `
  );

  const handleLogoFooter = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer style={{ marginTop: 40 }}>
      <Box className={classes.footerContainerTop}>
        <Container>
          {/* <Box className={`${classes.ribbonPegaduroShop} ribbon-footer`}>
            <Typography
              variant="h6"
              color="secondary"
              align="center"
              className={classes.ribbonText}
            >
              Pegaduro
            </Typography>
          </Box> */}
          <Box mt={5}>
            <Grid container>
              {footer &&
                footer
                  .filter((s) => s.estatus)
                  .sort((a, b) => a.orden - b.orden)
                  .map((seccion) => {
                    if (![6, 9, 3].includes(seccion.idSeccion)) {
                      if (seccion.tipoSeccion.idTipoSeccion === 1) {
                        return (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            lg={3}
                            mb={4}
                            key={short.generate()}
                          >
                            <SeccionTextoIconos
                              seccion={seccion}
                              classes={classes}
                            />
                          </Grid>
                        );
                      }
                      return null;
                    } else {
                      if (seccion.idSeccion === 6) {
                        const seccionSocialMedia = footer.find(
                          (item) => item.idSeccion === 3
                        );
                        const seccionContactanos = footer.find(
                          (item) => item.idSeccion === 6
                        );
                        return (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            lg={3}
                            mb={4}
                            key={short.generate()}
                          >
                            <Box display="flex" flexDirection="column">
                              <Box>
                                <SeccionTextoIconos
                                  seccion={seccionContactanos}
                                  classes={classes}
                                />
                              </Box>
                              <Box mt={3}>
                                <SeccionInconos
                                  classes={classes}
                                  seccion={seccionSocialMedia}
                                />
                              </Box>
                              <Box mt={3}>
                                <NuestrasPlantas />
                              </Box>
                            </Box>
                          </Grid>
                        );
                      } else {
                        return null;
                      }
                    }
                  })}
              <Grid item xs={12} md={6} lg={3} mb={4} key={short.generate()}>
                <FooterNewsLetter classes={classes} />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box className={classes.footerContainerBot}>
        <Container>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pt={5}
            pb={5}
          >
            <Box>
              <img
                alt="fondo"
                src={Logo}
                height="120"
                onClick={handleLogoFooter}
              />
            </Box>
            <ul>
              {footer &&
                footer
                  .filter((s) => s.estatus)
                  .sort((a, b) => a.orden - b.orden)
                  .map((seccion) => {
                    if (seccion.tipoSeccion.idTipoSeccion === 5) {
                      return (
                        <li display="flex" mt={5} key={short.generate()}>
                          {seccion.contenidoSeccion
                            .filter((s) => s.estatus)
                            .sort((a, b) => a.orden - b.orden)
                            .map((contenido) => {
                              if (contenido.estatus) {
                                return (
                                  <Typography
                                    variant="body2"
                                    key={short.generate()}
                                  >
                                    <ArrowForwardIosOutlined
                                      className={classes.linksArrow}
                                      style={{ fontSize: 10 }}
                                    />
                                    <a
                                      href={`http://www.pegaduro.com.mx${contenido.url}`}
                                      rel="noreferrer"
                                      target="_blank"
                                      className={classes.link}
                                    >
                                      {contenido.contenido}
                                    </a>
                                  </Typography>
                                );
                              }
                              return null;
                            })}
                        </li>
                      );
                    }

                    return null;
                  })}
            </ul>
          </Box>
          <>
            <Box display="flex" justifyContent="center" p={4}>
              <Typography
                color="secondary"
                textAlign="center"
                variant="caption"
                className={classes.link}
              >
                © Copyright {new Date().getFullYear()}.Todos los derechos
                reservados.
              </Typography>
            </Box>
          </>
        </Container>
      </Box>
    </footer>
  );
};

const NuestrasPlantas = () => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment key={short.generate()}>
      <ModalNuestrasPlantas handleClose={handleClose} open={open} />

      <Typography
        variant="subtitle2"
        fontWeight="bold"
        color="secondary"
        onClick={handleClickOpen}
        style={{ cursor: "pointer" }}
        data-target="#defaultModal"
      >
        {" "}
        Nuestras Plantas
      </Typography>
    </React.Fragment>
  );
};

const SeccionInconos = ({ seccion, classes }) => {
  return (
    <Box>
      <Typography variant="subtitle2" fontWeight="bold" color="secondary">
        {seccion.nombre}
      </Typography>
      <Box display="flex">
        {seccion.contenidoSeccion
          .filter((s) => s.estatus)
          .sort((a, b) => a.orden - b.orden)
          .map((contenido) => {
            return (
              <Box
                className={classes.containerItemSocial}
                key={short.generate()}
              >
                <a href={`${contenido.url}`} target="_blank" rel="noreferrer">
                  <i
                    className={`${contenido.icon} ${classes.iconsSocialMedia}`}
                  ></i>
                </a>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

const SeccionTextoIconos = ({ seccion, classes }) => {
  return (
    <>
      <Typography variant="subtitle2" fontWeight="bold" color="secondary">
        {seccion.nombre}
      </Typography>

      {seccion.contenidoSeccion
        .filter((s) => s.estatus)
        .sort((a, b) => a.orden - b.orden)
        .map((contenido) => {
          if (contenido.urlExterna) {
            return (
              <Box
                p={1}
                className={classes.itemListSeccion}
                key={short.generate()}
              >
                <i className={`${contenido.icon} ${classes.icono}`}></i>
                <a
                  href={contenido.url}
                  rel="noreferrer"
                  target="_blank"
                  className={classes.linkSeccion}
                >
                  {contenido.contenido}
                </a>
              </Box>
            );
          }
          return (
            <Box
              p={1}
              className={classes.itemListSeccion}
              key={short.generate()}
            >
              <i className={`${contenido.icon} ${classes.icono}`}></i>
              <a
                href={`http://www.pegaduro.com.mx/${contenido.url}`}
                rel="noreferrer"
                target="_blank"
                className={classes.linkSeccion}
              >
                {contenido.contenido}
              </a>
            </Box>
          );
        })}
    </>
  );
};

export default Footer;
