import { encryptStorage } from "./localStorage";

const utmNameLs = {
  name: "utms",
};

export const getUtmsLocalStorage = () => {
    const utms = encryptStorage.getItem(utmNameLs.name);
    
    return utms?utms:null;
  };
  
  export const setUtmsLocalStorage = (utms) => {
    encryptStorage.setItem(utmNameLs.name, utms);
    return encryptStorage.getItem(utmNameLs.name);
  };