import {
  getLocalUnregistredUser,
  setLocalUnregistredUser,
} from "../helper/UnregistredLocalStorage";
import { unregistredTypes } from "../types/types";

export const setUnregistedUserAction = (usuario) => {
  return (dispatch) => {
    const usuarioLs = setLocalUnregistredUser(usuario);
    dispatch({
      payload: usuarioLs,
      type: unregistredTypes.set_unregistred,
    });
  };
};

export const unregisteredUsuerAction = () => {
  return (dispatch) => {
    const usuario = getLocalUnregistredUser();
    dispatch({ payload: usuario, type: unregistredTypes.get_unregistred });
  };
};
