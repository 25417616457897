import { setLocalDisponibilidad } from "../helper/disponibilidadLocalStorage";
import { disponibilidadTypes } from "../types/types";

export const changeDisponibilidad = (newDisponbilidad) => {
  return (dispatch) => {
    const disponbilidad = setLocalDisponibilidad(newDisponbilidad);
    dispatch({
      type: disponibilidadTypes.edit_disponibilidad,
      payload: disponbilidad,
    });
  };
};
