import { getAuthLS } from "../helper/authLocalStorage";
import { authTypes } from "../types/types";

export const authReducer = (
  state = { ...getAuthLS(), loading: false },
  action
) => {
  switch (action.type) {
    case authTypes.login:
      return action.payload;
    case authTypes.logout:
      return action.payload;
    case authTypes.registro:
      return action.payload;
    case authTypes.loading:
      return action.payload;
    case authTypes.error:
      return action.payload;
    default:
      return state;
  }
};
