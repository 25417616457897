import { Paper, Typography, Box } from "@mui/material";
import React from "react";
import Logo from "../../assets/img/LogoPegaduro.png";
const DatosEnvio = ({ formulario }) => {
  const {
    apellidos,
    nombres,
    municipio,
    calle,
    colonia,
    estado,
    numeroExterior,
    numeroTelefono,
    correoElectronico,
    codigoPostal,
    comentarios,
  } = formulario;
  return (
    <>
      <Paper elevation={1}>
        <Box p={3}>
          <Typography fontWeight="bold" align="center" variant="subtitle1">
            Datos de envío
          </Typography>
          <Box display="flex">
            <Box  display="flex" justifyContent="center">
              <img
                src={Logo}
                style={{ width: 140, height: 180, marginBottom: 30 }}
                alt="logo"
              />
            </Box>
            <Box ml={2}>
              <Box >
                <Typography variant="subtitle2" fontWeight="bold">
                  Nombre
                </Typography>
                <Typography variant="body2">{`${nombres} ${apellidos}`}</Typography>
              </Box>

              <Box >
                <Typography variant="subtitle2" fontWeight="bold">
                  Domicilio de envío
                </Typography>
                <Typography variant="body2">
                  {` ${calle} ${numeroExterior}, ${colonia}  C.P ${codigoPostal} ${municipio} ${estado}`}
                </Typography>
              </Box>
              {numeroTelefono && (
                <Box >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Número de teléfono
                  </Typography>
                  <Typography variant="body2"> {numeroTelefono}</Typography>
                </Box>
              )}
              <Box >
                <Typography variant="subtitle2" fontWeight="bold">
                  Correo electrónico
                </Typography>
                <Typography variant="body2">{correoElectronico}</Typography>
              </Box>
              {comentarios && (
                <p>
                  <strong>Comentarios del pedido</strong>
                  <br />
                  {comentarios}
                </p>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default DatosEnvio;
